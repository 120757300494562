import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "@services/apis/authentication.service";
import { UsersService } from "@services/apis/users.service";
import { error } from "protractor";

@Component({
  selector: "app-admin-profile",
  templateUrl: "./admin-profile.html",
  styleUrls: ["./admin-profile.sass"],
})
export class AdminProfileComponent implements OnInit {
  editProfile: any;
  user: any;
  invalidMobile = false;
  emailExists = false;
  notMatched = false;
  confirmPassword: any;
  currentPassword: any;
  newPassword: any;
  invalidPassword = false;
  phoneExist = false;
  haserror = false;
  savedForm: boolean = false;
  constructor(
    private authApi: AuthenticationService,
    private usersApi: UsersService,
    private router: Router
  ) {
    this.editProfile = {};
  }
  async ngOnInit() {
    const currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    console.log(currentUser);
    if (currentUser != null) {
      this.user = await this.usersApi
        .query({ where: { userId: currentUser.userId } })
        .toPromise();
      console.log(this.user);
      this.user = this.user.result[0];
      console.log(this.user);
    }
  }
  showPassword() {
    (document.getElementById("password") as HTMLInputElement).type = "text";
  }

  hidePassword() {
    (document.getElementById("password") as HTMLInputElement).type = "password";
  }
  isValidMobile(mobile): boolean {
    return /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{9})$/.test(mobile);
  }
  updateFormStep() {
    if (this.newPassword != null) {
      if (this.confirmPassword !== this.newPassword) {
        this.notMatched = true;
        return;
      }
    }
    if (this.user) {
      this.user.password = this.currentPassword;
      this.user.newPassword = this.newPassword;
      this.resetError();
      this.usersApi.updateUser(this.user).subscribe(
        (res) => {
          if (res) {
            if (this.user.password != null && this.user.newPassword != null) {
              let data = {
                currentpassword: this.user.password,
                newpassword: this.user.newPassword,
              };
              this.authApi.changePassword(data).subscribe(
                (result) => {
                  // msg
                  this.savedForm = true;
                  setTimeout(() => {
                    this.savedForm = false;
                  }, 3000);
                  this.confirmPassword = "";
                  this.currentPassword = "";
                  this.newPassword = "";
                },
                (error) => {
                  console.log(error);
                  this.invalidPassword = true;
                }
              );
            } else {
              // this.savedForm = true;
              // setTimeout(() => {
              //   this.savedForm = false;
              // }, 5000);
            }
            this.usersApi
              .query({ where: { userId: this.user.id } })
              .subscribe((res) => {
                this.user = res["result"][0];
              });
          }
        },
        (error) => {
          if (
            error.status === 451 &&
            error.error.message === "هذاالأيميل  لديه حساب أخر بالفعل."
          ) {
            this.emailExists = true;
          } else if (error.status === 450) {
            this.phoneExist = true;
          } else {
            this.haserror = true;
          }
        }
      );
    } else {
      console.error("can't find user");
    }
  }
  resetError() {
    this.phoneExist = false;
    this.haserror = false;
    this.emailExists = false;
  }
}
