import { TimerService } from "./../../../exams/exam/timer.service";
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";

import { SettingsService } from "@services/apis/setting.service";
import { LecturesService } from "@services/apis/lectures.service";

import { SectionItems } from "../right-nav-bar-component/right-nav-bar.component";
import { interval, Subscription } from "rxjs";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import * as moment from "moment";
import * as _ from "lodash";

declare var $: any;
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.sass"],
})
export class NavbarComponent implements OnInit {
  display: Subscription;
  isExam: boolean = false;
  constructor(
    private pubSub: NgxPubSubService,
    private router: Router,
    private settingApi: SettingsService,
    public lecturesService: LecturesService,
    private timer: TimerService,
    private activatedRoute: ActivatedRoute
  ) {}

  @Output() handleLocationChange = new EventEmitter();
  currentUrl: string = "/";
  setting: any = {};
  smScreen: boolean = false;
  isRightNavBarOpened: boolean = false;
  rightBarSectionItems: SectionItems;
  time: any;
  displayedTime: any;
  duration: any;
  interval: any;
  lecName: string;
  counter: number = 0;

  async ngOnInit() {
    this.pubSub.subscribe("display", (data) => {
      this.display = data;
    });
    this.CreateRigthMenu();
    this.isPromise();

    if (window.screen.width < 991) {
      this.smScreen = true;
    }
    this.router.events.subscribe((next: NavigationEnd) => {
      if (next.url && next.url !== this.currentUrl) {
        this.currentUrl = next.url;
        this.handleLocationChange.emit(next.url);
        this.timeOnExam();
        setTimeout(() => {
          this.timer.currentLecName.subscribe((name) => (this.lecName = name));
        }, 2000);
      }
    });
    let setting: any = await this.settingApi.query({}).toPromise();
    this.setting = setting.result[0];
  }
  CreateRigthMenu() {
    const currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    this.rightBarSectionItems = [];
    if (currentUser) {
      if (currentUser.allowExam) {
        this.rightBarSectionItems.push({
          text: "بنك الاسئلة",
          iconClass: "brain-icon",
          lastLevelSectionItems: [
            {
              text: "انشاء اختبار عشوائي",
              iconClass: "random-test-icon",
              link: "/lections/create-test",
            },
            {
              text: "الاختبارات السابقة",
              iconClass: "previous-tests-icon",
              link: "/lections/previous-tests",
            },
            {
              text: "الاداء",
              iconClass: "productivity-icon",
              link: "/lections/performance",
            },
          ],
        });
      }
      if (currentUser.allowLecture) {
        this.rightBarSectionItems.push({
          text: "المحاضرات",
          iconClass: "lections-icon",
          link: "/lections",
        });
        this.rightBarSectionItems.push({
          text: "الملخصات",
          iconClass: "cv-icon",
          link: "/lections/summeries",
        });
        this.rightBarSectionItems.push({
          text: "ملاحظاتي",
          iconClass: "my-notes-icon",
          link: "/lections/mynotes",
        });
        this.rightBarSectionItems.push({
          text: "اسألتى",
          iconClass: "ask-me-icon",
          link: "/lections/myquestion",
        });
      }
    }
    this.rightBarSectionItems.push({
      text: "اسألتي",
      isBottomSection: true,
      bottomSectionItems: [
        {
          text: "الاعدادات",
          iconClass: "menu-settings-icon",
          link: "/profile/me",
        },
        {
          text: "تسجيل خروج",
          iconClass: "menu-logout-icon",
          link: "/auth",
        },
      ],
    });
  }
  locateTo(pageUrl: string) {
    this.router.navigate([pageUrl]);
  }

  homeAnalytics() {
    this.router.navigate(["/"]);
  }
  //note : replace with routes
  onNavigate() {
    localStorage.removeItem("currentUser");
    sessionStorage.removeItem("currentUser");
    localStorage.removeItem("isAdmin");
    sessionStorage.removeItem("isAdmin");
    window.open("sign-up", "_self");
  }
  //note : replace with routes
  onNavigateAuth() {
    localStorage.removeItem("currentUser");
    sessionStorage.removeItem("currentUser");
    localStorage.removeItem("isAdmin");
    sessionStorage.removeItem("isAdmin");
    window.open("auth", "_self");
  }
  toggleNavList() {
    let buttonImages = document.getElementsByClassName(
      "toggle_nav_list_btn_img"
    );
    let listItems = document.getElementsByClassName("nav_right_list_li");
    Array.from(buttonImages).forEach((li) => {
      li.classList.toggle("d-none");
    });
    Array.from(listItems).forEach((li) => {
      li.classList.toggle("d-none");
    });
  }
  logOut() {
    localStorage.removeItem("currentUser");
    sessionStorage.removeItem("currentUser");
    this.router.navigateByUrl("/").then();
  }
  addActive(event) {
    let listItems = document.getElementsByClassName("nav_right_list_li");
    Array.from(listItems).forEach((li) => {
      li.classList.add("d-none");
    });
    let buttonImages = document.getElementsByClassName(
      "toggle_nav_list_btn_img"
    );
    Array.from(listItems).forEach((li) => {
      li.classList.toggle("d-none");
    });
  }

  toggleRightMenu() {
    this.isRightNavBarOpened = !this.isRightNavBarOpened;
    this.lecturesService.updateRightNavState(this.isRightNavBarOpened);
  }

  handleState(newState: boolean) {
    this.isRightNavBarOpened = newState;
    this.lecturesService.updateRightNavState(this.isRightNavBarOpened);
  }
  onAdminNotification() {}

  IsLoggedIn() {
    if (localStorage.currentUser) {
      return true;
    } else {
      return false;
    }
  }
  isAdmin() {
    if (localStorage.currentUser) {
      return JSON.parse(localStorage.currentUser).isadmin;
    } else {
      return false;
    }
  }

  isPromise() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      if (
        currentUser.promise &&
        currentUser.promise == true &&
        currentUser.subscribedAndPaid &&
        currentUser.subscribedAndPaid == true &&
        currentUser.allowLecture &&
        currentUser.allowLecture == true
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  isAllowExam() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      if (
        currentUser.promise &&
        currentUser.promise == true &&
        currentUser.subscribedAndPaid &&
        currentUser.subscribedAndPaid == true &&
        currentUser.allowExam &&
        currentUser.allowExam == true
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  timeOnExam() {
    setTimeout(() => {
      this.timer.currentTime.subscribe((time) => (this.time = time));
      let isExamFinished: boolean;
      this.timer.currentExamStatus.subscribe(
        (examStatus) => (isExamFinished = examStatus)
      );
      this.timer.currentExam.subscribe((exam) => {
        this.isExam = exam;
      });
      if (isExamFinished == true) {
        this.displayedTime = moment(this.time, "HH:mm:ss").format("HH:mm:ss");
        return;
      } else if (this.isExam == true) {
        this.time = moment(this.time, "HH:mm:ss");
        let isTime;
        this.timer.currentExamHaveTime.subscribe((time) => (isTime = time));
        if (isTime == true) this.displayTimeChange();
        this.displayedTime = "00:00:00";

        // console.log("exam started", this.time, this.displayedTime);
        return;
      } else {
        this.displayedTime = "00:00:00";
        this.timer.changeTime("00:00:00");
      }
    }, 2000);
  }

  displayTimeChange() {
    this.duration = moment(this.time, "HH:mm:ss").add(1, "s");
    this.interval = setInterval(() => {
      if (this.isExam == true) {
        this.duration = moment(this.duration).add(1, "s");
        this.displayedTime = moment(this.duration).format("HH:mm:ss");
      } else {
        clearInterval(this.interval);
        this.duration = moment("00:00:00", "HH:mm:ss");
        this.displayedTime = this.duration.format("HH:mm:ss");
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    this.timer.changeTime("00:00:00");
  }
}
