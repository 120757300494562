<footer class="app-footer" [ngClass]="{'d-none': currentUrl == '' || currentUrl == '/'}" >
  <div class="app-footer-container row">
    <div class="app-footer-block col-xs-12 col-lg-3 col-sm-12 col-md-12">
      <ul class="app-footer-block-contacts">
        <li>
          <p>تواصل معنا</p>
        </li>
        <li >
          <span>{{setting?.address}}</span>
          <img class="location-icon" src="assets/icons/location-icon.png" />
        </li>
        <li > 
          <span>{{setting?.phone}}</span>
          <img class="phone-icon" src="assets/icons/phone-icon.png" />
        </li>
        <li >
          <span>{{setting?.email}}</span>
          <img class="convert-icon" src="assets/icons/convert-icon.png" />
        </li>
      </ul>
      <br />
    </div>
    <div class="app-footer-block col-xs-12 col-lg-3 col-sm-12 col-md-4">
      <ul>
        <li style="padding-bottom: 20px">
          <p>تابعنا</p>
        </li>

        <div dir="RTL">
        <li class="sharing-links" >
          <a target="_blank" class="sharing-links-link facebook" [href]="setting?.facebookLink">
            <img src="assets/icons/facebook-icon.png" />
          </a>
          <a target="_blank" class="sharing-links-link twitter" [href]="setting?.twitterLink">
            <img src="assets/icons/twitter-icon.png" />
          </a>
          <a target="_blank" class="sharing-links-link instagram" [href]="setting?.instagramLink">
            <img src="assets/icons/instagram-icon.png" />
          </a>
        </li>
      </div>
      </ul>
    </div>
    <div class="app-footer-block col-xs-12 col-lg-3 col-sm-12 col-md-4">
      <ul>
        <li>
          <p>الأشتراك</p>
        </li>
        <li>
          <a [routerLink]="['/sign-up']">الخطط والأسعار</a>
        </li>
        <li>
          <a [routerLink]="['/sign-up']">الدفع</a>
        </li>
      </ul>
    </div>
    <div class="app-footer-block col-xs-12 col-lg-3 col-sm-12 col-md-4">
      <ul>
        <li>
          <p>المواد</p>
        </li>
        <li *ngFor="let course of courses">
          <a href="">{{ course.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</footer>
