import { TableSourceItem } from "@modules/shared/components/table-with-pagination-component/interfaces";
import { noop } from "rxjs";

export class TransformDataService {
  static getTableSourceDataFromOrigin<T, G, B>(
    data: Array<T>,
    transformKeys: Array<string>,
    isColorizeConditionForEachItem: G | any = noop,
    hasCirclePointerConfition: B | any = noop
  ): Array<TableSourceItem> {
    return data.map((item: any) => ({
      items: transformKeys.map((key, keyID: number) => ({
        key,
        i18n: item[key],
        isColorize: isColorizeConditionForEachItem(keyID),
        hasCirclePointer: hasCirclePointerConfition(keyID, transformKeys),
        blueText: item.blueText,
        loginText: item.loginText,
        redText: item.redText,
        deleteText: item.deleteText,
        id: item.id,
        transactionId: item.transactionId,
        sendText: item.sendText,
        endDate: item.endDate
      })),
    }));
  }
}
