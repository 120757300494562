import { browser } from "protractor";
import { ExamsService } from "@services/apis/exam.service";
import { RecreateTestComponent } from "./../test-preparation/recreate-test/recreate-test";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { async } from "@angular/core/testing";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LecturesService } from "@services/apis/lectures.service";
import { UserLecturesService } from "@services/apis/userLectures.service";
import * as moment from "moment";
import { AskLecturerComponent } from "../test-preparation/ask-lecturer/ask-lecturer";
import { AuthenticationService } from "@services/apis/authentication.service";
declare var require: any;
const FileSaver = require("file-saver");
import * as Bowser from "bowser";
import { Player  } from '@vime/core/dist/types/components/core/player/player';

@Component({
  selector: "lection",
  templateUrl: "./lection.component.html",
  styleUrls: ["./lection.component.sass"],
})
export class LectionComponent implements OnInit, AfterViewInit,OnDestroy {
  @ViewChild('player') player : Player;
  lectureId: string;
  lecture: any = {};
  userLecture: any = {};
  userLectures: any;
  showNote = false;
  newNote = "";
  sender = "";
  url = "https://angular.io/api/router/RouterLink";
  urlSafe: SafeResourceUrl;
  lectures: any;
  @ViewChild("youtubLink", { static: false }) youtubLink: ElementRef;
  timeStamp: number = 0;
  smScreen: boolean = false;
  bowser: any;
  isIos: boolean = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private lectureApi: LecturesService,
    private modalService: NgbModal,
    private elementRef: ElementRef,
    private userLectureApi: UserLecturesService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private examsApi: ExamsService,
    public authApi: AuthenticationService
  ) {}
  ngOnDestroy(): void {
   let videoContainer= document.getElementById('videoContainer');
   if(videoContainer) videoContainer.parentElement.removeChild(videoContainer);
  }

  @HostListener("window:beforeunload") goToPage() {
    // this.router.navigate(["/hello"]);
    console.log("redirect");
    this.videoClick();
  }
  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    this.videoClick();
    console.log("Back or forward button pressed");
  }
  async ngOnInit() {
    this.bowser = Bowser.parse(navigator.userAgent);
    console.log(this.bowser.browser.name, this.bowser.os.name);
    if (this.bowser.os.name === "iOS") {
      this.isIos = true;
    }
    if (screen.width <= 576) this.smScreen = true;
    const currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      this.sender = currentUser.userId;
    }
    this.activatedRoute.params.subscribe(async (params) => {
      this.lectureId = params.lectureId;
      this.lecture = await this.lectureApi.get(this.lectureId).toPromise();
      if(this.player) this.player.playbackReady=true;
      this.urlSafe = null;
      if (
        this.lecture != null &&
        this.lecture.attachments != null &&
        this.lecture.attachments.length > 0
      ) {
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.lecture.attachments[0].path
        );
      }
      this.lectures = await this.lectureApi.query({}).toPromise();
      this.lectures = this.lectures.result;
      this.userLectures = await this.userLectureApi
        .query({
          where: {
            userId: this.sender,
          },
        })
        .toPromise();
      this.userLectures = this.userLectures.result;
      this.watchLectures();
      this.videoClick();
    });
  }
  ngAfterViewInit() {
    let player = document.getElementById("player");
    if (player) player.addEventListener("ended", this.videoEnd.bind(this));
  }
  askLecturer() {
    const ask = this.modalService.open(AskLecturerComponent, {
      windowClass: "forgot-password",
    });
    ask.componentInstance.lectureId = this.lectureId;
  }
  youtubeLinkToId(url) {
    let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    if (url != null) {
      let match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    }
    return false;
  }
  videoEnd() {
    this.userLectureApi
      .save({
        id: this.userLecture.id,
        lastSeenTime: this.userLecture.lastSeenTime,
        isCompleted: true,
      })
      .subscribe();
  }

  onVideoChange(event) {
    // console.log(event);
    const time = Math.floor(event.detail);
    this.userLecture.lastSeenTime = event.detail;
    if (time % 15 == 0 && time > this.timeStamp) {
      this.timeStamp = time;
      this.userLectureApi
        .save({
          id: this.userLecture.id,
          lastSeenTime: event.detail,
        })
        .subscribe();
    }
  }

  showNotes() {
    this.showNote = true;
  }
  editNotes(index) {
    this.userLecture.Notes[index].showNote = true;
  }
  addTime(index) {
    let noteTime = moment
      .utc(
        moment
          .duration(this.userLecture.lastSeenTime, "seconds")
          .asMilliseconds()
      )
      .format("HH:mm:ss");
    this.userLecture.Notes[index].time = noteTime;
  }
  saveEditNotes(index) {
    this.userLecture.Notes[index].showNote = false;
    this.userLecture.Notes = this.userLecture.Notes.filter(
      (x) => x.note != null && x.note != ""
    );
    this.userLectureApi
      .save({
        id: this.userLecture.id,
        Notes: this.userLecture.Notes,
        lectureId: this.lectureId,
        userId: this.sender,
        isCompleted: this.userLecture.isCompleted,
      })
      .subscribe((res) => {
        this.userLecture.Notes.push({});
      });
  }
  downloadPdf() {
    for (const attach in this.lecture.attachments) {
      FileSaver.saveAs(
        this.lecture.attachments[attach].path,
        this.lecture.attachments[attach].fileName
      );
    }
  }
  nextLecture() {
    const index = this.lectures.findIndex((item) => item.id == this.lecture.id);
    if (index > -1 && index + 1 < this.lectures.length) {
      this.lecture = this.lectures[index + 1];
      this.router.navigateByUrl("/lection/watch/" + this.lecture.id).then();
    }
  }
  prevLecture() {
    const index = this.lectures.findIndex((item) => item.id == this.lecture.id);
    if (index > -1 && index - 1 > -1) {
      this.lecture = this.lectures[index - 1];
      this.router.navigateByUrl("/lection/watch/" + this.lecture.id).then();
    }
  }
  async watchLectures() {
    const userLecture = this.userLectures.find(
      (item) => item.lectureId == this.lecture.id
    );
    if (userLecture != null) {
      this.userLectureApi
        .save({
          lectureId: this.lecture.id,
          userId: this.sender,
          id: userLecture.id,
          lastSeen: new Date(),
        })
        .subscribe((res) => {
          this.userLecture = res;
          if (this.userLecture.Notes == null) this.userLecture.Notes = [];
          this.userLecture.Notes.push({});
        });
    } else {
      this.userLectureApi
        .save({
          lectureId: this.lecture.id,
          userId: this.sender,
          isCompleted: false,
          lastSeen: new Date(),
        })
        .subscribe((res) => {
          this.userLecture = res;
          if (this.userLecture.Notes == null) this.userLecture.Notes = [];
          this.userLecture.Notes.push({});
        });
    }
  }

  openRecreateModal(lecId: string) {
    this.examsApi
      .query({
        where: {
          userId: this.sender,
          lectureId: lecId,
          examType: "تدريبي",
        },
      })
      .subscribe((exams) => {
        if (exams["count"] > 0) {
          const lastExam = exams["count"] - 1;
          if (
            exams["result"][lastExam] &&
            exams["result"][lastExam].isCompleted &&
            exams["result"][lastExam].isCompleted == true
          ) {
            const createTest = this.modalService.open(RecreateTestComponent, {
              windowClass: "forgot-password",
            });
            createTest.componentInstance.lectureId = lecId;
          } else {
            this.router.navigateByUrl("/exam/" + exams["result"][lastExam].id);
          }
        } else {
          this.examsApi
            .creatExamFromLectureId(lecId, true)
            .subscribe((examId) => {
              this.router.navigateByUrl("/exam/" + examId);
            });
        }
      });
  }

  videoClick() {
    console.log(this.userLecture.lastSeenTime);
    const time = Math.floor(this.userLecture.lastSeenTime);
    if (time > this.timeStamp) {
      this.timeStamp = time;
      this.userLectureApi
        .save({
          id: this.userLecture.id,
          lastSeenTime: this.userLecture.lastSeenTime,
        })
        .subscribe();
    }
  }
}
