import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";

import { StudentsMock } from "../constants/admin-fields.mock";
import * as moment from "moment";
import {
  TableHeadingItem,
  TableSourceItem,
} from "@modules/shared/components/table-with-pagination-component/interfaces";
import { TransformDataService } from "@services/transform-data.service";
import {
  isTableItemColorize,
  isTableItemHasCirclePointer,
} from "./utils/table-source.util";
import { User } from "@modules/_models/User";
import { UsersService } from "@services/apis/users.service";
import { PlansService } from "@services/apis/plans.service";
import { TransactionsService } from "@services/apis/transaction.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-admin-students-manage",
  templateUrl: "./admin-students-manage.html",
  styleUrls: ["./admin-students-manage.sass"],
})
export class AdminStudentsManageComponent implements OnInit {
  students: Array<any>;
  studentsData: Array<TableSourceItem>;
  studentsHeadingData: Array<TableHeadingItem>;
  addNewStudentTurnedOn: boolean;
  student: any;
  studentsDataKeys: Array<string>;
  plans: any;
  emailExists: boolean;
  invalidMobile = false;
  searchText: any = "";
  errorRegister: boolean = false;
  phoneExist: boolean;
  @ViewChild("TABLE", { static: false }) TABLE: ElementRef;
  constructor(
    private userApi: UsersService,
    private planApi: PlansService,
    private transactionApi: TransactionsService,
    private activatedRoute: ActivatedRoute
  ) { }
  async ngOnInit() {
    //this.students = StudentsMock;
    this.activatedRoute.params.subscribe((params) => {
      window.scroll(0, 0);
    });
    this.student = {};
    this.plans = await this.planApi.query({}).toPromise();
    this.plans = this.plans.result;
    this.addNewStudentTurnedOn = false;
    this.studentsDataKeys = Object.keys(StudentsMock[0]);
    let listButton = ["loginText", "blueText", "redText", "sendText", "deleteText"];
    this.studentsDataKeys = this.studentsDataKeys.filter(
      (item) => !listButton.includes(item)
    );
    this.getStudentData();
    this.studentsHeadingData = [
      {
        i18n: "المزيد",
      },
      {
        i18n: "ايميل الطالب",
      },
      {
        i18n: "رقم الجوال",
      },
      {
        i18n: "المدة المتبقية",
      },
      {
        i18n: "نوع الاشتراك",
      },
      {
        i18n: "تاريخ الاشتراك",
      },
      {
        i18n: "الاسم",
      },
      {
        i18n: "كود الطالب",
      }
    ];
  }
  filter() {
    return {
      where: {
        and: [
          {
            id: { neq: "5ee5dd83eb9d5c09e8b3887a" },
          },
          {
            or: [
              { firstName: { like: this.searchText + ".*", options: "i" } },
              { lastName: { like: this.searchText + ".*", options: "i" } },
              { middleName: { like: this.searchText + ".*", options: "i" } },
              { email: { like: this.searchText + ".*", options: "i" } },
              { mobile: { like: this.searchText + ".*", options: "i" } },
            ],
          },
        ],
      },
      include: [{ user: "transactions" }],
    };
  }
  async getStudentData() {
    let data: any = await this.userApi.query(this.filter()).toPromise();
    let result = data.result;
    this.students = result.map((item) => {
      let redText = "حظر";
      if (item.user.blocked) redText = "فك الحظر";
      let date = this.getEndDate(item.user.transactions);
      let endDate = date.endDate;
      let name = "";
      if (item.userName) name = item.userName;
      if (item.firstName != null) name = item.firstName;
      if (item.lastName != null) name = name + '' + item.lastName;
      return {
        id: item.id,
        blueText: "تجديد الاشتراك",
        loginText: "تسجيل الدخول",
        description: "ارسال رسالة تجديد الاشتراك",
        editDate: this.getStartDate(item.user.transactions),
        email: item.email,
        leftTime: this.getduration(endDate),
        endDate: endDate,
        transactionId: date.id,
        name: name,
        phone: item.mobile,
        redText: redText,
        sendText: "ارسال رسالة",
        deleteText: "حذف",
        code: item.user.verify.emailCode,
        subType:
          item.user.transactions == null || item.user.transactions.length == 0
            ? ""
            : "الخطة" + " " + item.user.transactions[0]._plan.name,
      };
    });
    this.studentsData = TransformDataService.getTableSourceDataFromOrigin(
      this.students,
      this.studentsDataKeys,
      isTableItemColorize,
      isTableItemHasCirclePointer
    );
  }
  addStudent() {
    this.student = {};
    if (this.plans?.length > 0) {
      this.student.plan = this.plans[0].id;
    }
    this.addNewStudentTurnedOn = true;
  }
  exportExcel() {
    this.userApi.export(this.filter(), "");
  }
  blockedChanged() {
    this.getStudentData();
  }
  getStartDate(transactions) {
    if (transactions != null && transactions.length > 0) {
      transactions = transactions
        .filter(
          (x) =>
            x.isDeleted != true &&
            x.isSuccess == true && moment(new Date()).diff(moment(x.endDate))
        )
        .sort(function (left, right) {
          return moment.utc(left.startDate).diff(moment.utc(right.startDate));
        });
      if (transactions.length > 0) {
        return moment(transactions[0].startDate).format("MMMM DD,YYYY");
      }
    }
    return "";
  }
  getEndDate(transactions) {
    if (transactions != null && transactions.length > 0) {
      transactions = transactions
        .filter(
          (x) =>
            x.isDeleted != true &&
            x.isSuccess == true && moment(new Date()).diff(moment(x.endDate))
        )
        .sort(function (left, right) {
          return moment.utc(left.endDate).diff(moment.utc(right.endDate));
        });
      if (transactions.length > 0) {
        return transactions[transactions.length - 1];
      }
    }
    return {};
  }
  getduration(endDate) {
    moment.locale("ar");
    if (endDate) {
      var duration = moment.duration(moment(endDate).diff(moment())).humanize();
      moment.locale("en");
      return duration;
    }
    moment.locale("en");
    return "";
  }
  isValidMobile(mobile) {
    if (mobile.length >= 10) {
      return true;
    } else return false;
    // return /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{9})$/.test(mobile);
  }
  saveNewStudent() {
    if (!this.isValidMobile(this.student.phone)) {
      this.invalidMobile = true;
      return;
    } else {
      this.invalidMobile = false;
    }
    const user = new User();
    user.name = this.student.name;
    user.email = this.student.email;
    user.mobile = this.student.phone;
    user.password = this.student.password;
    user.ignoreCaptcha = true;
    this.userApi.createUser(user).subscribe(
      (res) => {
        let selectedPlan = this.plans.find(item => item.id == this.student.plan);
        if (this.student.subPeriod != null && this.student.plan != null) {
          let transaction = {
            userId: res.id,
            planId: this.student.plan,
            phoneNumber: this.student.phone,
            allowExam: selectedPlan.allowExam,
            allowLecture: selectedPlan.allowLecture,
            amount: 0,
            isSuccess: true,
            startDate: new Date(),
            endDate: moment(new Date(), "DD-MM-YYYY").add(
              this.student.subPeriod,
              "days"
            ),
          };
          this.transactionApi.saveTransaction(transaction).subscribe((res) => {
            this.getStudentData();
            this.addNewStudentTurnedOn = false;
          });
        } else {
          this.getStudentData();
          this.addNewStudentTurnedOn = false;
        }
      },
      (error) => {
        console.log(error);
        alert("error :" + error.status)
        this.emailExists = false;
        if (error.status === 451) {
          this.emailExists = true;
        } else if (error.status === 450) {
          this.phoneExist = true;
        } else {
          this.errorRegister = true;
        }
      }
    );
  }
  onSearch() {
    this.getStudentData();
  }
}
