<table class="table-with-pagination">
  <!-- table heading -->
  <thead>
    <tr class="table-with-pagination-heading-items">
      <th *ngFor="let tableHeadingItem of tableHeadingItems" class="table-with-pagination-heading-items-item">
        <div class="table-with-pagination-heading-items-item-content">
          <span>{{tableHeadingItem.i18n}}</span>
        </div>
      </th>
    </tr>
  </thead>
  <tbody class="table-with-pagination-body-items">
    <tr *ngFor="let tableBodyItem of displayBodyItems" class="table-with-pagination-body-items-item">
      <td *ngFor="let itemOf of tableBodyItem.items">
        <div class="table-with-pagination-body-items-item-content">
          <p *ngIf="itemOf.isColorize">
            <span class="text-blue pointer" (click)="loginAsStudent(tableBodyItem)">
              {{itemOf.loginText || itemOf.i18n}}&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span class="text-blue pointer" (click)="reNewParticipat(tableBodyItem)">{{itemOf.blueText || itemOf.i18n}}&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span class="text-red pointer" (click)="blockUser(itemOf)">{{itemOf.redText}}&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span class="text-blue pointer" (click)="sendMessage(tableBodyItem)">{{itemOf.sendText}}&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span class="text-red pointer" (click)="removeUser(tableBodyItem)">{{itemOf.deleteText}}&nbsp;&nbsp;&nbsp;&nbsp;</span>

          </p>
          <p *ngIf="!itemOf.isColorize">
            <span *ngIf="itemOf.hasCirclePointer" class="small-text-overalap-circle"></span>
            <span>{{itemOf.i18n || ''}}</span>
          </p>
        </div>
      </td>
    </tr>
  </tbody>
</table>
<!-- table footer -->
<div class="table-with-pagination-pagination">
  <ul class="table-with-pagination-pagination-content">
    <li (click)="downPage()" class="pagination-control-left pagination-control pointer">
      <div class="left-control-icon icon-100">
        <div class="arrow-icon icon-100"></div>
      </div>
    </li>
    <li *ngFor="let paginationPageCount of paginationPagesCount; let ID = index;" [ngClass]="{'active': ID+1 == paginationPageID }"
        class="pagination-count-item" (click)="onPage(ID+1)">
      <span>{{ID+1}}</span>
    </li>
    <li (click)="upPage()" class="pagination-control-right pagination-control pointer">
      <div class="right-control-icon icon-100">
        <div class="arrow-icon icon-100"></div>
      </div>
    </li>
  </ul>
</div>