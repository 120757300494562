import { Router } from '@angular/router';
import { AuthenticationService } from '@services/apis/authentication.service';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { AdminContinueBlockComponent } from '../../../admin/components/admin-continue-block-component/admin-continue-block.component';
import { AdminsendMessageComponent } from '../../../admin/components/admin-continue-send-message-component/admin-continue-send-messagecomponent';
import { AdminContinueSubscribeComponent } from '../../../admin/components/admin-continue-subscribe-component/admin-continue-subscribe.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessagesService } from "@services/apis/messages.service";
import { PlansService } from "@services/apis/plans.service";
import { TransactionsService } from "@services/apis/transaction.service";
import { AdminContinueDeleteComponent } from "@modules/admin/components/admin-continue-delete-component/admin-continue-deletecomponent";
import { UsersService } from "@services/apis/users.service";
@Component({
  selector: "app-table-with-pagination",
  templateUrl: "./table-with-pagination.html",
})
export class TableWithPaginationComponent implements OnInit, OnChanges {
  @Input() tableHeadingItems: Array<any>;
  @Input() tableBodyItems: Array<any>;
  @Input() maxItemsCountPerPage: number;
  @Output() blockedChanged: EventEmitter<any> = new EventEmitter<any>();
  activePaginationItem: number;
  displayBodyItems: Array<any>;
  paginationPagesCount: Array<number>;
  reNewParticipation: boolean;
  selectedPerson: any;
  student: any;
  plans: any;
  isSubPeriod: boolean;
  selectedItem: any;
  isSendMessage: boolean;
  blockPopupOpened: boolean;
  userBlock: any;
  paginationPageID: number;
  constructor(
    private planApi: PlansService,
    private modalService: NgbModal,
    private userApi: UsersService,
    private authApi: AuthenticationService,
    private router: Router,

  ) {
    this.paginationPageID = 1;
  }
  async ngOnInit() {
    // this.maxItemsCountPerPage = 1;
    this.student = {};
    this.formPaginationRes();
    this.plans = await this.planApi.query({}).toPromise();
    this.plans = this.plans.result;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tableBodyItems != null && changes.tableBodyItems.currentValue) {
      this.formPaginationRes();
      let to = (this.paginationPageID - 1) * this.maxItemsCountPerPage;
      let from = this.paginationPageID * this.maxItemsCountPerPage;
      this.displayBodyItems = this.tableBodyItems.slice(to, from);
    }
  }
  formPaginationRes(): void {
    if (this.maxItemsCountPerPage && this.tableBodyItems != null) {
      let modules = this.tableBodyItems.length % this.maxItemsCountPerPage;
      let count = Math.floor(
        this.tableBodyItems.length / this.maxItemsCountPerPage
      );
      if (modules != 0) {
        count = count + 1;
      }
      this.paginationPagesCount = new Array(count);
    } else {
      this.paginationPagesCount = new Array(1);
    }
  }
  reNewParticipat(item) {
    this.selectedItem = item;
    this.student = {};
    if (this.plans.length > 0) {
      this.student.plan = this.plans[0].id;
    }
    this.student.endDate = item.items[0].endDate;
    this.student.transactionId = item.items[0].transactionId;
    this.student.phone = item.items.find((x) => x.key == "phone").i18n;
    this.student.name = item.items.find((x) => x.key == "name").i18n;
    const ask = this.modalService.open(AdminContinueSubscribeComponent, {
      windowClass: "forgot-password",
    });
    ask.result.then(async (result) => {
      this.blockedChanged.emit();
    }, async (reason) => {
      this.blockedChanged.emit();
    });
    ask.componentInstance.student = this.student;
    ask.componentInstance.selectedItem = this.selectedItem;
  }


  loginAsStudent(item) {
    console.log(item);
    this.authApi.loginAsStudent(item.items[0].id).subscribe(res => {
      console.log(res);
      if (res && res.id) {
        localStorage.setItem("currentUser", JSON.stringify(res));
        this.router.navigate(['/', 'profile', 'me']);
        setTimeout(() => {
          location.reload();
        }, 1500);
      }
    })
  }

  sendMessage(item) {
    this.selectedItem = item;
    this.student = {};
    this.student.phone = item.items.find((x) => x.key == "phone").i18n;
    this.student.name = item.items.find((x) => x.key == "name").i18n;
    this.isSendMessage = true;
    const ask = this.modalService.open(AdminsendMessageComponent, {
      windowClass: "forgot-password",
    });
    ask.result.then(async (result) => {
      this.blockedChanged.emit();
    }, async (reason) => {
      this.blockedChanged.emit();
    });
    ask.componentInstance.student = this.student;
    ask.componentInstance.selectedItem = this.selectedItem;
  }
  blockUser(user) {
    this.userBlock = user;
    //this.blockPopupOpened = true;
    const ask = this.modalService.open(AdminContinueBlockComponent, {
      windowClass: "forgot-password",
    });
    ask.result.then(async (result) => {
      this.blockedChanged.emit();
    }, async (reason) => {
      this.blockedChanged.emit();
    });
    ask.componentInstance.userBlock = this.userBlock;
  }
  handleBlockClose() {
    this.blockPopupOpened = false;
    this.userBlock = null;
  }
  upPage() {
    if (
      this.paginationPagesCount.length != this.paginationPageID &&
      this.paginationPagesCount.length > this.paginationPageID
    ) {
      this.paginationPageID = this.paginationPageID + 1;
      let to = (this.paginationPageID - 1) * this.maxItemsCountPerPage;
      let from = this.paginationPageID * this.maxItemsCountPerPage;
      this.displayBodyItems = this.tableBodyItems.slice(to, from);
    }
  }
  downPage() {
    if (this.paginationPageID > 1) {
      this.paginationPageID = this.paginationPageID - 1;
      let to = (this.paginationPageID - 1) * this.maxItemsCountPerPage;
      let from = this.paginationPageID * this.maxItemsCountPerPage;
      this.displayBodyItems = this.tableBodyItems.slice(to, from);
    }
  }
  onPage(num) {
    this.paginationPageID = num;
    let to = (this.paginationPageID - 1) * this.maxItemsCountPerPage;
    let from = this.paginationPageID * this.maxItemsCountPerPage;
    this.displayBodyItems = this.tableBodyItems.slice(to, from);
  }
  removeUser(item) {
    let itemName = item.items.find(i => i.key == "name");
    let itemId = item.items[0].id;
    const ask = this.modalService.open(AdminContinueDeleteComponent, {
      windowClass: "forgot-password",
    });
    ask.result.then(
      async (result) => {
      },
      async (result) => {
        console.log(result);
        if (result) {
          this.userApi.deleteUser(itemId).subscribe((res) => {
            this.blockedChanged.emit();
          }, (error) => {
            this.blockedChanged.emit();
          });
        }
      }
    );
    ask.componentInstance.messageConfirm =
      "  هل انت متاكد من حذف " + itemName.i18n + " ";
  }
}
