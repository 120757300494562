import { Component, OnInit } from "@angular/core";
import { async } from '@angular/core/testing';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessagesService } from '@services/apis/messages.service';
import * as moment from "moment";
import { SendResponseComponent } from '../admin-send-response/admin-send-response';
import { UsersMock } from "../constants/admin-fields.mock";

@Component({
  selector: "app-admin-root",
  templateUrl: "./admin-root.html",
  styleUrls: ["./admin-root.component.sass"],
})
export class AdminRootComponent implements OnInit {
  updatesCards: Array<any>;
  editStudentPopupOpened: boolean;
  studentIndexToEdit: number;
  selectedPerson: any;
  response:string;
  constructor(private messageApi:MessagesService,
              private modalService: NgbModal,
              private activatedRoute:ActivatedRoute){

  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params)=>{
      window.scroll(0, 0);
    });
    let receiver='';
    const currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      receiver=currentUser.userId;
    }
    let resultMessages:any=await this.messageApi.query({where:
      {
        isSeen:false,
        toAdmin:true
      }}).toPromise();
    this.updatesCards = resultMessages.result;
  }

  editStudent(studInselected) {
    this.selectedPerson = studInselected;
    //this.editStudentPopupOpened = true;
    const ask = this.modalService.open(SendResponseComponent, {
      windowClass: "forgot-password",
    });
    ask.result.then(async(result) => {
      let resultMessages:any=await this.messageApi.query({where:
        {
          isSeen:false,
          toAdmin:true
        }}).toPromise();
      this.updatesCards = resultMessages.result;
    },async (reason) => {
      let resultMessages:any=await this.messageApi.query({where:
        {
          isSeen:false,
          toAdmin:true
        }}).toPromise();
      this.updatesCards = resultMessages.result;
    });
    ask.componentInstance.selectedPerson=studInselected;
  }
}
