import { SummariesService } from "./../../services/apis/summaries.service";
import { Component, OnInit, Input } from "@angular/core";
import { LecturesService } from "@services/apis/lectures.service";

@Component({
  selector: "summaries",
  templateUrl: "./summaries.component.html",
  styleUrls: ["./summaries.component.sass"],
})
export class SummariesComponent implements OnInit {
  @Input() isRightNavOpened: boolean;
  isTimeThrowed: boolean;
  summaries: any[] = [];

  constructor(
    public lecturesService: LecturesService,
    private summeryApi: SummariesService
  ) {}

  ngOnInit(): void {
    this.summeryApi.query({}).subscribe((res) => {
      this.summaries = res["result"];
    });
  }
}
