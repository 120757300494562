import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { CoursesService } from "@services/apis/courses.service";
import { error } from "protractor";


import { ArticlesMock, VideoCardsMock } from "../constants/admin-fields.mock";

@Component({
  selector: "app-admin-articles-list",
  templateUrl: "./admin-articles-list.html",
  styleUrls: ["./admin-articles-list.sass"],
})
export class AdminArticlesListComponent implements OnInit {
  article: any;
  activeStep: number;
  videoCards: Array<any>;
  courses:any;
  deletePopupOpened:boolean;
  deletedCourse:any;
  constructor(private coursesApi: CoursesService,
              private router:Router,
              private activatedRoute:ActivatedRoute) {}
  async ngOnInit() {
    this.activatedRoute.params.subscribe((params)=>{
      window.scroll(0, 0);
    });
   const coursesResult:any=  await this.coursesApi.query({}).toPromise();
   this.courses=coursesResult.result;
  }

  handleClose(saveBefore?: boolean) {
    if (saveBefore) {
      this.coursesApi.destroy(this.deletedCourse.id).subscribe(async(res)=>{
        const coursesResult:any=  await this.coursesApi.query({}).toPromise();
        this.courses=coursesResult.result;
      },(error)=>{

      })
    }
    this.deletePopupOpened = false;
  }
  removeCourse(course){
    this.deletePopupOpened=true;
    this.deletedCourse=course;
  }
  editCourse(course){
    if(course){
    this.router.navigate(["/dashboard/admin/articles-modify/"+course.id]);
    }else{
      this.router.navigate(["/dashboard/admin/articles-modify"]);
    }
  }
}
