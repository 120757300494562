

   <div class="lections-create-test">
    <div
      class="lections-create-test--content"
      [ngClass]="{'collapsed': lecturesService.rightNavState }"
    >
      <div class="lections-create-test--content--section">

<div>

    <div  class="admin-articles-modify-middle margfo margfo heightheader">
        <span class="spanedit"> مدخل الى اساسيات المحاسبة المحاضرة الاولى    </span>
        <div style="margin-left: 4%;">
        <img class="editimg"  src="assets/icons/teacher.svg"  />
      
       </div>
           </div>
           <div class="card cardsub" >

            <div class="divmain" >
               <span class="spantitle">تحدث الكفاءة التخصيصية عندما يكون الإنتاج متوافقا مع تفضيلات 
                 المستهلك . قط لأن النتيجة الأقتصادية فعالة لا تفعل ذلك
                  </span>  
                  <div class="spaneditto">
                    <span class="time">18:22</span> 
                <img  class="editmove" src="assets/icons/material-timer.svg">
  
                </div>
    
            </div>

            <div class="divmain" style="border: none;" >
                <h5 class="spantitle">حقوق الملكية :التوزيع العادل للمنافع الاقتصادية
                </h5>  
                <h5 class="spantitle">
                    على سبيل المثال يدعم بعض الاشخاص فرض الضرائب على الاشخاض ذوى الدخل المرتفع
                </h5>  

                   <div class="spaneditto">
                     <span class="time">18:22</span> 
                 <img  class="editmove" src="assets/icons/material-timer.svg">
   
                 </div>
     
             </div>

            </div>


            <div  class="admin-articles-modify-middle margfo">
                <span class="spanedit"> مدخل الى اساسيات المحاسبة المحاضرة الاولى    </span>
                <div style="margin-left: 4%;">
                <img class="editimg"  src="assets/icons/teacher.svg"  />
              
               </div>
                   </div>
                   <div class="card cardsub" >
        
                    <div class="divmain" >
                       <span class="spantitle">تحدث الكفاءة التخصيصية عندما يكون الإنتاج متوافقا مع تفضيلات 
                         المستهلك . قط لأن النتيجة الأقتصادية فعالة لا تفعل ذلك
                          </span>  
                          <div class="spaneditto">
                            <span class="time">18:22</span> 
                        <img  class="editmove" src="assets/icons/material-timer.svg">
          
                        </div>
            
                    </div>
        
                    <div class="divmain" style="border: none;" >
                        <h5 class="spantitle">حقوق الملكية :التوزيع العادل للمنافع الاقتصادية
                        </h5>  
                        <h5 class="spantitle">
                            على سبيل المثال يدعم بعض الاشخاص فرض الضرائب على الاشخاض ذوى الدخل المرتفع
                        </h5>  
        
                           <div class="spaneditto">
                             <span class="time">18:22</span> 
                         <img  class="editmove" src="assets/icons/material-timer.svg">
           
                         </div>
             
                     </div>
        
                    </div>

</div>

</div>
</div></div>