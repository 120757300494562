<div class="form-field-file form-field">
  <div *ngIf="withAddIcon" class="form-field-file-with-add-icon mt-3">
    <label>{{label}}</label>
    <div class="add-icon icon-100"></div>
  </div>
  <div class="form-field-file-container">
    <div (click)="deleteImage()" class="btn-icon btn-icon-remove icon-circle c-pointer mt-5 pt-5"></div>
    <input
      class="form-control-file"
      type="file"
      id="{{ID}}"
      name="{{name}}"
      (change)="updateValue($event)"
    />
    <div class="form-control-file-mask">
      <span>{{placeholder}}</span>
    </div>
  </div>
  <div *ngIf="isError" class="form-field-error">{{error}}</div>
</div>
