<div class="admin-articles-modify">
  <div style="max-width: 90%;
  align-self: center;
  margin-bottom: 10px;" class="admin-articles-modify-middle">
    <span>أضافة مادة جديدة</span>
    <button (click)="editCourse(null)" class="btn icon-circle btn-icon-plus"></button>
  </div>
  <div
  name="adminCourse"
  style="margin-bottom: 30px;"
  *ngFor="let course of courses"
 >
  <div class="admin-articles-modify-content" >
    <div class="admin-articles-modify-article-info">
      <div class="admin-articles-modify-article-info-card-right">
      
         <div class="admin-articles-modify-content-2-header padding">
          <div class="admin-articles-modify-content-2-header-preview-top">
            <span>{{course.type}}</span>
            <span>{{course.name}}</span>
          </div>
          <div style="direction: rtl" class="admin-articles-modify-content-2-header-preview-bottom text-align">
            <span style="direction: rtl"> {{course.description}}</span>
          </div>
        </div>
 

      </div>
      <div class="form-field form-field-submit">
        <button
          type="submit" class="btn btn-green submit-btn no-border"
         (click)="editCourse(course)">
          تعديل
        </button>

      </div>
    </div>
  </div>
</div>
</div>
