import { AdminMainControlSaveComponent } from "./../admin-main-control-component/admin-main-control-save-component/admin-main-control-save-component";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { async } from "@angular/core/testing";
import { NgModel } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LecturesService } from "@services/apis/lectures.service";
import { QuestionssService } from "@services/apis/questions.service";
import { debug } from "console";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { AdminQuestionConfirmComponent } from "../admin-question-confirm-component/admin-question-confirm.component";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { QuestionBankItems } from "../constants/admin-fields.mock";

@Component({
  selector: "app-admin-questions-bank-lecture",
  styleUrls: ["./admin-questions-bank-lecture.sass"],
  templateUrl: "./admin-questions-bank-lecture.html",
})
export class AdminQuestionsBankLectureComponent implements OnInit {
  public Editor = ClassicEditor;
  questionBankItems: Array<any>;
  activeStep: number;
  @Input() type: string;
  @Output() focus: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
  // todo
  // Update this variables with real data-naming.
  paginationPagesCount: Array<number>;
  activePaginationItem: number;
  newQuestionBankItem: any = {};
  radioItemPlaceholders: Array<string> = [];
  isquestionRequired: boolean;
  selectedlecture: any = {};
  uploadFileError: boolean;
  Questions: any;
  isdeleteConfirm: boolean;
  noChoices: boolean = false;
  isRightChoicesProvided: boolean = false;
  lectureId: any;
  answerError: boolean = false;
  isQuestionImage: boolean;
  questionImage: any;
  config = {
    contentsLangDirection: "rtl",
    toolbar: [
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
          "Strike",
          "Subscript",
          "Superscript",
          "-",
          "CopyFormatting",
          "RemoveFormat",
        ],
      },
      {
        name: "paragraph",
        items: [
          "NumberedList",
          "BulletedList",
          "-",
          "Outdent",
          "Indent",
          "-",
          "Blockquote",
          "CreateDiv",
          "-",
          "JustifyLeft",
          "JustifyCenter",
          "JustifyRight",
          "JustifyBlock",
          "-",
          "BidiLtr",
          "BidiRtl",
        ],
      },
      { name: "links", items: ["Link", "Unlink", "Anchor"] },
      {
        name: "insert",
        items: [
          "Table",
          "HorizontalRule",
          "Smiley",
          "SpecialChar",
          "PageBreak",
        ],
      },
      "/",
      {
        name: "styles",
        items: ["Styles", "Format", "fontFamily", "Font", "FontSize"],
      },
      { name: "colors", items: ["TextColor", "BGColor"] },
    ],
  };


  constructor(
    private router: Router,
    private LectureApi: LecturesService,
    private QuestionssApi: QuestionssService,
    public sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async (params) => {
      window.scroll(0, 0);
      this.lectureId = params.lectureId;
      this.selectedlecture = await this.LectureApi.get(
        this.lectureId
      ).toPromise();
      let Questionss: any = await this.QuestionssApi.query({
        where: { lectureId: this.selectedlecture.id },
      }).toPromise();
      this.Questions = Questionss.result;
      if (this.Questions.length > 0) {
        this.activePaginationItem = 0;
        this.newQuestionBankItem = this.Questions[0];
        if (this.newQuestionBankItem.questionImage) this.isQuestionImage = true;
        this.fixingRightAnswer();
      } else {
        this.newQuestionBankItem = {
          question: "",
          lectureId: this.selectedlecture.id,
          files: [],
          type: "مقالى",
          choiceCount: "كلاهما",
          level: "سهل",
        };
      }
    });
    this.radioItemPlaceholders = [
      "كلاهما",
      "عشوائي",
      "تدريبي",
      "مقالي",
      "اختيار من متعدد",
    ];
    this.paginationPagesCount = new Array(2);
    this.activePaginationItem = 1;
    this.newQuestionBankItem = {
      question: "عرف المحاسبة المالية ؟",
      rightAnswer: "الإجابة الصحيحة",
    };
  }
  updatePage() {
    this.saveQuestion(true);
    window.scroll(0, 0);
  }

  countDown() {
    if (this.activePaginationItem > 0) {
      this.activePaginationItem = this.activePaginationItem - 1;
      this.newQuestionBankItem = this.Questions[this.activePaginationItem];
      this.fixingRightAnswer();
      window.scroll(0, 0);
    }
  }

  removeQuestion() {
    //this.isdeleteConfirm = true;
    const askLecture = this.modalService.open(AdminQuestionConfirmComponent, {
      windowClass: "forgot-password",
    });
    askLecture.result.then(
      async (result) => {
        //this.addcollectionAnswer(result);
      },
      async (reason) => {
        if (reason) {
          this.deleteConfirm();
        }
      }
    );
  }
  deleteConfirm() {
    if (this.newQuestionBankItem.id != null) {
      this.QuestionssApi.destroy(this.newQuestionBankItem.id).subscribe(
        (res) => {
          const ask = this.modalService.open(AdminMainControlSaveComponent, {
            windowClass: "forgot-password",
          });
          ask.componentInstance.displayMessage = "تم مسح السؤال بنجاح ";
          // this.router.navigateByUrl("/dashboard/admin/questions-bank").then();
        }
      );
    }
  }

  countUp() {
    if (this.activePaginationItem < this.Questions.length) {
      this.activePaginationItem = this.activePaginationItem + 1;
      this.newQuestionBankItem = this.Questions[this.activePaginationItem];
      this.fixingRightAnswer();
      window.scroll(0, 0);
    }
  }
  togglePhoto() {
    this.isQuestionImage = !this.isQuestionImage;
  }

  goToQuestion(id: number) {
    if (this.activePaginationItem < this.Questions.length) {
      this.activePaginationItem = id;
      this.newQuestionBankItem = this.Questions[this.activePaginationItem];
      this.fixingRightAnswer();
      window.scroll(0, 0);
    }
  }

  validateAnswers() {
    this.answerError = false;
    this.noChoices = false;
    this.isquestionRequired = false;
    this.isRightChoicesProvided = false;
    if (!this.newQuestionBankItem) {
      return false;
    }
    if (
      this.newQuestionBankItem == null ||
      ((this.newQuestionBankItem.question == "" ||
        this.newQuestionBankItem.question == null) &&
        !this.newQuestionBankItem.questionImage)
    ) {
      this.isquestionRequired = true;
      return false;
    } else if (
      this.newQuestionBankItem.rightAnswer == null ||
      this.newQuestionBankItem.rightAnswer == ""
    ) {
      console.log(this.newQuestionBankItem);
      this.answerError = true;
      return false;
    } else if (
      this.newQuestionBankItem.type == "اختيار من متعدد" &&
      (!this.newQuestionBankItem.choices ||
        this.newQuestionBankItem.length == 0)
    ) {
      this.noChoices = true;
      return false;
    } else if (this.newQuestionBankItem.type == "اختيار من متعدد") {
      let isAnswerProvided = this.newQuestionBankItem.choices.findIndex(
        (x) => x.value == "1"
      );
      if (isAnswerProvided == -1) {
        this.isRightChoicesProvided = true;
        return false;
      }
    }
    return true;
  }

  saveQuestion(addAnather) {
    if (this.validateAnswers() == false) {
      return;
    } else {
      this.answerError = false;
      if (this.newQuestionBankItem.rightAnswer.includes("\n")) {
        this.newQuestionBankItem.rightAnswer = this.newQuestionBankItem.rightAnswer.split(
          "\n"
        );
        this.newQuestionBankItem.rightAnswer = this.newQuestionBankItem.rightAnswer.join(
          "/////"
        );
      }

      console.log(this.newQuestionBankItem);
      this.QuestionssApi.save(this.newQuestionBankItem).subscribe(
        async (res) => {
          console.log(res);
          let Questionss: any = await this.QuestionssApi.query({
            where: { lectureId: this.selectedlecture.id },
          }).toPromise();
          this.Questions = Questionss.result;
          if (addAnather) {
            this.newQuestionBankItem = {
              question: "",
              lectureId: this.selectedlecture.id,
              files: [],
              type: "مقالى",
              choiceCount: "كلاهما",
              level: "سهل",
            };
          } else {
            const ask = this.modalService.open(AdminMainControlSaveComponent, {
              windowClass: "forgot-password",
            });
            ask.componentInstance.displayMessage = "تم حفظ السؤال بنجاح ";
            // this.router.navigateByUrl("/dashboard/admin/questions-bank").then();
          }
        }
      );
    }
  }

  updateImage(event) {
    if (event && event.path) {
      this.newQuestionBankItem.questionImage = event.path;
      this.isquestionRequired = false;
    }
  }



  updateRadioValue(event) {
    this.newQuestionBankItem.type = event;
    if (event == "مقالى") {
      this.newQuestionBankItem.choices = [];
    }
  }
  updateRadioValueChoiceCount(event) {
    this.newQuestionBankItem.choiceCount = event;
  }
  updateRadioValueLevel(event) {
    this.newQuestionBankItem.level = event;
  }
  changeChoices(event) {
    this.newQuestionBankItem.choices = event;
  }
  async onFileChange(event: any) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const fileUploaded = await this.QuestionssApi.upload(fileList[0])
        .pipe(
          catchError((error) => {
            console.error(error);
            this.uploadFileError = true;
            return of(null);
          })
        )
        .toPromise();
      if (fileUploaded) {
        this.newQuestionBankItem.file = fileUploaded;
      }
    }
  }
  removeFile() {
    this.newQuestionBankItem.file = null;
  }
  annulateQuestion() {
    this.router.navigateByUrl("/dashboard/admin/questions-bank").then();
  }

  fixingRightAnswer() {
    if (this.newQuestionBankItem.rightAnswer.includes("/////")) {
      this.newQuestionBankItem.rightAnswer = this.newQuestionBankItem.rightAnswer.split(
        "/////"
      );
      this.newQuestionBankItem.rightAnswer = this.newQuestionBankItem.rightAnswer.join(
        "\n"
      );
    }
  }


  iframeStyle() {
    let frame: any = document.getElementById("questionIframe");
    // console.log(frame.contentDocument.children[0].children[1]);
    // console.log(frame.contentWindow);
    var style = document.createElement("style");
    style.textContent =
      `body {
        float: right;
        overflow: hidden;
        white-space: break-spaces;
      }
      td {
        border: 1px solid black;
        
      }
      table {
        margin-right:0;
        width: ${frame.clientWidth - 30}px;
      }
      p, strong, pre, div {
        text-align: right;
        white-space: break-spaces ;
        font-family: 'SegoeUI' ;

      }
      `;
    frame.contentDocument.head.appendChild(style);
    frame.height = frame.contentWindow.document.body.scrollHeight + "px";
    frame.contentDocument.children[0].setAttribute("dir", "rtl");
    frame.contentDocument.children[0].setAttribute("lang", "ar");
    var iframeDoc = frame.contentDocument || frame.contentWindow.document;
    if (typeof iframeDoc.addEventListener != "undefined") {
      iframeDoc.addEventListener("contextmenu", this.rightMenuDisable, false);
      iframeDoc.addEventListener("selectstart", this.rightMenuDisable, false);
    } else if (typeof iframeDoc.attachEvent != "undefined") {
      iframeDoc.attachEvent("contextmenu", this.rightMenuDisable);
      iframeDoc.attachEvent("selectstart", this.rightMenuDisable);
    }
  }

  rightMenuDisable(event: Event) {
    event.preventDefault();
    return false;
  }

  getRightAnswerFromChoices() {
    if (this.newQuestionBankItem && this.newQuestionBankItem.choices) {
      const rightAnswers = this.newQuestionBankItem.choices.filter(
        (x) => x.value == 1
      );
      return rightAnswers[0];
    }
  }



  hideToolbar() {
    document.getElementById('cke_editor2').childNodes[1]['style'].display = 'none'
  }
}
