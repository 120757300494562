<div class="admin-continue-subscribe padding">
  <!-- Continue subscribe dialog -->
  <div class="admin-edit-user-dialog-content">
    <div class="admin-edit-user-dialog-content-header">
      <div class="close-icon-gray icon-100" (mousedown)="close()" style="margin-top: 10px;"></div>
      <span style="margin-top: 10px;"> ارسال رسالة</span>
    </div>
    <div class="admin-edit-user-dialog-content-body ">
      <p class="person-info text">
        <span class="text-blue">{{student.phone}}</span>
        <span>ارسالة رساله لطالب</span>
        <span class="text-blue">{{student.name}}</span>

        <span>صاحب رقم </span>
      </p>
      <div class="person-info-field form-field">
        <label>اكتب الرسالة</label>
        <textarea style="direction: RTL" class="comment-textarea form-control" [(ngModel)]="student.message"
          [ngModelOptions]="{ standalone: true }">
        </textarea>
      </div>
    </div>
  </div>
  <div class="admin-edit-user-dialog-content-footer margin">
    <button class="btn-green btn-large no-border" (click)="handleMessageClose(true)">
      ارسال
    </button>
    <button class="btn-dark btn-large no-border" (click)="close()">
      الغاء
    </button>
  </div>
</div>