<div class="admin-articles-modify">
  <div class="admin-articles-modify-preview" *ngIf="false">
    <p class="title-text">لا توجد اختبارات حتى الان</p>
    <p class="title-last-text">
      قم بإضافة محاضرات حتى تتمكن من ربط الاختبارات بها
    </p>
    <div class="btn-area">
      <button class="btn no-border btn-large btn-green">اضافة محاضرة</button>
    </div>
  </div>
  <div class="">
    <form (ngSubmit)="f.form.valid && saveModifiedArticle()" #f="ngForm" name="adminCourse" *ngIf="activeStep==1">
      <div class="admin-articles-modify-content">
        <div class="admin-articles-modify-article-info">
          <div class="form-field">
            <label>اسم المادة</label>
            <div style="flex-direction: column">
              <button class="btn-icon btn-edit-icon"></button>
              <input class="form-control" type="text"
                placeholder="التحضير لمادة المحاسبة لأختبار الهيئة  السعودية للمحاسبين القانونين"
                [(ngModel)]="course.name" #name="ngModel" required [ngModelOptions]="{ standalone: true }" />
              <div class="row">
                <div class="col-lg-12 feedback_height">
                  <div class="row justify-content-end">
                    <div *ngIf="(f.submitted || name.touched) && name.hasError('required')"
                      class="form-control-feedback">
                      اسم الماده مطلوب
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-field">
            <label>نوع المادة</label>
            <div style="flex-direction: column">
              <button class="btn-icon btn-edit-icon"></button>
              <input class="form-control" type="text" placeholder="مادة المحاسبة" [(ngModel)]="course.type"
                [ngModelOptions]="{ standalone: true }" #type="ngModel" required />
              <div class="row">
                <div class="col-lg-12 feedback_height">
                  <div class="row justify-content-end">
                    <div *ngIf="(f.submitted || type.touched) && type.hasError('required')"
                      class="form-control-feedback">
                      نوع الماده مطلوب
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-field">
            <label>وصف المادة</label>
            <div style="flex-direction: column">
              <button class="btn-icon btn-edit-icon"></button>
              <textarea class="form-control comment-textarea" type="text" [(ngModel)]="course.description"
                [ngModelOptions]="{ standalone: true }" #description="ngModel" required></textarea>
              <div class="row">
                <div class="col-lg-12 feedback_height">
                  <div class="row justify-content-end">
                    <div *ngIf="(f.submitted || description.touched) && description.hasError('required')"
                      class="form-control-feedback">
                      وصف الماده مطلوب
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-field form-field-submit">
            <button type="submit" class="btn btn-green submit-btn no-border">
              تقسيم المحاضرات
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="admin-articles-modify-content-2" *ngIf="activeStep==2">
      <div class="admin-articles-modify-content-2-header">
        <div class="admin-articles-modify-content-2-header-preview-top" *ngIf="course.type || course.name">
          <span>{{course.type}}</span>
          <span>{{course.name}}</span>
        </div>
        <div style="direction: rtl" class="admin-articles-modify-content-2-header-preview-bottom text-align">
          <span style="direction: rtl"> {{course.description}}</span>
        </div>
      </div>
      <div class="admin-articles-modify-middle">
        <span>أضافة محاضرة</span>
        <button class="btn icon-circle btn-icon-plus" (click)="addLecture()"></button>
      </div>
      <div class="admin-articles-modify-videos">
        <div class="admin-articles-modify-videos-cards-card" *ngFor="let Lecture of Lectures; let ID = index;">
          <div class="form-field">
            <label>اسم المحاضرة</label>
            <div class="btn-icon btn-icon-remove c-pointer" (click)="removeOneLecture(Lecture.id)"
            data-toggle="tooltip" data-placement="bottom" title="حذف المحاضرة"></div>
            <input class="form-control" type="text" placeholder="من نحن وماذا نعمل ؟" [(ngModel)]="Lecture.LectureName"
              [ngModelOptions]="{ standalone: true }" />
          </div>
          <div class="admin-articles-modify-videos-cards-card-body">
             <!-- <vime-youtube [videoId]="youtubeLinkToId(Lecture.videoLink)"></vime-youtube> -->
             <div>.</div>
            <vime-player controls *ngIf="Lecture.type=='link' && Lecture.videoLink">
              <vime-video>
                <source [src]="Lecture.videoLink" />
              </vime-video>
            </vime-player>

            <vime-player controls *ngIf="Lecture.type=='upload' && Lecture.videoSrc && Lecture.videoSrc.path">
              <vime-video>
                <source [src]="Lecture.videoSrc.path" />
              </vime-video>
            </vime-player>
          </div>
          <div class="admin-articles-modify-videos-cards-card-footer">
            <button class="btn-large btn-light-gray no-border" (click)="linkOrUpload(Lecture)">
              {{Lecture.type=='link' ? "إرفاق فيديو": "ادخل رابط الفيديو"}}
            </button>
          </div>
          <div class="admin-articles-modify-videos-cards-card-footer-2">
            <!-- <div>
              <span>{{Lecture.fileName}}</span>
              <div class="btn-icon btn-icon-remove c-pointer" (click)="removeFile(ID)"></div> -->
            <!-- </div> -->
            <div class="form-field" *ngIf="Lecture.type=='link'">
              <label>ادخل رابط الفيديو</label>
              <input class="form-control" type="text" placeholder="  رابط الفيديو" [(ngModel)]="Lecture.videoLink"
                [ngModelOptions]="{ standalone: true }" />
            </div>

            <div class="form-field" *ngIf="Lecture.type!=='link'">
              <tk-upload [(value)]="Lecture.videoSrc" [title]="'أرفاق فيديو'" [filetype]="'mise'"></tk-upload>
            </div>


            <tk-gallery-list [(value)]="Lecture.attachments" [title]="'أرفاق الملخصات'" [filetype]="'file'">
            </tk-gallery-list>
            <!-- <app-video-uploader [placeholder]="fileUploaderSrc" [type]="'file'" [index]="ID" [baseApi]="lectureApi"
              (changeVideoSrc)="handleVideoSrcChange($event)">
            </app-video-uploader> -->
            <div *ngIf="Lectures[ID].isLoaded==false" class="form-control-feedback" style="color: red">
              حدث خطا فى تحميل الملف
            </div>
          </div>
        </div>
      </div>
      <div class="admin-articles-modify-footer margin-div">
        <button class="btn-green btn-large no-border" (click)="saveArticleUpdates()">
          حفظ
        </button>

      </div>
    </div>
  </div>
</div>