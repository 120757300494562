<div class="lections-create-test">
  <div class="lections-create-test--content" [ngClass]="{'collapsed': lecturesService.rightNavState }">
    <div class="lections-create-test--content--section">

      <div class="main margfo">

        <div class="card cardsub ">

          <div class="divmain" *ngFor="let summery of summaries; let i = index">
            <a target="_blank" [href]="summery?.path" download><span class="spantitle">{{i + 1}}-
                {{summery?.originalFilename || summery?.name}}</span></a>
            <a class="spansub" target="_blank" [href]="summery?.path" download> <img src="assets/icons/download.svg" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>