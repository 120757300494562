import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CoursesService } from "@services/apis/courses.service";
import { LecturesService } from "@services/apis/lectures.service";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";

import { VideoCardsMock } from "../constants/admin-fields.mock";
import { FormControl } from "@angular/forms";
import { EventManager } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AdminContinueDeleteComponent } from "../admin-continue-delete-component/admin-continue-deletecomponent";

@Component({
  selector: "app-admin-articles-modify",
  templateUrl: "./admin-articles-modify.html",
  styleUrls: ["./admin-articles-modify.sass"],
})
export class AdminArticlesModifyComponent implements OnInit {
  course: any;
  activeStep: number;
  Lectures: Array<any> = [];
  courseId: any;
  errorFileUpload = false;
  videoCards: Array<any>;
  article: any = {};
  videoUploaderSrc: string;
  fileUploaderSrc: string;
  name: FormControl;
  video: any;
  addLink: boolean = false;
  addLinkString: string = "ادخل رابط الفيديو";
  string;
  constructor(
    private coursesApi: CoursesService,
    private activatedRoute: ActivatedRoute,
    public lectureApi: LecturesService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  async onFileChange(event: any, index) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      let fileUploaded = await this.coursesApi
        .upload(file)
        .pipe(
          catchError((error) => {
            this.Lectures[index].FileUpload = false;
            console.error(error);
            return of(null);
          })
        )
        .toPromise();
      if (fileUploaded) {
        this.Lectures[index].FileUpload = true;
        this.Lectures[index].originalFilename = fileUploaded.originalFilename;
        this.Lectures[index].path = fileUploaded.path;
      }
    }
  }

  ngOnInit() {
    this.name = new FormControl();
    this.videoCards = VideoCardsMock;
    // todo
    // Left those properties only for merge.
    this.article = {};
    this.videoUploaderSrc = "أرفاق فيديو";
    this.fileUploaderSrc = "أرفاق ملخصات المحاضره";
    this.course = {};
    this.activeStep = 1;
    this.activatedRoute.params.subscribe((params) => {
      this.courseId = params.id;
      if (this.courseId) {
        this.lectureApi
          .query({ where: { courseId: this.courseId } })
          .subscribe((res: any) => {
            this.Lectures = res.result;
          });
        this.coursesApi.get(this.courseId).subscribe((res) => {
          this.course = res;
        });
      }
    });
  }

  addLecture() {
    this.Lectures.push({ courseId: this.courseId });
  }

  async saveArticleUpdates() {
    for (let Lecture of this.Lectures) {
      await this.lectureApi.save(Lecture).toPromise();
    }
    this.router.navigate(["/dashboard/admin/articles-list"]);
  }
  async removeArticleUpdates() {
    const ask = this.modalService.open(AdminContinueDeleteComponent, {
      windowClass: "forgot-password",
    });
    ask.result.then(
      async (result) => {
        if (result) {
          for (let Lecture of this.Lectures) {
            await this.lectureApi.destroy(Lecture.id).toPromise();
          }
          await this.coursesApi.destroy(this.courseId).toPromise();
          this.router.navigate(["/dashboard/admin/articles-list"]);
        }
      },
      async (result) => {
        if (result) {
          for (let Lecture of this.Lectures) {
            await this.lectureApi.destroy(Lecture.id).toPromise();
          }
          await this.coursesApi.destroy(this.courseId).toPromise();
          this.router.navigate(["/dashboard/admin/articles-list"]);
        }
      }
    );
    ask.componentInstance.messageConfirm =
      "  هل انت متاكد من حذف " + this.course.name + "";
  }

  removeOneLecture(id: string) {
    const index = this.Lectures.findIndex((lec) => lec.id == id);
    const ask = this.modalService.open(AdminContinueDeleteComponent, {
      windowClass: "forgot-password",
    });
    ask.result.then(
      async (result) => {
        if (result) {
          this.lectureApi.destroy(id).toPromise();
        }
      },
      async (result) => {
        if (result) {
          this.lectureApi.destroy(id).toPromise();
          this.Lectures.splice(index, 1);
        }
      }
    );
    ask.componentInstance.messageConfirm =
      "  هل انت متاكد من حذف " + this.Lectures[index].LectureName + "";
  }

  removeVideo(index) {
    this.Lectures[index].videoName = null;
  }
  removeFile(index) {
    this.Lectures[index].fileName = null;
    this.Lectures[index].filePath = null;
  }
  saveModifiedArticle() {
    console.log(this.course);
    if (
      this.course.type != null &&
      this.course.type != "" &&
      this.course.name != null &&
      this.course.name != "" &&
      this.course.description != null &&
      this.course.description != ""
    ) {
      this.coursesApi.save(this.course).subscribe(
        (res) => {
          this.course = res;
          this.activeStep = 2;
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.activeStep = 1;
      return;
    }
  }

  handleVideoSrcChange(event) {
    if (event.type == "file") {
      if (event.isLoaded) {
        this.Lectures[event.index].fileName = event.originalFilename;
        this.Lectures[event.index].filePath = event.path;
        this.Lectures[event.index].isLoaded = true;
      } else {
        this.Lectures[event.index].isLoaded = false;
        this.Lectures[event.index].fileName = null;
        this.Lectures[event.index].filePath = null;
      }
    } else {
    }
  }

  addLecturer() {
    this.activeStep = 2;
  }

  linkOrUpload(lection) {
    if (lection.type == "link") {
      lection.videoLink = "";
    } else {
      lection.videoSrc = {};
    }
    lection.type = lection.type === "link" ? "upload" : "link";
  }
  youtubeLinkToId(url) {
    if (url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    }
  }
}
