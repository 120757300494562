<div class="home-analytics">
  <div class="home-analytics-container">
    <div *ngIf="participation != null" class="home-analytics-details">
      <p>تم الاشتراك في الخطة {{ participation.name }}</p>
      <ul>
        <li><b>.امكانية مشاهدة المقاطع المسجلة في أي وقت تشاء</b></li>
        <li><b> .تنفيذ الاختبارات بعد كل موضع يتم شرحه</b></li>
        <li>
          <b
            >.امكانية انشاء اختبارات عشوائية و ايضا تحديد المواضيع التي يرغب
            المشترك في اجراء الاختبار حولها</b
          >
        </li>
        <li>
          <b
            >ارسال استفسارات لمحاضر الدورة عن النقاط التي تحتاج لتوضيح اكثر من
            قبل المحاضر
          </b>
        </li>
      </ul>
    </div>
    <div *ngIf="participation == null">
      <h3>لايوجد اشتراك</h3>
    </div>
    <div
      *ngIf="transactions != null && participation != null"
      class="home-analytics-subscribtion-details"
    >
      <div class="home-analytics-subscribtion-details-block">
        <span>المدة المتبقية:</span>
        <span
        ><b>&nbsp;&nbsp;&nbsp;&nbsp;{{ years }} {{months}} {{days}} </b></span>
      </div>
      <div class="home-analytics-subscribtion-details-block">
        <span *ngIf="transactions != null"
          ><b>{{ endDate | date: "dd/MM/yyyy" }}</b></span
        >
        <span>تاريخ انتهاء الأشتراك </span>
      </div>
      <div class="home-analytics-subscribtion-details-block">
        <span *ngIf="transactions != null"
          ><b>{{ startDate | date: "dd/MM/yyyy" }}</b></span
        >
        <span>تاريخ بدء الأشتراك </span>
      </div>
    </div>
    <div class="home-analytics-renew-sub">
      <button class="btn-green btn no-border" (click)="reparticipation()">
        تجديد الأشتراك
      </button>
    </div>
    <!-- <app-context-menu
      *ngIf="participation != null && participation.name == 'العادية'"
      [contextMenuContent]="contextMenuContent"
      [contextMenuSwitcher]="contextMenuSwitcher"
      [contextMenuClass]="'home-analytics-context-menu'"
      [contextMenuSwitcherClass]="'home-analytics-context-menu-switcher'"
      [contextMenuSwitcherID]="'home-analytics-context-menu-switcher'"
    ></app-context-menu> -->
  </div>
  <app-dialog-component
    (emitClose)="handlepromiseClose()"
    [isOpen]="isAnalyticsDialogOpened"
    [modalDialogContent]="modalDialogContent"
    [modalDialogFooter]="modalDialogFooter"
    [closeBtnText]="'اتعهد'"
    ></app-dialog-component>
  <ng-template #contextMenuContent>
    <div
      *ngIf="participation != null && participation.name == 'العادية'"
      class="flying_div"
    >
      <p>تم الاشتراك في الخطة {{ participation.name }}</p>
      <p>
        يمكنك ترقية الخطة خلال 15 يوم من بداية الاشتراك عن طريق الاتصال على
        الرقم في الاسفل وتحويل مبلغ الفرق(4000ريال) الى الحساب التالي وارسال
        إيصال التحويل على الرقم التالي 132003330008
      </p>
    </div>
  </ng-template>
  <ng-template #contextMenuSwitcher>
    <div class="switcher-content">
      <span>ترقية للخطة المكثفة</span>
      <img src="assets/icons/info-circle-icon.png" />
    </div>
  </ng-template>
  <ng-template #modalDialogContent>
    <div class="home-analytics-dialog-content">
      <img src="assets/images/law-dialog.png" />
      <p>
        قال تعالى (يَا أَيُّهَا الَّذِينَ آمَنُوا أَوْفُوا بِالْعُقُودِ) اتعهد
        امام الله بعدم مشاركة حسابي مع الغير ولن نحلل مشاركة بيانات حسابك مع
        الغير
      </p>
    </div>
  </ng-template>
  <ng-template #modalDialogFooter>
      <button class="btn-green btn no-border promise "  (click)="handlepromiseClose()">
        اتعهد
      </button>
  </ng-template>
</div>
