<div class="counter-items" (mouseout)="choicesChanged()">
 
  <div class="counter-items-heading">
    <div  class="counter-items-heading-left">
      <div *ngIf="type==='اختيار من متعدد'">
        <span>إجابات مجمعة</span>
        <button (click)="addcollAnswers()" class="icon-circle btn-icon-plus btn-icon-plus-dark"></button>
      </div>
      <div *ngIf="type==='اختيار من متعدد'">
        <span>إضافة اختيار</span>
        <button (click)="addNewChoice()" class="icon-circle btn-icon-plus btn-icon-plus-dark"></button>
      </div>
    </div>
    <div class="radio-buttons-group-items">
      <app-form-control-radio
        [value]="'صعب'"
        [name]="'Level'"
        [placeholder]="counterItemPlaceholders[0]"
        [ID]="'level'"
        [model]="level"
        (changeValuelevel)="updateRadioValue($event)"
      >
      </app-form-control-radio>
      <app-form-control-radio
        [value]="'متوسط'"
        [name]="'Level'"
        [placeholder]="counterItemPlaceholders[1]"
        [ID]="'level'"
        [model]="level"
        (changeValuelevel)="updateRadioValue($event)"
      >
      </app-form-control-radio>
      <app-form-control-radio
        [value]="'سهل'"
        [name]="'Level'"
        [placeholder]="counterItemPlaceholders[2]"
        [ID]="'level'"
        [model]="level"
        (changeValuelevel)="updateRadioValue($event)"
      >
      </app-form-control-radio>
    </div>
  </div>
  <div *ngIf="type==='اختيار من متعدد'" class="counter-items-body">
    <div
      class="counter-items-item"
      *ngFor="let counterItem of counterItems; let itemID = index;"
    >
      <div class="range-controls">
        <div>
          <button
            class="range-controls--minus btn-icon btn-icon-minus"
            (click)="countDown(itemID)"
          ></button>
          <input
            type="number"
            value="{{counterItems[itemID].value}}"
            class="range-controls-count"
            disabled
          />
          <button
            class="range-controls--plus btn-icon btn-icon-plus"
            (click)="counterUp(itemID)"
          ></button>
        </div>
      </div>
      <div class="range-controls-text">
        <div class="btn-icon btn-icon-small btn-icon-remove c-pointer" (click)="removeChoice(itemID)"></div>
        <input [(ngModel)]="counterItems[itemID].text" style="direction: rtl;" type="text" />
      </div>
    </div>
  </div>

</div>
