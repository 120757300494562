import { LecturesService } from "./../../../services/apis/lectures.service";
import { TimerService } from "./timer.service";
import { ExamsService } from "./../../../services/apis/exam.service";
import { AnswersService } from "./../../../services/apis/answers.service";
import { QuestionssService } from "./../../../services/apis/questions.service";
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  HostListener,
} from "@angular/core";
import { NgbNavConfig } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { Subscription } from "rxjs";
const FileSaver = require("file-saver");

@Component({
  selector: "app-exam",
  templateUrl: "./exam.html",
  styleUrls: ["./exam.scss"],
  providers: [NgbNavConfig],
})
export class ExamComponent implements OnInit {
  answer: boolean;
  answersForBorder: any[] = [];
  lectureId: string;
  examId: string;
  answers: any[] = [];
  questions: any[] = [];
  questsNum: number;
  displayedQuest: number = 0;
  currentQuestion: number = 1;
  answered: boolean = false;
  isRightAnswer: boolean = false;
  finalQuestion: boolean = false;
  userId: string;
  exam: any;
  newAnswer: any;
  examForNumbersComponent: any;
  right: number = 0;
  wrong: number = 0;
  isCompleted: boolean = false;
  flag = false;
  questionsIds: string[] = [];
  time: number = 0;
  subscription1: Subscription;
  display;
  interval;
  showAnswer: boolean = false;
  answeredArticleQuestions: number = 0;
  ArticleQuestionsNumber: number = 0;
  constructor(
    private router: Router,
    config: NgbNavConfig,
    private activatedRoute: ActivatedRoute,
    private examsApi: ExamsService,
    private lecApi: LecturesService,
    private timer: TimerService
  ) {
    config.destroyOnHide = false;
    config.roles = false;
  }

  @HostListener("window:keydown", ["$event"])
  onKeyPress($event: KeyboardEvent) {
    if (
      ($event.ctrlKey || $event.metaKey) &&
      $event.keyCode != undefined &&
      $event.keyCode == 67
    ) {
      return false;
    }
  }

  onTextSelect(event: Event) {
    return false;
  }

  async ngOnInit() {
    this.removeTimeExamStatus();
    window.onload = () => this.removeTimeExamStatus;
    this.userId = JSON.parse(localStorage.getItem("currentUser")).userId;
    this.activatedRoute.params.subscribe((params) => {
      if (this.router.url.indexOf("/lecture") > -1) {
        this.lectureId = params.lectureId;
        this.getDataByLectureId();
      } else {
        this.examId = params.examId;
        this.examsApi.get(this.examId).subscribe((res) => {
          if (res != null) {
            this.exam = res;
            this.examForNumbersComponent = res;
            let examTime: string = "";
            let examCompletion: boolean = false;
            if (this.exam.time) examTime = this.exam.time;
            if (this.exam.isCompleted) examCompletion = this.exam.isCompleted;
            this.startTime(examTime, examCompletion);
            if (this.exam.isCompleted != true) {
              this.isCompleted = false;
              this.displayedQuest = this.exam.currentQuestion - 1 || 0;
              this.currentQuestion = this.displayedQuest + 1;
            } else if (this.exam.isCompleted && this.exam.isCompleted == true) {
              this.isCompleted = true;
            }
            this.examForNumbersComponent = this.exam;
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.removeTimeExamStatus();
  }

  private getDataByLectureId() {
    // creat exam from remoteMethod create-exam
    this.examsApi.creatExamFromLectureId(this.lectureId).subscribe((res) => {
      this.examId = res;
      this.examsApi.get(res).subscribe((exam) => {
        this.exam = exam;
        this.examForNumbersComponent = exam;
        let examTime: string = "";
        let examCompletion: boolean = false;
        if (this.exam.time) examTime = this.exam.time;
        if (this.exam.isCompleted) examCompletion = this.exam.isCompleted;
        this.startTime(examTime, examCompletion);
      });
    });
  }

  articleCounter(articleNumber: number) {
    this.ArticleQuestionsNumber = articleNumber;
  }

  questionsArray(questionsArray: []) {
    this.questions = questionsArray;
    this.questsNum = this.questions.length;
    this.passLectNameToNavbar();
  }

  isAnswered(isAnswered: boolean) {
    this.answered = isAnswered;
  }

  isRightAnswerEvent(isRightAnswer: boolean) {
    this.isRightAnswer = isRightAnswer;
  }

  rightAnswersNumber(rightAnswersNumber: number) {
    this.right = rightAnswersNumber;
  }

  showAnswerEvent(showAnswerEvent: boolean) {
    this.showAnswer = showAnswerEvent;
  }

  examUpdates(exam: any) {
    this.examForNumbersComponent = exam;
  }

  answerForBorder(answerForBorder: any) {
    this.newAnswer = answerForBorder;
  }

  changeQuestion(step: string) {
    this.showAnswer = false;
    if (step == "next" && this.displayedQuest < this.questsNum - 1) {
      this.displayedQuest = this.displayedQuest + 1;
      this.currentQuestion = this.displayedQuest + 1;
    } else if (step == "prev" && this.displayedQuest != 0) {
      this.displayedQuest = this.displayedQuest - 1;
    }
    this.answered = false;
    if (this.displayedQuest == this.questsNum - 1) {
      this.finalQuestion = true;
    }
  }

  getRightAnswerFromChoices() {
    const rightAnswers = this.questions[this.displayedQuest].choices.filter(
      (x) => x.value == 1
    );
    return rightAnswers[0];
  }

  downloadTable() {
    FileSaver.saveAs(
      this.questions[this.displayedQuest].file.path,
      this.questions[this.displayedQuest].file.originalFilename
    );
  }

  isFileProvided() {
    if (
      this.questions[this.displayedQuest] &&
      this.questions[this.displayedQuest].file
    )
      return true;
    else return false;
  }

  submitExam() {
    let rightAnswers = this.right;
    let currentQuestion = this.questsNum - 1;
    this.isCompleted = true;
    let wrong = this.questsNum - rightAnswers;
    let time = document.getElementById("time_spent").innerHTML;
    const finalExam = {
      id: this.exam.id,
      userId: this.userId,
      questionNumbers: this.questsNum,
      isCompleted: true,
      rightAnswers: rightAnswers,
      wrongAnswers: wrong,
      currentQuestion: currentQuestion,
      time: time,
    };
    this.examsApi.update(finalExam).subscribe((res) => {
      this.examForNumbersComponent = res;
    });
    if (this.isCompleted == true)
      this.router.navigateByUrl("lections/previous-tests");
  }

  transform(value: number): string {
    let minutes: number = Math.floor(value / 60);
    let hours: number = Math.floor(minutes / 60);
    return hours + ":" + (minutes - hours * 60) + ":" + (value - minutes * 60);
  }

  startTime(examTime: string, examCompletion: boolean) {
    this.timer.changeExam(true);
    if (this.exam.timeMode && this.exam.timeMode == true) {
      this.timer.changeTimeStatus(true);
      if (examTime) {
        const time = moment(examTime, "HH:mm:ss").format("HH:mm:ss");
        this.timer.changeTime(JSON.stringify(time));
      } else this.timer.changeTime("00:00:00");
    }
    this.timer.changeExamStatus(examCompletion);
  }

  removeTimeExamStatus() {
    this.timer.changeTime("00:00:00");
    this.timer.changeExam(false);
    this.timer.changeExamStatus(false);
  }

  passLectNameToNavbar() {
    if (this.questions && this.questions[this.displayedQuest]) {
      this.lecApi
        .get(this.questions[this.displayedQuest]?.lectureId)
        .subscribe((lec) => {
          let name: string;
          if (this.exam.examType == "تدريبي")
            name = `${lec.LectureName} (اختبار تدريبي)`;
          else name = "(اختبار عشوائي)";
          this.timer.changeLecName(name);
        });
    }
  }

  changeQuestFromNumbersComp(questionNumber) {
    this.displayedQuest = questionNumber;
    this.currentQuestion = questionNumber;
    this.answered = false;
  }

  rightAnswerSplitting(rightAnswer: string) {
    if (rightAnswer && rightAnswer.includes("/////")) {
      const rightAnswersArray = rightAnswer.split("/////");
      return rightAnswersArray;
    }
  }

  rightAnswerIsArray(rightAnswer: string) {
    if (rightAnswer && rightAnswer.includes("/////")) return true;
    else return false;
  }

  onRightClick(event: Event) {
    return false;
  }

  changeNumbersToAr(text) {
    let map = [
      "&#1632;",
      "&#1633;",
      "&#1634;",
      "&#1635;",
      "&#1636;",
      "&#1637;",
      "&#1638;",
      "&#1639;",
      "&#1640;",
      "&#1641;",
    ];

    if (text) {
      text = text.replace(/\d(?=[^<>]*(<|$))/g, ($0) => {
        return map[$0];
      });
      return text;
    }
  }
}
