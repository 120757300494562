import { CouponsService } from "./../../../../services/apis/coupon.service";
import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { User } from "@modules/_models/User";
import { AuthenticationService } from "@services/apis/authentication.service";
import { PlansService } from "@services/apis/plans.service";
import { UsersService } from "@services/apis/users.service";
import { ReCaptcha2Component } from "ngx-captcha";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.sass"],
})
export class RegisterComponent implements OnInit {
  registerFellowshipCells: any;
  activeStep: number;
  paymentInfoSubmitted: boolean;
  registerForm: any;
  emailExists = false;
  invalidMobile = false;
  notMatched = false;
  participationItem: any = {};
  user: any;
  includeCaptcha: boolean = false;
  captchaKey: string = "";
  captchaFailed: boolean;
  captchaSuccess = false;
  captchaIsExpired = false;
  captchaResponse?: string;
  currentUser: any;
  errorWithRegister: boolean;
  phoneExist: boolean;
  @ViewChild("captchaElem", { static: false }) captchaElem: ReCaptcha2Component;
  codeWrong: boolean = false;
  isCouponUsed: boolean = false;
  wrongPlan: boolean = false;
  constructor(
    private UsersApi: UsersService,
    private couponsApi: CouponsService,
    private cdr: ChangeDetectorRef,
    private authService: AuthenticationService,
    private matSnackbar: MatSnackBar
  ) { }
  async ngOnInit() {
    this.currentUser = localStorage.getItem("currentUser");
    this.registerForm = {};
    if (this.currentUser) {
      this.activeStep = 1;
    } else {
      this.activeStep = 0;
    }
  }

  getPressOnFellowshipTableResolveAction(item) {
    this.participationItem = item;
    this.participationItem.afterDiscount = this.participationItem.text;
    // console.log(this.participationItem);
    // console.log(Number(this.participationItem.text));
    this.updateFormStep(2);
    // this.matSnackbar.open(
    //   "بوابة الدفع غير متاحة حالياً",
    //   "تم",
    //   {
    //     duration: 5000,
    //     direction: "rtl",
    //   }
    // );
  }
  isValidMobile(mobile) {
    if (mobile.length >= 10) {
      return true;
    } else return false;
    // return /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{9})$/.test(mobile);
  }
  handleSuccess($event) {
    this.captchaKey = $event;
    this.includeCaptcha = true;
  }
  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaKey = undefined;
    this.captchaIsExpired = false;
    this.captchaKey = "";
    this.cdr.detectChanges();
  }
  updateFormStep(stepID: number) {
    if (stepID !== 1) {
      this.activeStep = stepID;
      return;
    }
    this.resetError();
    if (!this.isValidMobile(this.registerForm.phone)) {
      this.invalidMobile = true;
      return;
    } else {
      this.invalidMobile = false;
    }
    if (this.registerForm.confirmPassword !== this.registerForm.password) {
      this.notMatched = true;
      return;
    }
    const user = new User();
    user.firstName = this.registerForm.firstName;
    user.lastName = this.registerForm.lastName;
    user.email = this.registerForm.email;
    user.mobile = this.registerForm.phone;
    user.password = this.registerForm.password;
    user.captchaResponse = this.captchaKey;
    this.UsersApi.createUser(user).subscribe(
      (res) => {
        this.user = res;
        this.authService
          .login(user.email, user.password)
          .subscribe(async (result) => {
            localStorage.setItem("currentUser", JSON.stringify(result));
            this.activeStep = stepID;
          });
        this.captchaElem.resetCaptcha();
      },
      (error) => {
        console.log(error);
        if (error.status === 451) {
          this.emailExists = true;
        } else if (error.status === 450) {
          this.phoneExist = true;
        } else if (error.status === 400) {
          this.captchaFailed = true;
        } else {
          this.errorWithRegister = true;
        }
        this.captchaElem.resetCaptcha();
      }
    );
  }
  resetError() {
    this.emailExists = false;
    this.captchaFailed = false;
    this.errorWithRegister = false;
    this.includeCaptcha = true;
    this.notMatched = false;
    this.phoneExist = false;
  }
  showPassword(id) {
    (document.getElementById(id) as HTMLInputElement).type = "text";
  }

  hidePassword(id) {
    (document.getElementById(id) as HTMLInputElement).type = "password";
  }
  handlePaymentInfo(data: any) {
    this.updateFormStep(3);
  }
  setIncludeCaptcha($event) {
    this.captchaResponse = $event;
    this.includeCaptcha = true;
  }

  handleVerificationData(data: any) { }

  couponValidation(couponCode) {
    // console.log(couponCode);
    // console.log(this.participationItem);
    this.codeWrong = false;
    this.wrongPlan = false;
    if (this.participationItem.text !== this.participationItem.afterDiscount) {
      this.isCouponUsed = true;
    }
    this.couponsApi
      .query({ where: { code: couponCode }, order: "created desc" })
      .subscribe((res) => {
        if (res["count"] > 0 && this.isCouponUsed != true) {
          const coupons = res['result'];
          const index = coupons.findIndex(x => x.plan == this.participationItem.id);
          console.log('index', index);
          if (index > -1) {
            const discount =
              (Number(this.participationItem.text) / 100) *
              Number(coupons[index]["percentage"]);
            // console.log("discount", discount);
            this.participationItem.afterDiscount =
              Number(this.participationItem.text) - discount;
            this.participationItem.couponId = coupons[index].id;
          } else {
            this.wrongPlan = true;
            setTimeout(() => {
              this.wrongPlan = false;
            }, 5000);
          }
        } else {
          if (this.isCouponUsed == true) return;
          else this.codeWrong = true;
        }
      });
  }
}
