<div class="admin-continue-subscribe">
  <!-- Continue subscribe dialog -->
  <div class="admin-edit-user-dialog-content padding">
    <div class="admin-edit-user-dialog-content-header">
      <div class="close-icon-gray icon-100" (mousedown)="close()"></div>
      <span>تجديد الاشتراك</span>
    </div>
    <div class="admin-edit-user-dialog-content-body">
      <p class="person-info text">
        <span>تجديد الاشتراك لمستخدم</span>
        <span class="text-blue">{{student.name}}</span>
        <span>صاحب رقم </span>

        <span class="text-blue">{{student.phone}}</span>
      </p>
      <div style="flex-direction: column;" class="person-info-field person-info-field-top form-field">
        <label style="text-align: right">نوع الاشتراك</label>
        <div class="radio-buttons-group-items">
          <div class="div-radio" *ngFor="let plan of plans ">
            <div>
              <label class="margin-label">الخطة {{plan.name}}</label>
              <input (change)="onValueChange($event)" type="radio" [value]="plan.id" name="intensePlan"
                [(ngModel)]="planId" />
            </div>
          </div>
        </div>
      </div>
      <div class="person-info-field form-field">
        <label>ادخل عدد ايام الاشتراك الجديدة</label>
        <input type="number" style="direction: RTL" class="form-control" [(ngModel)]="student.subPeriod"
          [ngModelOptions]="{ standalone: true }" />

        <div *ngIf="isSubPeriod" class="form-control-feedback" style="color: red">
          ايام الاشتراك غير صحيحه
        </div>
        <table *ngIf="transactions && transactions.length>0" style="margin-top: 10px;" class="table-with-pagination">
          <thead>
            <tr class="table-with-pagination-heading-items">
              <th class="table-with-pagination-heading-items-item">
                <div class="table-with-pagination-heading-items-item-content">
                  <span></span>
                </div>
              </th>
              <th class="table-with-pagination-heading-items-item">
                <div class="table-with-pagination-heading-items-item-content">
                  <span>تاريخ الانتهاء</span>
                </div>
              </th>
              <th class="table-with-pagination-heading-items-item">
                <div class="table-with-pagination-heading-items-item-content">
                  <span>تاريخ الاشتراك</span>
                </div>
              </th>
              <th class="table-with-pagination-heading-items-item">
                <div class="table-with-pagination-heading-items-item-content">
                  <span> نوع الاشتراك</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="table-with-pagination-body-items">
            <tr *ngFor="let transaction of transactions let i=index" class="table-with-pagination-body-items-item">
              <td *ngIf="i+1==transactions.length">
                <div class="table-with-pagination-body-items-item-content" (click)="removeTransaction(transaction)">
                  <p>
                    <span class="small-text-overalap-circle"></span>
                    <span class="text-red pointer" 
                    >حذف&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </p>
                </div>
              </td>
              <td *ngIf="i+1 !=transactions.length">
                <div class="table-with-pagination-body-items-item-content" (click)="removeTransaction(transaction)">
                  <p>
                    <span class="small-text-overalap-circle"></span>
                    <span class="text-red pointer" 
                    ></span>
                  </p>
                </div>
              </td>
              <td>
                <div class="table-with-pagination-body-items-item-content">
                  <p>
                    <span class="small-text-overalap-circle"></span>
                    <span>{{transaction.endDate | date}}</span>
                  </p>
                </div>
              </td>
              <td>
                <div class="table-with-pagination-body-items-item-content">
                  <p>
                    <span class="small-text-overalap-circle"></span>
                    <span>{{transaction.startDate | date}}</span>
                  </p>
                </div>
              </td>
              <td>
                <div class="table-with-pagination-body-items-item-content">
                  <p>
                    <span class="small-text-overalap-circle"></span>
                    <span>{{transaction._plan?transaction._plan.name:''}}</span>
                  </p>
                </div>
              </td>
            </tr>


          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="admin-continue-subscribe-dialog-content-footer margin">
    <button class="btn-green btn-large no-border" (click)="handleClose(true)">
      تجديد الاشتراك
    </button>
    <button class="btn-dark btn-large no-border" (click)="close()">
      الغاء
    </button>
  </div>
</div>