import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-form-control-radio",
  templateUrl: "./form-control-radio.html",
  styleUrls: ["./form-control-radio.sass"],
})
export class FormControlRadioComponent implements OnInit {
  @Input() name: string;
  @Input() ID: string | number;
  @Input() placeholder: string;
  @Output() changeValue = new EventEmitter<string>();
  @Output() changeValueChoiceCount = new EventEmitter<string>();
  @Output() changeValuelevel =new EventEmitter<string>();
  @Input() value:string;
  @Input() model:string;
  constructor() {}

  formField: FormControl;

  ngOnInit() {
    this.formField = new FormControl();
    }

  updateValue(event) {
    if(this.name=='choice'){
       this.changeValue.emit(event);
    }else if(this.name=='choiceCount'){
       this.changeValueChoiceCount.emit(event);
    }else if (this.name=='Level'){
      this.changeValuelevel.emit(event);
    }
  }
}
