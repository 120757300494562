<div class="lections-create-test">
    <div class="lections-create-test--content" [ngClass]="{'collapsed': lecturesService.rightNavState }">
        <div class="lections-create-test--content--section">
            <div class="lections-previous-tests">
                <div class="ui-grid row justify-content-center lections-previous-tests--content--section">
                    <div class="col-lg-12 border_bottom">
                        <h1 class="text-right ">
                            نسبة الإنجاز
                        </h1>
                    </div>
                    <div class="col-lg-12">
                        <div class="row reverse justify-content-between sm-screen">
                            <div class="col-lg-9 pt-5">
                                <!-- <span class=" text-right margin-text margin-span ">{{percentage}}% </span> -->
                                <span class=" text-right margin-text margin-span ">{{getPercentage()}}% </span>
                                <ngb-progressbar class="  progressComplete" type="success" [value]="getPercentage()">
                                </ngb-progressbar>
                                <!-- <div class="progress">
                                    <div class="fullProgrss">
                                        <div class="progress-bar bg_green text-right  progressComplete"
                                            role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">

                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="col-lg-3 d-flex justify-content-end">
                                <!-- (click)="competeLecture()" -->
                                <button routerLink="/loading" [state]="{ route: 'complete',userLectures: userLectures ,lectures:lectures  }"
                                        *ngIf="percentage < 100" class="bg_green complete margin-btn px-3">
                                    إستكمال
                                    <img src="../../../../../assets/images/exams/ionic-ios-arrow-round-back-white.svg" alt="">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lections-previous-tests--display-previous-tests">
                    <div class="ui-grid row">
                        <div class="col-lg-12 border_bottom pb-3">
                            <h1 class="text-right">
                                {{course?.name}}
                            </h1>
                        </div>
                        <div *ngFor="let lec of lectures; let i = index" class="col-lg-12 border_bottom py-3">
                            <div class="row reverse sm-sc-wrap lecture_row_sm">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="row reverse">
                                        <h3 class="px-3 green font20 sm-sc-word-wrap">.{{i + 1}}</h3>
                                        <!-- (click)="watchLectures(lec.id) " -->
                                        <h3 [routerLink]="['/lection/watch/'+lec.id]" class="green pointer font20 sm-sc-word-wrap"
                                            data-toggle="tooltip" data-placement="bottom" title="{{lec?.LectureName}}">
                                            {{lec?.LectureName}}
                                        </h3>
                                        <h3 class="px-3 green pointer font20 sm-sc-word-wrap" *ngIf="getLastSeenTime(lec?.id) !=null">
                                            {{'(' + getLastSeenTime(lec?.id) + ')'}}</h3>
                                        <!-- (click)="watchLectures(lec.id)" -->
                                        <img [routerLink]="['/lection/watch/'+lec.id]" class="px-3 pointer d_none_sm"
                                             src="../../../../../assets/images/exams/ionic-ios-arrow-round-back.svg" alt="">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="row reverse flrx_row_sm">
                                        <div *ngIf="authApi.isAllowExam()" class="col-lg-4 col-md-4 col-6 d-flex reverse">
                                            <img src="../../../../../assets/images/exams/feather-edit.svg" alt="">
                                            <a class="blue font15 mr-3 sm-sc-word-wrap pt-2" routerLink="/loading"
                                               [state]="{ route: 'exam',lectureId: lec.id }">
                                                <!-- [routerLink]="['/exam/lecture/'+lec.id]" -->
                                                <!-- (click)="openRecreateModal(lec.id)" -->
                                                إختبار تدريبي
                                            </a>
                                        </div>
                                        <!-- <div class="col-lg-4 col-md-4 col-6 d-flex reverse pointer" (click)="askLecturer(lec?.id)">
                                            <img src="../../../../../assets/images/exams/raise-hand.svg" alt="">
                                            <p class="blue font15 mr-3 sm-sc-word-wrap pt-2">
                                                إسال المحاضر
                                            </p>
                                        </div> -->
                                        <div class="col-lg-6 col-md-6 col-12 d-flex justify-content-end">
                                            <p *ngIf="getLastSeen(lec?.id) != null" class="font15 pb_sm">
                                                اخر مشاهدة
                                                {{getLastSeen(lec?.id)| date: "dd/MM/yyyy"}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>