import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "@services/apis/authentication.service";
import { TransactionsService } from "@services/apis/transaction.service";
import { UsersService } from "@services/apis/users.service";
import { SocketService } from "@services/socket.service";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.sass"],
})
export class AuthComponent implements OnInit {
  authForm: any;
  phoneVerificationStepActive: boolean;
  verificationInfo: Array<string>;
  loginFailed = false;
  userNotFound = false;
  includeCaptcha = false;
  userId;
  user: any;
  errorWithlogin = false;
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private transactiontransAPI: TransactionsService,
    private usersApi: UsersService,
    private socketService: SocketService
  ) {}

  ngOnInit() {
    this.authForm = {
      email: "",
      password: "",
    };
    this.verificationInfo = [];
  }

  submitStep(stepID: number) {
    if (!this.authForm.email || !this.authForm.password) {
      return;
    }
    this.userLogin(this.authForm.email, this.authForm.password, stepID);
  }
  private userLogin(email, password, stepID) {
    localStorage.removeItem("currentUser");
    this.authService.login(email, password).subscribe(
      async (result) => {
        this.userId = result.userId;
        this.user = result;
        localStorage.setItem("currentUser", JSON.stringify(this.user));
        let _user: any = await this.authService.get(this.userId).toPromise();
        this.socketService.connect()
        //this.user = _user;
        if (!_user.isVerified) {
          this.phoneVerificationStepActive = true;
        } else {
          const transactiontran: any = await this.transactiontransAPI
            .query({ where: { userId: this.userId } })
            .toPromise();
          let haveTransactiontran = transactiontran.result.find(
            (x) => x.isSuccess === true
          );
          if (_user.isadmin) {
            this.user.isadmin = true;
            localStorage.setItem("currentUser", JSON.stringify(this.user));

            this.router.navigate(["/dashboard/admin/articles-list"]).then();
          } else {
            this.router.navigate(["/profile/subjects"]).then();
          }
        }
      },
      (error) => {
        console.log(error);
        this.errorWithlogin = true;
        if (error.status === 401) {
          this.userNotFound = true;
          this.loginFailed = false;
        }
      }
    );
  }
  async handleVerificationData(data: string) {
    // const Code = data.join("");
    let _profile: any = await this.usersApi.get(this.userId).toPromise();
    this.authService.confirmEmail(data, _profile.email).subscribe(
      async (result: any) => {
        const transactiontran: any = await this.transactiontransAPI
          .query({ where: { userId: this.userId } })
          .toPromise();
        let haveTransactiontran = transactiontran.result.find(
          (x) => x.isSuccess === true
        );
        if (this.user.isadmin) {
          this.router.navigate(["/dashboard/admin/articles-list"]).then();
        } else {
          if (haveTransactiontran == null) {
            this.router.navigate(["/profile/subjects"]).then();
          } else {
            this.router.navigate(["/"]).then();
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  showPassword() {
    (document.getElementById("password") as HTMLInputElement).type = "text";
  }

  hidePassword() {
    (document.getElementById("password") as HTMLInputElement).type = "password";
  }
}
