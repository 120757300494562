import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from "@angular/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminAskLectorComponent } from '../admin-ask-lector-component/admin-ask-lector.component';

@Component({
  selector: "app-counter-items",
  templateUrl: "./counter-items.html",
  styleUrls: ["./counter-items.sass"],
})
export class CounterItemsComponent implements OnInit {
  @Input() counterItems: Array<any>;
  @Input() level:string;
  counterItemPlaceholders: Array<string>;
  @Output() changeValueLevel = new EventEmitter<string>();
  @Output() changeChoices = new EventEmitter<object>();
  askLecture:boolean;
  @Input()type:string;
  // todo
  // Add counter items logic.
    constructor(private modalService: NgbModal){

    }
  ngOnInit() {
    this.counterItemPlaceholders = ["صعب", "متوسط", "سهل"];
  }
  addNewChoice(){
    if(this.counterItems==null)this.counterItems=[];
    this.counterItems.push({
      text:'',
      value: 0
    }) 
  }
  updateRadioValue(event) {
    this.changeValueLevel.emit(event);
  }
  choicesChanged(){
  this.changeChoices.emit(this.counterItems);
  }
  removeChoice(itemId){
    this.counterItems.splice(itemId,1);
  }
  counterUp(itemID: number) {
    if(this.counterItems[itemID].value=="0"){
      this.counterItems[itemID].value="1";
    }
  }

  countDown(itemID: number) {
    if(this.counterItems[itemID].value=="1"){
      this.counterItems[itemID].value="0";
    }
  }
  addcollAnswers(){
    const askLecture = this.modalService.open(AdminAskLectorComponent, {
      windowClass: "forgot-password",
    });
    askLecture.result.then(async(result) => {
      this.addcollectionAnswer(result);
    },async (reason) => {
        this.addcollectionAnswer(reason);
    });
  }
  addcollectionAnswer(event){
    this.askLecture=false;
    if(event !=null && event !=""){
      let answers= event.split("\n");
      if(this.counterItems ==null) this.counterItems=[];
      for(let answer in answers){
        this.counterItems.push({
          text: answers[answer],
          value: 0
        }) 
      }
    }
  }
}
