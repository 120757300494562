import { TimerService } from "./modules/exams/exam/timer.service";
import { Component, HostListener, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ThrowStmt } from "@angular/compiler";
import { NavigationEnd, Router } from "@angular/router";
import { SocketService } from "@services/socket.service";

declare let ga: Function;
@Component({
  selector: "app-root",
  template: `
    <app-navbar (handleLocationChange)="onLocationChange($event)"></app-navbar>
    <div class="app-wrapper {{ specialClass }}">
      <router-outlet></router-outlet>
    </div>
    <app-footer
      *ngIf="
        !this.currentUrl?.includes('dashboard/admin') &&
        !this.currentUrl?.includes('profile/') &&
        !this.currentUrl?.includes('lections') &&
        !this.currentUrl?.includes('exam') &&
        !this.currentUrl?.includes('exam/lecture') &&
        !this.currentUrl?.includes('lection/watch') &&
        !this.currentUrl?.includes('payment-error')
      "
    ></app-footer>
  `,
})
export class AppComponent implements OnInit {
  constructor(
    translate: TranslateService,
    private timer: TimerService,
    private router: Router,
    private socketService: SocketService
  ) {
    translate.setDefaultLang("ar");
    this.footerVisible = false;

    this.socketService.connect();
  }
  currentUrl: string;
  footerVisible: boolean;
  activeLocation: string;
  specialClass: string;
  lecName: string;
  // Toggle footer visibility.
  @HostListener("window:scroll", ["$event"])
  onScroll() {
    if (this.activeLocation.indexOf("/analytics") > -1)
    {
      if (window.pageYOffset > 250)
      {
        this.footerVisible = true;
      } else
      {
        this.footerVisible = false;
      }
    } else
    {
      if (window.pageYOffset > 5580 && !this.footerVisible)
      {
        this.footerVisible = true;
      } else if (window.pageYOffset < 5000)
      {
        this.footerVisible = false;
      }
    }
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd))
      {
        return;
      } else
      {
        // if (ga)
        // {
        //   ga('set', 'page', evt.urlAfterRedirects);
        //   ga('send', 'pageview');
        // }

        this.currentUrl = evt.url;
      }
      window.scrollTo(0, 0);


    });
  }

  onLocationChange(newLocation: string) {
    this.activeLocation = newLocation;
    this.footerVisible = false;
    if (newLocation === "/auth")
    {
      this.specialClass = "app-wrapper--auth";
    } else if (newLocation === "/sign-up")
    {
      this.specialClass = "app-wrapper--sign-up";
    } else if (newLocation.indexOf("dashboard") > -1)
    {
      this.specialClass = "app-wrapper--dashboard";
    } else if (newLocation.indexOf("project") > -1)
    {
      this.specialClass = "app-wrapper--project";
    } else if (newLocation.indexOf("lections") > -1)
    {
      this.specialClass = "app-wrapper--lections";
    } else if (newLocation.indexOf("exam") > -1)
    {
      this.specialClass = "app-wrapper--lections";
    } else if (newLocation.indexOf("/lection/watch") > -1)
    {
      this.specialClass = "app-wrapper--lection";
    }
  }
}

/* ng analytics project on/off */
