<div class="admin-questions-bank">
  <div class="admin-questions-add-question">
    <div class="admin-questions-add-question-header">
      <p>{{selectedlecture.LectureName}}</p>
      <p>إضافة أسئلة</p>
    </div>
    <form class="admin-questions-add-question-form">
      <div class="admin-questions-add-question-form-footer-actions mb-3">
        <button class="btn-green btn-large no-border" (click)="saveQuestion(false)">
          حفظ
        </button>
        <button class="btn-light-gray btn-large no-border" (click)="annulateQuestion()">
          الغاء
        </button>
      </div>
      <div class="row justify-content-end">

        <button (click)="togglePhoto()" class="btn-green btn-large no-border mb-3 mr-3">
          {{isQuestionImage? 'كتابة السؤال' : 'تحميل صورة'}}
        </button>
      </div>

      <div class="admin-questions-add-question-form-content">
        <div class="form-field">
          <div class="form-field-remove-btn" (click)="removeQuestion()">
            <span>احذف السؤال</span>
            <button class="btn-icon btn-icon-remove icon-circle c-pointer"></button>
          </div>
          <label>السؤال</label>

          <ckeditor *ngIf="!isQuestionImage" [config]="config" [ngModelOptions]="{standalone: true }" [(ngModel)]="newQuestionBankItem.question">
          </ckeditor>



          <div *ngIf="isQuestionImage" class="form-group row justify-content-end">
            <div *ngIf="newQuestionBankItem && newQuestionBankItem.questionImage" class="col-5">
              <img [src]="newQuestionBankItem?.questionImage" class="question__image">
            </div>
            <div class="col-lg-3 kt-margin-bottom-20-mobile">
              <tk-upload [filetype]="'image'" class="mx-5" [value]="newQuestionBankItem.questionImage" (valueChange)="updateImage($event)">
              </tk-upload>
            </div>
          </div>




          <div *ngIf="isquestionRequired" class="form-control-feedback" style="color: red">
            السؤال مطلوب
          </div>

          <!-- <div class="row mt-3">
            <div class="col-11">
              <h3>السؤال</h3>
              <hr> -->
          <!-- <iframe (load)="iframeStyle()" id="questionIframe" [srcdoc]="newQuestionBankItem.question" height="auto" width="100%" frameBorder="0"
                      lang="ar" dir="rtl"></iframe> -->

          <!-- <ckeditor (click)="hideToolbar()" id="cke_editor23" readOnly="true" type="inline" [config]="config"
                        [data]="newQuestionBankItem.question">
              </ckeditor> -->

          <!-- </div> -->
          <!-- </div> -->

        </div>
        <div class="form-field">
          <div class="radio-buttons-group-items">
            <app-form-control-radio [name]="'choice'" [placeholder]="'اختيار من متعدد'" [ID]="'someChoice'" (changeValue)="updateRadioValue($event)"
                                    [value]="'اختيار من متعدد'" [model]="newQuestionBankItem.type">
            </app-form-control-radio>
            <app-form-control-radio [name]="'choice'" [placeholder]="'مقالى'" [ID]="'multipleChoice'" (changeValue)="updateRadioValue($event)"
                                    [value]="'مقالى'" [model]="newQuestionBankItem.type">
            </app-form-control-radio>
          </div>
        </div>
        <div class="form-field">
          <div class="radio-buttons-group-items">
            <app-form-control-radio [name]="'choiceCount'" [placeholder]="'تدريبى'" [ID]="'bothFromThem'" [value]="'تدريبى'"
                                    [model]="newQuestionBankItem.choiceCount" (changeValueChoiceCount)="updateRadioValueChoiceCount($event)">
            </app-form-control-radio>
            <app-form-control-radio [name]="'choiceCount'" [placeholder]="'عشوائى'" [ID]="'bothFromThem'" [value]="'عشوائى'"
                                    [model]="newQuestionBankItem.choiceCount" (changeValueChoiceCount)="updateRadioValueChoiceCount($event)">
            </app-form-control-radio>
            <app-form-control-radio [name]="'choiceCount'" [placeholder]="'كلاهما'" [ID]="'bothFromThem'" [value]="'كلاهما'"
                                    [model]="newQuestionBankItem.choiceCount" (changeValueChoiceCount)="updateRadioValueChoiceCount($event)">
            </app-form-control-radio>
          </div>
        </div>
        <app-counter-items [type]="newQuestionBankItem.type" [level]="newQuestionBankItem.level" [counterItems]="newQuestionBankItem.choices"
                           (changeChoices)="changeChoices($event)" (changeValueLevel)="updateRadioValueLevel($event)">
        </app-counter-items>
        <div class="form-field form-field-bordered">
          <label *ngIf="noChoices == true" class="error_msg">من فضلك أدخل الإختيارات</label>
          <label *ngIf="isRightChoicesProvided == true" class="error_msg">من فضلك اعط الإجابة الصحيحة قيمة = 1 </label>
          <label>الإجابة الصحيحة</label>
          <textarea [ngModelOptions]="{ standalone: true }" [(ngModel)]="newQuestionBankItem.rightAnswer" placeholder=""
                    style="direction: rtl;"></textarea>
          <label *ngIf="answerError == true" class="error_msg">من فضلك أدخل الإجابة الصحيحة</label>
        </div>
        <div class="row justify-content-center">
          <p class="col-lg-11" *ngIf="newQuestionBankItem.rightAnswer">
            {{ newQuestionBankItem.rightAnswer }}
          </p>
          <p class="col-lg-11" *ngIf=" !newQuestionBankItem.rightAnswer && getRightAnswerFromChoices() && getRightAnswerFromChoices().text">
            {{ getRightAnswerFromChoices().text }}
          </p>
        </div>
        <div class="form-field">
          <div style="direction: rtl;" class="form-field-add-item">
            <button class="icon-circle btn-icon-plus btn-icon-plus-dark"></button>
            <label>ارفاق ملفات</label>
            <input type="file" class="upload_file" (change)="onFileChange($event)">
            <div *ngIf="uploadFileError" class="form-control-feedback" style="color: red">
              حدث خطأ فى تحميل الملف
            </div>
          </div>
          <div class="form-field form-field-doubled">
            <div *ngIf="newQuestionBankItem.file !=null" class="form-field-doubled-item">
              <input type="text" [placeholder]="newQuestionBankItem.file.originalFilename" />
              <button (click)="removeFile()" class="btn-icon btn-icon-remove icon-circle c-pointer"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="admin-questions-add-question-form-footer">
        <div class="admin-questions-add-question-form-footer-actions">
          <button class="btn-green btn-large no-border" (click)="saveQuestion(false)">
            حفظ
          </button>
          <button class="btn-light-gray btn-large no-border" (click)="annulateQuestion()">
            الغاء
          </button>
        </div>
        <div class="admin-questions-add-question-form-footer-pagination">
          <li (click)="countDown()" class="pagination-control-left pagination-control pointer">
            <div class="left-control-icon icon-100">
              <div class="arrow-icon icon-100"></div>
            </div>
          </li>
          <ul class="pagination-content row">
            <li (click)="goToQuestion(ID)" *ngFor="let paginationPageCount of Questions; let ID = index;"
                [ngClass]="{'active': ID === activePaginationItem }" class="pagination-count-item cursor_pointer">
              <span>{{ID + 1}}</span>
            </li>
          </ul>
          <li (click)="countUp()" class="pagination-control-right pagination-control pointer">
            <div class="right-control-icon icon-100">
              <div class="arrow-icon icon-100"></div>
            </div>
          </li>
          <button class="btn-green btn-large no-border" (click)="updatePage()">
            اضافة سؤال اخر
          </button>
        </div>
      </div>
    </form>
  </div>
</div>