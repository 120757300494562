import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { NgModel } from '@angular/forms';
import { AdminContinueDeleteComponent } from '@modules/admin/components/admin-continue-delete-component/admin-continue-deletecomponent';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SummariesService } from '@services/apis/summaries.service';

@Component({
  selector: "app-range-controls",
  templateUrl: "./range-controls.html",
  styleUrls: ["./range-controls.sass"],
})
export class RangeControlsComponent implements OnInit {
  rangeCount: number;
  @Output() updateRangeCount: EventEmitter<any> = new EventEmitter<any>();
  @Input() initialCount: number;
  @Input() rowId:string;
  @Input() row:any;
  constructor(private SummariesApi:SummariesService,
             private modalService:NgbModal){

  }
  ngOnInit() {
    this.rangeCount = this.initialCount || 0;
  }

  plus() {
    this.SummariesApi.save({}).subscribe((res)=>{
      this.rangeCount += 1;     
      this.updateRangeCount.emit({count:this.rangeCount,rowId:this.rowId});
    })
  }

  // todo
  // Fix logic.
  minus() {
    const ask = this.modalService.open(AdminContinueDeleteComponent, {
      windowClass: "forgot-password",
    });
    ask.result.then(async(result) => {
      if(result){
        this.SummariesApi.destroy(this.rowId).subscribe((res)=>{
          this.rangeCount -= 1;
          this.updateRangeCount.emit({count:this.rangeCount,rowId:this.rowId});
        });
       }
    },async (result) => {
       if(result){
        this.SummariesApi.destroy(this.rowId).subscribe((res)=>{
          this.rangeCount -= 1;
          this.updateRangeCount.emit({count:this.rangeCount,rowId:this.rowId});
        });
       }
    });
    ask.componentInstance.messageConfirm="هل انت متاكد انك تريد حذف"+this.row.originalFilename+"";
  }
}
