import { LoadingComponent } from './../../components/loading/loading';
import { NewLandingPage } from "./components/new-landing-page/new-landing-page";
import { AdminProfileComponent } from "./components/admin-profile/admin-profile";
import { PromiseComponent } from "./components/home-analytics-component/promise/promise";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClientModule, HttpClient } from "@angular/common/http";

import { SharedModule } from "@modules/shared/shared.module";
import { HomeRoutingModule } from "@routing/home-routing.module";
import { HomeComponent } from "./components/home-component/home.component";
import { AppHomeAnalyticsRootComponent } from "./components/home-analytics-root-component/home-analytics-root.component";
import { SubAndPaymentComponent } from "./components/home-analytics-component/home-analytics.component";
import { ProfileComponent } from "./components/home-analytics-profile-component/home-analytics-profile.component";
import { SubjectsComponent } from "./components/home-analytics-sub-and-payment-component/home-analytics-sub-and-payment.component";
import { LandingComponent } from "./components/landing-component/landing.component";
import { FormsModule } from "@angular/forms";
import { VimeModule } from "@vime/angular/dist";

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    HomeComponent,
    LandingComponent,
    AppHomeAnalyticsRootComponent,
    SubAndPaymentComponent,
    ProfileComponent,
    SubjectsComponent,
    PromiseComponent,
    AdminProfileComponent,
    NewLandingPage,
    LoadingComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HomeRoutingModule,
    SharedModule,
    FormsModule,
    VimeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  entryComponents: [PromiseComponent],
})
export class HomeModule {}
