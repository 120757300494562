import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap, shareReplay } from "rxjs/operators";
import { environment } from "../../../environments/environment";

import { BaseApiService } from "../baseAPI";
import { User } from "@modules/_models/User";
// import { SocialUser } from "angularx-social-login";

const API_USERS_URL = environment.baseUrl;

const cache = new Map();
@Injectable()
export class ExamsService extends BaseApiService<any> {
  constructor(http: HttpClient) {
    super(http);
    this.url = "/userExams";
  }

  getQuestions(examId: string) {
    return this.http.get<any>(
      API_USERS_URL + `${this.url}/questions?examId=${examId}`,
      {
        headers: this.authorization(),
      })
  }
  creatExamFromLectureId(lectureId: string, createNew = false) {
    return this.http.post<any>(
      API_USERS_URL + `${this.url}/create-exam`,
      {
        lectureId: lectureId,
        createNew: createNew,
      },
      {
        headers: this.authorization(),
      }
    );
  }

  createRandomExam(exam: any) {
    let userId = exam.userId;
    let lectures = exam.lectures;
    let multipleChoices = exam.multipleChoices;
    let articleQuestions = exam.articleQuestions;
    let time = exam.time;
    let isCompleted = exam.isCompleted;
    let questionNumbers = exam.questionNumbers;

    return this.http.post<any>(
      API_USERS_URL + `${this.url}/create-exam`,
      {
        lectures: lectures,
        questionNumbers: questionNumbers,
        multipleChoices: multipleChoices,
        articleQuestions: articleQuestions,
        time: time,
      },
      {
        headers: this.authorization(),
      }
    );
  }
}
