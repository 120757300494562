<div style="padding: 20px;">
	<p *ngFor="let alert of alerts.values |async">
		<!-- <ngb-alert [type]="'danger'" (close)="closeAlert(alert)">{{ alert.message }}</ngb-alert> -->
	</p>

	<div class="card card-custom">
		<!--begin::Form-->
		<form class="form">
			<div class="card-body">
				<div class="form-group">
					<label>{{title}} <span class="acceptedTypes">({{acceptedTypes[filetype]}})</span></label>
					<div></div>
					<div class="row">
						<div class="col-12 custom-file">
							<div class="video-uploader-pane">
								<span>{{title}}</span>
							</div>
							<input [accept]="acceptedTypes[filetype]" multiple="false" type="file" class="custom-file-input" id="file"
								   (change)='onFileUploadchange($event)' />

							<label class="custom-file-label" for="customFile">Choose file</label>
						</div>
						<div class="col-12">
							<mat-spinner *ngIf='(loading$.value|async)' class="spinner" [diameter]="25"></mat-spinner>
							<span>{{progress.value|async}}</span>
						</div>
					</div>
				</div>
			</div>
		</form>
		<!--end::Form-->
	</div>
</div>