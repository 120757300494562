import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@services/apis/authentication.service';

@Component({
  selector: "app-admin-continue-block",
  templateUrl: "./admin-continue-block.html",
  styleUrls: ["./admin-continue-block.sass"],
})
export class AdminContinueBlockComponent implements OnInit {
  dialogOpened: boolean;
  plans:any=[];
  @Input() userBlock: any={};
  isSubPeriod: boolean;
  constructor(private authenticationApi: AuthenticationService,
              private modalService: NgbModal){
    
  }
  confirmblockUser() {
    let blocked = false;
    if (this.userBlock.redText == "حظر") {
      blocked = true;
    }
    this.authenticationApi.blocked(blocked, this.userBlock.id).subscribe(() => {
      //this.blockedChanged.emit();
      //this.blockPopupOpened = false;
      this.modalService.dismissAll();
    });
  }
  close() {
    this.modalService.dismissAll();
  }
 async ngOnInit() {

  }
}
