<div class="admin-students-manage-page">
  <div class="admin-students-manage" *ngIf="!addNewStudentTurnedOn">
    <button class="btn btn-green btn-large no-border" (click)="addStudent()">
      إضافة طالب
    </button>
    <div class="admin-students-manage-search-for-user">
      <input [(ngModel)]="searchText" (change)="onSearch()" type="text" id="is-search-for-user"
        class="form-control-search" placeholder="أبحث عن مستخدم" />
    </div>
    <div class="admin-students-manage-students-data">
      <div class="admin-students-manage-students-data-header">
        <div class="text-left">
          <span class="text-blue pointer" (click)="exportExcel()">تحميل البيانات كملف اكسل</span>
          <span>{{students!=null? students.length:'0'}}</span>
          <span>العدد الكلي</span>
        </div>
        <div class="text-right">
          <span>بيانات الطلاب</span>
        </div>
      </div>
      <div #TABLE class="admin-students-manage-students-data-body">
        <app-table-with-pagination [tableHeadingItems]="studentsHeadingData" [tableBodyItems]="studentsData"
          (blockedChanged)="blockedChanged()" [maxItemsCountPerPage]="5"></app-table-with-pagination>
      </div>
    </div>
  </div>
  <div *ngIf="addNewStudentTurnedOn" class="add-new-student">
    <form (ngSubmit)="f.form.valid && saveNewStudent()" #f="ngForm" class="add-new-student-form">
      <div class="form-field">
        <label>اسم المستخدم</label>
        <div style="flex-direction: row">
          <button class="btn-icon btn-edit-icon"></button>
          <input type="text" class="form-control" [(ngModel)]="student.name" #Name="ngModel" required
            [ngModelOptions]="{ standalone: true }" />
          <div class="row">
            <div class="col-lg-12 feedback_height">
              <div class="row justify-content-end">
                <div *ngIf="
            (f.submitted || Name.touched) &&
            Name.hasError('required')
          " class="form-control-feedback">
                  الاسم مطلوب
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-field">
        <label>ايميل الطالب</label>
        <div style="flex-direction: row">
          <button class="btn-icon btn-edit-icon index"></button>
          <input type="text" class="form-control col-md-12" [(ngModel)]="student.email" #email="ngModel" required
            [ngModelOptions]="{ standalone: true }" />
          <div class="row">
            <div class="col-lg-12 feedback_height">
              <div class="row justify-content-end">
                <div *ngIf="(f.submitted || email.touched) && email.hasError('required')"
                  class="form-control-feedback col-md-12">
                  الأيميل مطلوب
                </div>
                <div *ngIf="(f.submitted || email.touched) && email.hasError('pattern')"
                  class="form-control-feedback col-md-12">
                  الأيميل غير صحيح
                </div>
                <div *ngIf="emailExists" class="form-control-feedback col-md-12">
                  هذاالأيميل لديه حساب أخر بالفعل
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-field">
        <label>رقم الموبايل</label>
        <div style="flex-direction: row">
          <button class="btn-icon btn-edit-icon"></button>
          <input type="text" class="form-control" [(ngModel)]="student.phone" [ngModelOptions]="{ standalone: true }" />
          <div class="row">
            <div class="col-lg-12 feedback_height">
              <div class="row justify-content-end">
                <div *ngIf="f.submitted && !student.phone" class="form-control-feedback">
                  رقم الهاتف مطلوب
                </div>
                <div *ngIf="f.submitted && invalidMobile && student.phone" class="form-control-feedback">
                  رقم التليفون غير صحيح
                </div>
                <div *ngIf="f.submitted && phoneExist" class="form-control-feedback">
                  هذا الجوال لديه حساب أخر بالفعل
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-field form-field-radio-buttons">
        <label>نوع الاشتراك</label>
        <div class="radio-buttons-group-items">


          <div class="div-radio" *ngFor="let plan of plans">
            <div>
              <label class="margin-label">الخطة {{plan.name}}</label>
              <input type="radio" [value]="plan.id" name="intensePlan" [(ngModel)]="student.plan" />
            </div>
          </div>


        </div>
      </div>
      <div class="form-field">
        <label>عدد أيام الاشتراك</label>
        <div>
          <button class="btn-icon btn-edit-icon"></button>
          <input type="number" class="form-control" [(ngModel)]="student.subPeriod"
            [ngModelOptions]="{ standalone: true }" />
        </div>
      </div>
      <div class="form-field">
        <label>ادخال كلمة المرور</label>
        <div>
          <button class="btn-icon btn-edit-icon"></button>
          <input type="password" class="form-control" #pass="ngModel" required [(ngModel)]="student.password"
            [ngModelOptions]="{ standalone: true }" />
          <div class="row">
            <div class="col-lg-12 feedback_height">
              <div class="row justify-content-end">
                <div *ngIf="(f.submitted || pass.touched) && pass.hasError('required')" class="form-control-feedback">
                  كلمه المرور مطلوبه
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-field-submit form-field">
        <button type="submit" class="btn btn-green submit-btn no-border">
          اضافة طالب
        </button>
      </div>
    </form>
  </div>
</div>