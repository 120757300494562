import { UploadService } from "./../../../../services/apis/upload.service";
import { AsyncObject } from "./../../asyncObject";
import { AsyncArray } from "./../../AsyncArray";
// Angular
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  NgZone,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { catchError, finalize } from "rxjs/operators";
import { of } from "rxjs/internal/observable/of";

import { FormGroup } from "@angular/forms";
import { difference } from "lodash";

@Component({
  selector: "tk-gallery-list",
  templateUrl: "gallery-list.component.html",
  styleUrls: ["gallery-list.component.scss"],
})
export class GalleryListComponent implements OnInit, OnChanges {
  constructor(
    private api: UploadService,

    private translate: TranslateService
  ) {
    this.images = new AsyncArray();
    this.loading$ = new AsyncObject(false);
    this.progress = new AsyncObject(null);
    this.images.values.subscribe((x) => {
      this.value = x;
      this.valueChange.emit(x);
      // console.log('sync value', x, this.value);
    });
  }

  @Input() value: any[];
  @Output() valueChange = new EventEmitter<any[]>();
  @Input() form: FormGroup;
  @Input() filetype: string; // [image ,file, misc]
  @Input() title: string;

  messages: any;
  public alerts: AsyncArray;

  fileImage = "/assets/images/logo.png";
  acceptedTypes = {
     image: ".png,.jpg,.jpeg",
     file: ".pdf",
     misc: ".mp4,.mov,.mp3",
    //misc: ".mp4",
  };
  translationLabels: string[];
  progress: AsyncObject<number>;
  loading$: AsyncObject<boolean>;
  images: AsyncArray;
  selected = [];
  selectedCount = 0;

  deleteDialogLabls = {
    title: "GALLARY.LIST.IMAGE_DELETE",
    description: "GALLARY.LIST.CONFIRM_IMAGE_DELETE",
    waitDescription: "GALLARY.LIST.WAIT_IMAGEDELETE",
    deleteMessage: "GALLARY.LIST.DELETED_IMAGE_MESSAGE",
  };

  ngOnChanges() {
    // console.log('set...', this.value)
    this.images.set(this.value);
  }

  async ngOnInit() {
    this.alerts = new AsyncArray();
    this.filetype = this.filetype || "image";
    this.translationLabels = [
      this.deleteDialogLabls.title,
      this.deleteDialogLabls.description,
      this.deleteDialogLabls.waitDescription,
      this.deleteDialogLabls.deleteMessage,
    ];

    this.loadTranslation();
  }

  startUpload() {
    if (this.form) {
      this.form.disable();
    }
    this.loading$.next(true);
  }
  endUpload() {
    if (this.form) {
      this.form.enable();
    }
    this.loading$.next(false);
    console.log("end upload");
    this.progress.next(null);
  }
  getFiles(e) {
    const fileList: FileList = e.target.files;
    const files = [];
    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList.item(i));
    }
    return files;
  }
  onFileUploadchange(event) {
    const files = this.getFiles(event);
    this.startUpload();
    this.api
      .upload(files)
      .pipe(
        catchError(() => {
          this.alerts.push({ message: "file upload faild" });
          return of(null);
        }),
        finalize(() => this.endUpload())
      )
      .subscribe((response) => {
        console.log(response);
        if (response.type === "result") {
          const newFiles = response.files.map((x) => ({
            path: x["path"],
            fileName: x["originalFilename"],
            type: x["type"],
            extension: x["extension"],
          }));
          console.log("files to be added ", newFiles);
          this.images.add(newFiles);
          this.valueChange.emit(this.value);
        } else {
          this.progress.next(response.percentDone);
        }
      });
  }

  private loadTranslation() {
    this.translate.get(this.translationLabels).subscribe((res: any) => {
      this.messages = res;
    });
    this.translate.onLangChange.subscribe(() => {
      this.loadTranslation();
    });
  }

  public removeRow(image) {
    this.images.remove(image);
    // await this.api.destroy(image['id']).toPromise();
  }

  public closeAlert(alert) {
    this.alerts.remove(alert);
  }
  onLoad($event, image) {
    console.log($event);
  }

  calcSelected() {
    this.selectedCount = this.selected.filter((x) => x === true).length;
  }
  removeSelected() {
    const toBeDeleted = [];
    for (let i = 0; i < this.selected.length; i++) {
      const el = this.selected[i];
      if (el === true) {
        toBeDeleted.push(this.value[i]);
      }
    }

    this.selected = [];
    this.selectedCount = 0;
    this.images.set(difference(this.value, toBeDeleted));
  }
}