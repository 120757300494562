import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import registerFellowshipItems from "@modules/auth/components/register-component/constants/register-fellowship.items";
import { PlansService } from "@services/apis/plans.service";

@Component({
  selector: "app-fellowship-table",
  // templateUrl: "./fellowship-table.html",
  templateUrl: "./new-fellowship-table.html",
  styleUrls: ["./new-fellowship-table.scss"],
})
export class FellowshipTableComponent implements OnInit {
  items: Array<any>;
  @Input() cellItems: Array<any>;
  @Input() withResolveActionOnTable: boolean;
  @Input() isHome: boolean;
  @Output() pressResolveAction: EventEmitter<any> = new EventEmitter<any>();
  participation: any;
  isParticipation: boolean;
  itemsResult: any = [];
  headerTable = {
    title: "هناك عدد من الخطط للاشتراك في موقع زمالتي",
    items: [
      {
        type: "text",
        text: "محاضرات مسجلة",
      },
      {
        type: "text",
        text: "اختبارات مقالي",
      },
      {
        type: "text",
        text: "اختبارات متعددة",
      },
      {
        type: "text",
        text: "رسوم الأشتراك",
      },
      {
        type: "text",
        text: "مدة الأشتراك",
      },
    ],
  };

  constructor(private participationsApi: PlansService, private router: Router) {
    this.isParticipation = true;
  }
  async ngOnInit() {
    const participationItems: any = await this.participationsApi
      .query({})
      .toPromise();
    this.itemsResult = participationItems.result;
    this.items = [];
    for (let i = 0; i < this.itemsResult.length; i++) {
      this.items.push({
        id: this.itemsResult[i].id,
        title: this.itemsResult[i].name,
        dolarPrice: this.itemsResult[i].DolarPrice,
        allowExam: this.itemsResult[i].allowExam,
        allowLecture: this.itemsResult[i].allowLecture,
        items: [
          {
            type: "icon",
            iconSrc: this.itemsResult[i].allowLecture
              ? "assets/icons/done-icon.png"
              : "assets/icons/close-icon.png",
          },
          {
            type: "icon",
            iconSrc: this.itemsResult[i].allowExam
              ? "assets/icons/done-icon.png"
              : "assets/icons/close-icon.png",
          },
          {
            type: "icon",
            iconSrc: this.itemsResult[i].allowExam
              ? "assets/icons/done-icon.png"
              : "assets/icons/close-icon.png",
          },
          {
            type: "price",
            text: this.itemsResult[i].price,
          },
          {
            type: "duration",
            text: this.itemsResult[i].duration,
          },
          {
            type: "text-checkbox",
            text: "أشتراك",
            id: "participation-1",
            isChecked: false,
          },
        ],
      });
    }
  }
  checkValue(item, id) {
    this.participation = null;
    if (
      !this.isHome &&
      (item == null ||
        !item.items.find((i) => i.id == "participation-1").isChecked)
    ) {
      return null;
    }
    if (item != null) {
      console.log(item, id);
      this.isParticipation = true;
      this.participation = { ...item.items[4] };
      // this.participation.text = this.participation.text.replace(/\D/g, "");
      this.participation.text = { ...item.items[3] }.text;
      this.participation.id = id;
      this.participation.dolarPrice = item.dolarPrice;
    }
    if (!this.isHome) {
      for (let i = 0; i < this.items.length; i++) {
        let indexId = this.items[i].items.findIndex(
          (i) => i.id == "participation-1"
        );
        if (this.items[i].id != id) {
          this.items[i].items[indexId].isChecked = false;
        }
      }
    } else {
      this.router.navigate(["/sign-up"]).then();
    }
  }
  resolveAction() {
    if (this.participation != null) {
      this.pressResolveAction.emit(this.participation);
    } else {
      this.isParticipation = false;
    }
  }

  isPlanSelected(plan) {
    const check = plan.items.filter((x) => x.type === "text-checkbox");
    // console.log(plan);
    if (check[0].isChecked === true) return true;
    else return false;
  }
}
