import { Injectable } from "@angular/core";
import { BaseApiService } from "../baseAPI";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { User } from "@modules/_models/User";
import { environment } from "../../../environments/environment";
import { map, tap } from "rxjs/operators";

const API_USERS_URL = environment.baseUrl;
const API_TOKEN = "currentUser";

@Injectable()
export class AuthenticationService extends BaseApiService<User> {
  constructor(http: HttpClient) {
    super(http);
    this.url = "/users";
  }

  login(email: string, password: string, captchaResponse?: string) {
    const user = new User();
    user.email = email;
    user.password = password;
    return this.http.post<User>(API_USERS_URL + `${this.url}/login`, user);
  }

  forgotPassword(email: any) {
    return this.http.post(
      this.baseUrl + this.url + "/email/forget-password",
      email,
      { headers: this.authorization() }
    );
  }
  resendEmailCode(email: any) {
    return this.http
      .post(API_USERS_URL + this.url + `/email/resend?email=${email}`, null)
      .pipe();
  }

  get(id: string) {
    return this.http.get<User>(API_USERS_URL + this.url + `/${id}`, {
      headers: this.authorization(),
    });
  }

  logout(): Observable<any> {
    const userTokenStr = localStorage.getItem(API_TOKEN);
    const httpHeaders = new HttpHeaders();
    const userToken = JSON.parse(userTokenStr);
    localStorage.removeItem(API_TOKEN);
    httpHeaders.set("Authorization", "Bearer " + userToken.id);
    return this.http.post<User>(
      `${API_USERS_URL}${this.url}/logout?access_token=${userToken.id}`,
      { headers: httpHeaders }
    );
  }
  confirmEmail(emailCode, email): Observable<any> {
    return this.http
      .get<User>(
        API_USERS_URL +
        this.url +
        `/email/confirm?emailCode=${emailCode}&email=${email}`
      )
      .pipe(
        map((data) => {
          if (data && data.id) {
            localStorage.removeItem("currentUser");
            localStorage.setItem("currentUser", JSON.stringify(data));
          }
          return data;
        })
      );
  }

  resetPassword(vCode, password): Observable<any> {
    console.log(vCode);
    return this.http
      .post(
        API_USERS_URL +
        this.url +
        `/email/set-password?vcode=${vCode}&password=${password}`,
        null
      )
      .pipe();
  }

  completeRegistration(userId): Observable<any> {
    console.log(this.authorization());
    return this.http
      .post(
        API_USERS_URL + this.url + `/registrationCompleted?userId=${userId}`,
        null,
        { headers: this.authorization() }
      )
      .pipe();
  }
  changePassword(data): Observable<any> {
    return this.http.post(
      API_USERS_URL + this.url + "/changePassword",
      { data: data },
      { headers: this.authorization() }
    );
  }
  resendCode(userId: any) {
    return this.http.post(
      this.baseUrl + this.url + "/phone/resend",
      { userId: userId },
      { headers: this.authorization() }
    );
  }
  verifyphone(passcode, mobile) {
    return this.http.post(
      this.baseUrl + this.url + "/phone/confirm",
      { passcode: passcode, mobile: mobile },
      { headers: this.authorization() }
    );
  }
  blocked(data: boolean, userId): Observable<User> {
    return this.http.post<User>(
      API_USERS_URL + `${this.url}/blocked`,
      { data: data, userId: userId },
      { headers: this.authorization() }
    );
  }
  isAllowExam() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      if (
        currentUser.promise &&
        currentUser.promise == true &&
        currentUser.subscribedAndPaid &&
        currentUser.subscribedAndPaid == true &&
        currentUser.allowExam &&
        currentUser.allowExam == true
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  loginAsStudent(userId: string) {
    return this.http.post<any>(`${this.baseUrl}${this.url}/login-as-student`,
      { userId },
      { headers: this.authorization() }
    )
  }
}
