<div class="admin-questions-bank">
  <div class="admin-questions">
    <div class="admin-questions-card" *ngFor="let questionBankItem of Lectures; let ID = index"
      (mousedown)="addNewQuestion(ID)">
      <div class="admin-questions-card-info">
        <div>
          <span class="pointer">تعديل</span>
        </div>
        <div>
          <span>سؤالا {{getLectures(questionBankItem.id)}}</span>
          <span>{{questionBankItem.LectureName}}</span>
          <span>{{ID + 1}}</span>
        </div>
      </div>
    </div>
  </div>
</div>