<div class="admin-main-control">
  <form (ngSubmit)="f.form.valid && savesettingSocial()" #f="ngForm" class="add-new-student-form">
    <div class="form-field">
      <label>رقم التواصل</label>
      <div>
        <span class="btn-icon btn-edit-icon"></span>
        <input class="form-control" type="text" [(ngModel)]="settingSocial.phone"
          [ngModelOptions]="{ standalone: true }" />
        <div *ngIf="invalidMobile" class="form-control-feedback" style="color: red">
          رقم التليفون غير صحيح
        </div>
      </div>
    </div>
    <div class="form-field">
      <label>ايميل التواصل</label>
      <div>
        <span class="btn-icon btn-edit-icon"></span>
        <input class="form-control" type="text" [(ngModel)]="settingSocial.email"
          [ngModelOptions]="{ standalone: true }" />
      </div>
    </div>
    <div class="form-field">
      <label>حاسبات التواصل</label>
      <div>
        <span class="btn-icon btn-edit-icon"></span>
        <input class="form-control" type="text" placeholder="الفيس بوك" [(ngModel)]="settingSocial.facebookLink"
          [ngModelOptions]="{ standalone: true }" />
      </div>
    </div>
    <div class="form-field">
      <label>حاسبات التواصل</label>
      <div>
        <span class="btn-icon btn-edit-icon"></span>
        <input class="form-control" type="text" placeholder="تويتر" [(ngModel)]="settingSocial.twitterLink"
          [ngModelOptions]="{ standalone: true }" />
      </div>
    </div>
    <div class="form-field">
      <label>حاسبات التواصل</label>
      <div>
        <span class="btn-icon btn-edit-icon"></span>
        <input class="form-control" type="text" placeholder="انستيجرام" [(ngModel)]="settingSocial.instagramLink"
          [ngModelOptions]="{ standalone: true }" />
      </div>
    </div>
    <div class="form-field">
      <label> العنوان</label>
      <div>
        <span class="btn-icon btn-edit-icon"></span>
        <input class="form-control" type="text" [(ngModel)]="settingSocial.address"
          [ngModelOptions]="{ standalone: true }" />
      </div>
    </div>
    <div class="form-field form-field-submit">
      <button class="btn btn-green submit-btn no-border">حفظ</button>
    </div>
  </form>
  <div class="admin-main-control-sub">
    <div class="admin-main-control-sub-details">
      <span>انشاء اعلان</span>
      <span>التنسيق</span>
    </div>
    <div class="admin-main-control-sub-font-settings">
      <div class="admin-main-control-sub-font-settings-color-select">
        <div class="pallet-color-select">
          <input class="pallet-color-select-active-color" type="color" name="fontcolor" [(ngModel)]="font.fontColor"
            (change)="preview()" />
          <div class="edit-material-icon icon-100"></div>
        </div>
        <span>لون الخط</span>
      </div>
      <div>
        <input type="number" (change)="preview()" [(ngModel)]="font.fontSize" class="input-control" />
        <span>الحجم</span>
      </div>
      <div>
        <select [(ngModel)]="font.fontWeight" id="input-font" class="input-control" (change)="preview()">
          <option value="normal" selected="selected">normal</option>
          <option value="bold">bold</option>
          <option value="bolder">bolder</option>
          <option value="lighter">lighter</option>
          <option value="100">100</option>
          <option value="200">200</option>
          <option value="300">300</option>
          <option value="400">400</option>
          <option value="500">500</option>
          <option value="600">600</option>
          <option value="700">700</option>
          <option value="800">800</option>
          <option value="900">900</option>
          <option value="inherit">inherit</option>
          <option value="initial">initial</option>
          <option value="unset">unset</option>
        </select>
        <span>النوع</span>
      </div>
      <div>
        <select [(ngModel)]="font.fontFamily" id="input-font" class="input-control" (change)="preview()">
          <option value="Times New Roman" selected="selected">
            Times New Roman
          </option>
          <option value="Times">Times</option>
          <option value="Courier New">Courier New</option>
          <option value="Arial">Arial</option>
          <option value="fantasy">Fantasy</option>
          <option value="Courier">cursive</option>
          <option value="Verdana">Verdana</option>
          <option value="Georgia">Georgia</option>
          <option value="Palatino">Palatino</option>
          <option value="Garamond">Garamond</option>
          <option value="Bookman">Bookman</option>
        </select>
        <span>اسم الخط</span>
      </div>
      <div class="admin-main-control-sub-font-settings-color-select">
        <div class="pallet-color-select">
          <input class="pallet-color-select-active-color" type="color" name="backgroundcolor"
            [(ngModel)]="font.backgroundColor" (change)="preview()" />
          <div class="edit-material-icon icon-100"></div>
        </div>
        <span>لون الخلفية</span>
      </div>
    </div>
    <textarea style="
        direction: RTL;
        background-color: white;
        color: black;
        width: 100%;
        overflow-y: hidden;
      " class="admin-main-control-sub-discount-info" [(ngModel)]="setting.advertisingTitle"
      [ngModelOptions]="{ standalone: true }" (change)="preview()">
    </textarea>
    <div [ngStyle]="fontStyle" (input)="font.advertisingTitle=$event.target.textContent"
      class="admin-main-control-sub-discount-info">
      <p [ngStyle]="fontStyle">{{font.advertisingTitle}}</p>
    </div>

    <div class="admin-main-control-sub-footer">
      <button class="btn-green btn-large no-border" (click)="savefont()">
        حفظ
      </button>
    </div>
  </div>
  <div class="admin-main-control-videos">
    <div class="admin-main-control-videos-cards">
      <div class="admin-main-control-videos-cards-card">
        <div class="form-field">
          <label>اسم الفيديو</label>
          <input class="form-control" type="text" placeholder="اسم فيديو" [(ngModel)]="setting.videoName1"
            [ngModelOptions]="{ standalone: true }" />
        </div>
        <div class="admin-main-control-videos-cards-card-body">
          <!-- <video controls style="width: 300px; height: 250px" [src]="setting.videoLink1"  crossOrigin="anonymous" (click)="toggleVideo()" #videoPlayer>
            Browser not supported
          </video> -->
          <div style="width: 300px; height: 250px;margin-left: 500px;">
          <vime-player  controls >
            <vime-youtube  [videoId]="youtubeLinkToId(setting?.videoLink1)"></vime-youtube>
            <vime-video *ngIf="!youtubeLinkToId(setting?.videoLink1)">
              <source [src]="setting.videoLink1" />
            </vime-video>
          </vime-player>
        </div>
        </div>
        <div class="admin-main-control-videos-cards-card-footer">
          <button (click)="changeVideoType('link')" class="btn btn-large btn-light-gray no-border"
            [ngClass]="{'btn-white':videoLink == false}">
            إضافة لينك للفيديو
          </button>
          <button (click)="changeVideoType('upload')" class="btn btn-large btn-light-gray no-border"
            [ngClass]="{'btn-white':videoLink == true}">أرفاق فيديو</button>
        </div>


        <div class="form-field" *ngIf="videoLink == true">
          <label>ادخل رابط الفيديو</label>
          <input class="form-control" type="text" placeholder="  رابط الفيديو" [(ngModel)]="setting.videoLink1"
            [ngModelOptions]="{ standalone: true }" />
        </div>

        <div class="form-field" *ngIf="videoLink == false">
          <tk-upload [(value)]="setting.videoLink1" [title]="'أرفاق فيديو'" [filetype]="'mise'"></tk-upload>
        </div>
      </div>
      <div class="admin-main-control-videos-cards-card">
        <div class="form-field">
          <label>اسم الفيديو</label>
          <input class="form-control" type="text" placeholder="اسم فيديو" [(ngModel)]="setting.videoName2"
            [ngModelOptions]="{ standalone: true }" />
        </div>
        <div class="admin-main-control-videos-cards-card-body">
          <!-- <video controls style="width: 300px; height: 250px" (click)="toggleVideo()" #videoPlayer>
            <source [src]="setting.videoLink2" type="video/mp4" />
            Browser not supported
          </video> -->
          <div style="width: 300px; height: 250px;margin-left: 500px;">
            <vime-player  controls >
              <vime-youtube  [videoId]="youtubeLinkToId(setting?.videoLink2)"></vime-youtube>
              <vime-video *ngIf="!youtubeLinkToId(setting?.videoLink2)">
                <source [src]="setting.videoLink2" />
              </vime-video>
            </vime-player>
          </div>
        </div>
        <div class="admin-main-control-videos-cards-card-footer">
          <button (click)="changeVideoType2('link')" class="btn btn-large btn-light-gray no-border"
            [ngClass]="{'btn-white':videoLink == false}">
            إضافة لينك للفيديو
          </button>
          <button (click)="changeVideoType2('upload')" class="btn btn-large btn-light-gray no-border"
            [ngClass]="{'btn-white':videoLink == true}">أرفاق فيديو</button>
        </div>

        <div class="form-field" *ngIf="videoLink2 == true">
          <label>ادخل رابط الفيديو</label>
          <input class="form-control" type="text" placeholder="  رابط الفيديو" [(ngModel)]="setting.videoLink2"
            [ngModelOptions]="{ standalone: true }" />
        </div>

        <div class="form-field" *ngIf="videoLink2 == false">
          <tk-upload [(value)]="setting.videoLink2" [title]="'أرفاق فيديو'" [filetype]="'mise'"></tk-upload>
        </div>

      </div>
    </div>
    <div class="admin-main-control-videos-actions">
      <button class="btn btn-large btn-green no-border">معاينة</button>
      <button (click)="saveVideos()" class="btn btn-large btn-green no-border">حفظ</button>
    </div>
  </div>
  <div class="admin-main-control-specialists">
    <p class="title-text">المختصين</p>
    <form class="admin-main-control-specialists-edit-form">
      <div class="form-field">
        <label>اسم المحاضر</label>
        <div>
          <span class="btn-icon btn-edit-icon"></span>
          <input class="form-control" type="text" [(ngModel)]="settingLecture.lecturerName1"
            [ngModelOptions]="{ standalone: true }" />
        </div>
      </div>
      <div class="form-field">
        <label>عنوان رئيسي</label>
        <div>
          <span class="btn-icon btn-edit-icon"></span>
          <input class="form-control" type="text" [(ngModel)]="settingLecture.lecturerAddress1"
            [ngModelOptions]="{ standalone: true }" />
        </div>
      </div>
      <div class="form-field">
        <label>نبذة مختصرة</label>
        <div>
          <span class="btn-icon btn-edit-icon"></span>
          <input class="form-control" type="text" [(ngModel)]="settingLecture.lecturerDescription1"
            [ngModelOptions]="{ standalone: true }" />
        </div>
      </div>
      <app-form-control-file [withAddIcon]="true" [isError]="lecturerAvatar1Error" [label]="lectureControlLabel"
        [placeholder]="settingLecture.lecturerAvatarName1" [error]="lectureControlErrorText"
        (valueChange)="onFileChange($event)"></app-form-control-file>
      <div class="form-field">
        <img *ngIf="settingLecture.lecturerAvatar1" [src]="settingLecture.lecturerAvatar1"
          style="width: 100px; height: 100px; align-self: flex-end" />
      </div>
      <div class="form-field">
        <label>اسم المحاضر</label>
        <div>
          <span class="btn-icon btn-edit-icon"></span>
          <input class="form-control" type="text" [(ngModel)]="settingLecture.lecturerName2"
            [ngModelOptions]="{ standalone: true }" />
        </div>
      </div>
      <div class="form-field">
        <label>عنوان رئيسي</label>
        <div>
          <span class="btn-icon btn-edit-icon"></span>
          <input class="form-control" type="text" [(ngModel)]="settingLecture.lecturerAddress2"
            [ngModelOptions]="{ standalone: true }" />
        </div>
      </div>
      <div class="form-field">
        <label>نبذة مختصرة</label>
        <div>
          <span class="btn-icon btn-edit-icon"></span>
          <input class="form-control" type="text" [(ngModel)]="settingLecture.lecturerDescription2"
            [ngModelOptions]="{ standalone: true }" />
        </div>
      </div>
      <app-form-control-file [withAddIcon]="true" [isError]="lecturerAvatar2Error" [label]="lectureControlLabel"
        [placeholder]="settingLecture.lecturerAvatarName2" [error]="lectureControlErrorText"
        (valueChange)="onFileChangeAvatar($event)"></app-form-control-file>
      <div class="form-field">
        <img *ngIf="setting.lecturerAvatar2" [src]="settingLecture.lecturerAvatar2"
          style="width: 100px; height: 100px; align-self: flex-end" />
      </div>
      <div class="form-field form-field-submit">
        <button class="btn btn-green submit-btn no-border" (click)="saveSettingLecture()">
          حفظ
        </button>
      </div>
    </form>
  </div>
</div>