<div class="lections-create-test">
  <div class="lections-create-test--content" [ngClass]="{'collapsed': lecturesService.rightNavState }">
    <div class="lections-create-test--content--section">


      <div >


        <div class="search-for-lections searchdiv" style="float: right;">
          <input class="form-control-search searchinpt" placeholder="ابحث عن كلمة" />
        </div>

        <div *ngFor="let note of notes">
        <div  class="admin-articles-modify-middle margfo">
          <span class="spanedit spanupdate spanlec"> {{note._lecture.LectureName}} </span>
          <div class="btnsdiv">
            <div style="display: flex;">


              <!-- <a class="btn-green btn-large btn-link no-border btnsa" (click)="createPdf(note.id)"><span class="btns">تحميل PDF</span></a> -->
              <a (click)="createPdf()" style="  margin-left: 7px" class="btn-green btn-large btn-link no-border btnsa"><span class="btns">
                  طباعه </span></a>
            </div>

          </div>
        </div>
        <div [id]="note.id" class="card cardsub">
          <div class="divmain ">
            <div *ngFor="let text of note.Notes" class="contenttitle ">
              <h2>{{text.note}} </h2>
            </div>

          </div>

        </div>
        </div>
      </div>
    </div>
  </div>
</div>