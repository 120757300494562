import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { async } from '@angular/core/testing';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { LecturesService } from "@services/apis/lectures.service";
import { QuestionssService } from "@services/apis/questions.service";
import { debug } from 'console';
import { of } from "rxjs";
import { catchError } from "rxjs/operators";

import { QuestionBankItems } from "../constants/admin-fields.mock";

@Component({
  selector: "app-admin-questions-bank",
  styleUrls: ["./admin-questions-bank.sass"],
  templateUrl: "./admin-questions-bank.html",
})
export class AdminQuestionsBankComponent implements OnInit {
  Lectures: Array<any>;
  activeStep: number;
  @Output() focus: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
  // todo
  // Update this variables with real data-naming.
  paginationPagesCount: Array<number>;
  activePaginationItem: number;
  newQuestionBankItem: any;
  radioItemPlaceholders: Array<string>;
  isquestionRequired: boolean;
  selectedlecture: any;
  uploadFileError: boolean;
  Questions: any;
  isdeleteConfirm:boolean;
  constructor(
    private router: Router,
    private LectureApi: LecturesService,
    private QuestionssApi: QuestionssService,
    public sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params)=>{
      window.scroll(0, 0);
    });
    const DataResult: any = await this.LectureApi.query({}).toPromise();
    this.Lectures = DataResult.result;
    this.Questions=await this.QuestionssApi.query({}).toPromise();
    this.Questions=this.Questions.result;
  }
  getLectures(lectreId){
    if(this.Questions==null) this.Questions=[];
    return this.Questions.filter(x=>x.lectureId==lectreId).length;
  }
  async addNewQuestion(templateID: number) {
    this.selectedlecture = this.Lectures[templateID];
    this.router.navigateByUrl("/dashboard/admin/questions-bank/"+this.selectedlecture.id).then();
  }
}
