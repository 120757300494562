import { UploadService } from "../../../../services/apis/upload.service";
import { AsyncObject } from "../../asyncObject";
// Angular
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { catchError, finalize } from "rxjs/operators";
import { of } from "rxjs/internal/observable/of";

import { FormGroup } from "@angular/forms";
import { difference } from "lodash";
import { AsyncArray } from "@modules/shared/AsyncArray";

@Component({
  selector: "tk-upload",
  templateUrl: "upload.component.html",
  styleUrls: ["upload.component.scss"],
})
export class TkUploadComponent implements OnInit, OnChanges {
  @Input() value: {};
  @Output() valueChange = new EventEmitter<any>();
  @Input() form: FormGroup;
  @Input() filetype: string; // [image ,file, misc]
  @Input() title: string;

  acceptedTypes = {
    image: ".png,.jpg,.jpeg",
    // file: ".pdf,.doc,.docx",
    // misc: ".png,.jpg,.jpeg,.pdf,.doc,.docx,.mp4,.mov,.mp3",
    misc: ".mp4",
  };
  translationLabels: string[];
  progress: AsyncObject<number>;
  loading$: AsyncObject<boolean>;
  images: AsyncObject<any>;
  selected = [];
  selectedCount = 0;

  messages: any;
  public alerts: AsyncArray;

  constructor(
    private api: UploadService,

    private translate: TranslateService
  ) {
    this.images = new AsyncObject({});
    this.loading$ = new AsyncObject(false);
    this.progress = new AsyncObject(null);
    this.images.value.subscribe((x) => {
      this.value = x;
      this.valueChange.emit(x);
      // console.log('sync value', x, this.value);
    });
  }

  ngOnChanges() {
    // console.log('set...', this.value)
    this.images.next(this.value);
  }

  async ngOnInit() {
    this.alerts = new AsyncArray();
    this.filetype = this.filetype || "image";
  }

  startUpload() {
    if (this.form) {
      this.form.disable();
    }
    this.loading$.next(true);
  }
  endUpload() {
    if (this.form) {
      this.form.enable();
    }
    this.loading$.next(false);
    console.log("end upload");
    this.progress.next(null);
  }
  getFiles(e) {
    const fileList: FileList = e.target.files;
    const files = [];
    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList.item(i));
    }
    return files;
  }
  onFileUploadchange(event) {
    const files = this.getFiles(event);
    this.startUpload();
    this.api
      .upload(files, 'zamalti-vidoes')
      .pipe(
        catchError(() => {
          this.alerts.push({ message: "file upload faild" });
          return of(null);
        }),
        finalize(() => this.endUpload())
      )
      .subscribe((response) => {
        if (response.type === "result") {
          const newFiles = response.files.map((x) => ({
            path: x["path"],
            fileName: x["originalFilename"],
            type: x["type"],
            extension: x["extension"],
          }));

          this.images.next(newFiles[0]);
          this.valueChange.emit(this.value);
        } else {
          this.progress.next(response.percentDone);
        }
      });
  }

  public closeAlert(alert) {
    this.alerts.remove(alert);
  }
}
