<div class="home-analytics-profile">
  <div class="home-analytics-profile-info-card row edittable">
    <div class="home-analytics-profile-info-card-left col-lg-1">
      <a [routerLink]="['/lections']" class="btn btn-green no-border">

        <span>المحاضرات</span>
        <div class="arrow-left-icon"></div>
      </a>
    </div>
    <div class="home-analytics-profile-info-card-right col-lg-11">
      <p>مادة المحاسبة</p>
      <p>التحضير لمادة المحاسبة لأختبار الهيئة السعودية للمحاسبين القانونين</p>
      <p>
        مع التطورات السريعة في المعايير المحاسبية والأنظمة والممارسات الصناعية،
        يحتاج الأفراد إلى التدريب المستمر لمواكبة هذه التطورات. كما أنهم بحاجة
        الى تحسين جودة التقارير المالية من خلال التسجيل والإفصاح بدقة عن
        المعاملات التجارية العادية وغير العادية. أعدت هذه الدورة من قبل
        الأخصائيين من الأوساط الأكاديمية والذين لديهم خبرة عملية راسخة. المنهجية
      </p>
    </div>
  </div>
</div>