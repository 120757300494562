<div class="admin-summaries-modify" *ngIf="cvFiles.length > 0">
  <div class="admin-summaries-modify-content">
    <form>
      <div class="form-field form-field-no-label form-field-with-range" *ngFor="let file of cvFiles; let ID = index;">
        <div class="form-field-with-range-container">
          <button class="btn-icon btn-icon-remove icon-circle c-pointer"></button>
          <app-range-controls [rowId]="file.id" [row]="file" [initialCount]="cvFiles.length"
            (updateRangeCount)="handleFilesCountChange($event)">
          </app-range-controls>
          <div class="form-control-container">
            <label class="label-styles">الملخص {{ID+1}}</label>
            <!-- todo Replace to common components -->
            <input class="form-control" type="file" (change)="onFileChange($event,file.id)" />
            <div *ngIf="cvFiles[ID].FileUpload == false" class="form-control-feedback" style="color: red">
              حدث خطأ فى تحميل الملف
            </div>
            <button class="btn-icon btn-icon-upload icon-circle icon-100 upload-btn"></button>
            <div class="form-control-upload-pane">{{file.originalFilename}}</div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div *ngIf="cvFiles.length === 0" class="no-valid-data-available">
  <p>لا يوجد ملفات</p>
</div>