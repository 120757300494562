<div class="admin-continue-subscribe padding">
  <!-- Continue subscribe dialog -->
  <div class="admin-edit-user-dialog-content">
    <div class="admin-edit-user-dialog-content-header">
      <div style="margin-top: 1%;" class="close-icon-gray icon-100" (mousedown)="close()"></div>
      <span>{{messageConfirm}}</span>
    </div>
    <div class="admin-edit-user-dialog-content-body"></div>
  </div>
  <div class="admin-edit-user-dialog-content-footer div-btn">
    <button class="btn-green btn-large no-border" (click)="confirmdelete()">
      تاكيد
    </button>
    <button class="btn-dark btn-large no-border" (click)="close()">
      الغاء
    </button>
  </div>
</div>