import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { SummariesService } from "@services/apis/summaries.service";
import { of } from "rxjs/internal/observable/of";
import { catchError } from "rxjs/operators";

@Component({
  selector: "app-admin-summaries-modify",
  styleUrls: ["./admin-summaries-modify.sass"],
  templateUrl: "./admin-summaries-modify.html",
})
export class AdminSummariesModifyComponent implements OnInit {
  constructor(private SummariesApi: SummariesService,
              private activatedRoute:ActivatedRoute) {}

  cvFiles: Array<any>;

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params)=>{
      window.scroll(0, 0);
    });
    this.cvFiles = [];

    const files: any = await this.SummariesApi.query({}).toPromise();

    if (files.result) {
      this.cvFiles = files.result;
      if (this.cvFiles.length == 0) {
        this.SummariesApi.save({}).subscribe((res) => {
          this.cvFiles.push(res);
        });
      }
    }
  }
  async onFileChange(event: any, id: any) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      let fileUploaded = await this.SummariesApi.upload(file)
        .pipe(
          catchError((error) => {
            console.error(error);
            let uploadFileIndex = this.cvFiles.findIndex((x) => x.id == id);
            this.cvFiles[uploadFileIndex].FileUpload = false;
            return of(null);
          })
        )
        .toPromise();
      if (fileUploaded) {
        fileUploaded.id = id;
        this.SummariesApi.save(fileUploaded).subscribe((res) => {
          let uploadFileIndex = this.cvFiles.findIndex((x) => x.id == res.id);
          this.cvFiles[uploadFileIndex] = res;
          this.cvFiles[uploadFileIndex].FileUpload = true;
        });
      }
    }
  }
  handleFilesCountChange(data: any) {
    if (data.count > this.cvFiles.length) {
      this.cvFiles.push({ id: data.rowId });
    } else if (data.count <= this.cvFiles.length) {
      this.cvFiles = this.cvFiles.filter((x) => x.id != data.rowId);
    }
    if (this.cvFiles.length == 0) {
      this.SummariesApi.save({}).subscribe((res) => {
        this.cvFiles.push(res);
      });
    }
  }
}
