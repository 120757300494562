import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PlansService } from "@services/apis/plans.service";
import { AdminContinueDeleteComponent } from "../admin-continue-delete-component/admin-continue-deletecomponent";
import { AdminMainControlSaveComponent } from "../admin-main-control-component/admin-main-control-save-component/admin-main-control-save-component";

@Component({
  selector: "app-admin-subscribtion-control",
  templateUrl: "./admin-subscribtion-control.html",
  styleUrls: ["./admin-subscribtion-control.sass"],
})
export class AdminSubscribtionControlComponent implements OnInit {
  usersData: Array<any>;
  plans: any;
  DurationList: any;
  selectedText: string;
  planIndex: -1;
  constructor(
    private planApi: PlansService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      window.scroll(0, 0);
    });
    this.plans = [];
    this.DurationList = [
      {
        text: "سته أشهر",
        value: "6",
      },
      {
        text: "سنه كاملة",
        value: "12",
      },
      {
        text: "سنتين ",
        value: "24",
      },
    ];
    this.plans = await this.planApi.query({}).toPromise();
    if (this.plans.result) {
      this.plans = this.plans.result;
    }
  }
  changeDuration(event) {
    let selectedIndex = event.target.selectedIndex;
    this.selectedText = this.DurationList[selectedIndex].text;
  }
  saveUserData(plan: any, index: any) {
    if (
      plan.name != null &&
      plan.name != "" &&
      plan.price != null &&
      plan.price != "" &&
      plan.durationMonths != null &&
      plan.durationMonths != ""
    ) {
      plan.duration = this.selectedText;
      this.planIndex = -1;
      this.planApi.save(plan).subscribe(
        (res) => {
          const ask = this.modalService.open(AdminMainControlSaveComponent, {
            windowClass: "forgot-password",
          });
          ask.componentInstance.displayMessage = "تم حفظ خطه الاشتراك ";
        },
        (error) => {
          const ask = this.modalService.open(AdminMainControlSaveComponent, {
            windowClass: "forgot-password",
          });
          ask.componentInstance.displayMessage =
            " حدث خطأ اثناء حفظ خطة الاشتراك ";
        }
      );
    } else {
      this.planIndex = index;
    }
  }
  deleteUserData(plan) {
    const ask = this.modalService.open(AdminContinueDeleteComponent, {
      windowClass: "forgot-password",
    });
    ask.result.then(
      async (result) => {
        if (result) {
          this.planApi.destroy(plan.id).subscribe(async (res) => {
            this.plans = await this.planApi.query({}).toPromise();
            if (this.plans.result) {
              this.plans = this.plans.result;
            }
          });
        }
      },
      async (result) => {
        if (result) {
          this.planApi.destroy(plan.id).subscribe(async (res) => {
            this.plans = await this.planApi.query({}).toPromise();
            if (this.plans.result) {
              this.plans = this.plans.result;
            }
          });
        }
      }
    );
    ask.componentInstance.messageConfirm =
      " هل انت متاكد من حذف الخطه " + plan.name + "";
  }
  addNewPlan() {
    this.plans.push({});
  }
}
