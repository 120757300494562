import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SettingsService } from "@services/apis/setting.service";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";

import { VideoCardsMock } from "../constants/admin-fields.mock";
import { AdminMainControlSaveComponent } from "./admin-main-control-save-component/admin-main-control-save-component";

import { AdminSettings } from "./interfaces/index";

@Component({
  selector: "app-admin-main-control",
  templateUrl: "./admin-main-control.html",
  styleUrls: ["./admin-main-control.sass"],
})
export class AdminMainControlComponent implements OnInit {
  setting: any;
  videoCards: Array<any>;
  selectedColor: string;
  invalidMobile = false;
  font: any = {};
  fontStyle: any = {};
  lecturerAvatar1Error = false;
  lecturerAvatar2Error = false;
  videoLink = false;
  videoLink2 = false;
  lectureControlErrorText = "حدث خطأ فى تحميل الصوره";
  lectureControlLabel = "صورة المحاضر";
  lectureControlPlaceholder = "Avatar.png";
  isSavedMessage: boolean;
  displayMessage: string;
  settingSocial: any = {};
  settingLecture: any = {};
  constructor(
    private settingApi: SettingsService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) {
    this.setting = {};
  }

  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  isPlay = false;

  toggleVideo() {
    let video = this.videoplayer.nativeElement;
    // this.videoplayer.nativeElement.play();
    let isPlaying =
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > 2;

    if (!isPlaying) {
      video.play();
    }
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      window.scroll(0, 0);
    });
    this.selectedColor = "red";
    this.videoCards = VideoCardsMock;
    let resultSetting: any = await this.settingApi.query({}).toPromise();
    this.setting = resultSetting.result[0];
    this.setSocialSetting();
    this.setFontSetting();
    this.setSettingLecture();
    this.preview();
    //this.admin = AdminsMock[0];
  }
  setSettingLecture() {
    this.settingLecture.id = this.setting.id;
    this.settingLecture.lecturerName1 = this.setting.lecturerName1;
    this.settingLecture.lecturerAddress1 = this.setting.lecturerAddress1;
    this.settingLecture.lecturerDescription1 = this.setting.lecturerDescription1;
    this.settingLecture.lecturerAvatar1 = this.setting.lecturerAvatar1;
    this.settingLecture.lecturerAvatarName1 = this.setting.lecturerAvatarName1;
    this.settingLecture.videoLink1 = this.setting.videoLink1;

    this.settingLecture.lecturerName2 = this.setting.lecturerName2;
    this.settingLecture.lecturerAddress2 = this.setting.lecturerAddress2;
    this.settingLecture.lecturerDescription2 = this.setting.lecturerDescription2;
    this.settingLecture.lecturerAvatar2 = this.setting.lecturerAvatar2;
    this.settingLecture.lecturerAvatarName2 = this.setting.lecturerAvatarName2;
    this.settingLecture.videoLink2 = this.setting.videoLink2;
  }
  setFontSetting() {
    this.font.id = this.setting.id;
    this.font.backgroundColor = this.setting.backgroundColor;
    this.font.fontSize = this.setting.fontSize;
    this.font.fontWeight = this.setting.fontWeight;
    this.font.fontFamily = this.setting.fontFamily;
    this.font.fontColor = this.setting.fontColor;
    this.font.advertisingTitle = this.setting.advertisingTitle;
  }
  setSocialSetting() {
    this.settingSocial.id = this.setting.id;
    this.settingSocial.phone = this.setting.phone;
    this.settingSocial.email = this.setting.email;
    this.settingSocial.facebookLink = this.setting.facebookLink;
    this.settingSocial.twitterLink = this.setting.twitterLink;
    this.settingSocial.instagramLink = this.setting.instagramLink;
    this.settingSocial.address = this.setting.address;
  }
  savesettingSocial(): void {
    if (this.setting.phone != "" && this.setting.phone != null) {
      if (!this.isValidMobile(this.setting.phone)) {
        this.invalidMobile = true;
        return;
      } else {
        this.invalidMobile = false;
      }
    }
    this.settingApi.save(this.settingSocial).subscribe(
      (res) => {
        const ask = this.modalService.open(AdminMainControlSaveComponent, {
          windowClass: "forgot-password",
        });
        ask.componentInstance.displayMessage = "تم حفظ وسائل التواصل ";
      },
      (error) => {
        const ask = this.modalService.open(AdminMainControlSaveComponent, {
          windowClass: "forgot-password",
        });
        ask.componentInstance.displayMessage =
          " حدث خطأ اثناء حفظ وسائل الاتصال ";
      }
    );
  }
  isValidMobile(mobile): boolean {
    return true;
    ///^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{9})$/.test(mobile);
  }
  async onVideoChange(event: any) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      let video = await this.settingApi
        .upload(file)
        .pipe(
          catchError((error) => {
            console.error(error);
            return of(null);
          })
        )
        .toPromise();
      if (video) {
      }
    }
  }
  handleMessageClose() {
    this.isSavedMessage = false;
  }
  async onFileChange(event: any) {
    if (event === " ") {
      this.setting.lecturerAvatar1 = "";
      this.setting.lecturerAvatarName1 = "";
      this.saveSettingLecture();
      return;
    }
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      let image = await this.settingApi
        .upload(file)
        .pipe(
          catchError((error) => {
            console.error(error);
            this.lecturerAvatar1Error = true;
            return of(null);
          })
        )
        .toPromise();
      if (image) {
        this.lecturerAvatar1Error = false;
        this.setting.lecturerAvatar1 = image.path;
        this.setting.lecturerAvatarName1 = image.originalFilename;
      }
    }
  }
  async onFileChangeAvatar(event: any) {
    if (event === " ") {
      this.setting.lecturerAvatar2 = "";
      this.setting.lecturerAvatarName2 = "";
      this.saveSettingLecture();
      return;
    }
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      let image = await this.settingApi
        .upload(file)
        .pipe(
          catchError((error) => {
            console.error(error);
            this.lecturerAvatar2Error = true;
            return of(null);
          })
        )
        .toPromise();
      if (image) {
        this.lecturerAvatar2Error = false;
        this.setting.lecturerAvatar2 = image.path;
        this.setting.lecturerAvatarName2 = image.originalFilename;
      }
    }
  }
  savefont() {
    this.setFontSetting();
    this.settingApi.save(this.font).subscribe(
      (res) => {
        const ask = this.modalService.open(AdminMainControlSaveComponent, {
          windowClass: "forgot-password",
        });
        ask.componentInstance.displayMessage = "تم حفظ  الخط ";
      },
      (error) => {
        const ask = this.modalService.open(AdminMainControlSaveComponent, {
          windowClass: "forgot-password",
        });
        ask.componentInstance.displayMessage = " حدث خطأ اثناء حفظ  الخط ";
      }
    );
  }

  saveVideos() {
    this.settingLecture.videoLink1 = this.setting.videoLink1;
    this.settingLecture.videoLink2 = this.setting.videoLink2;
    this.settingApi.save(this.settingLecture).subscribe(
      (res) => {
        const ask = this.modalService.open(AdminMainControlSaveComponent, {
          windowClass: "forgot-password",
        });
        ask.componentInstance.displayMessage = "تم حفظ  الفيديوهات ";
      },
      (error) => {
        const ask = this.modalService.open(AdminMainControlSaveComponent, {
          windowClass: "forgot-password",
        });
        ask.componentInstance.displayMessage =
          " حدث خطأ اثناء حفظ  الفيديوهات ";
      }
    );
  }

  saveSettingLecture() {
    this.settingLecture.videoLink1 = this.setting.videoLink1;
    this.settingLecture.videoLink2 = this.setting.videoLink2;
    this.settingLecture.lecturerAvatar1 = this.setting.lecturerAvatar1;
    this.settingLecture.lecturerAvatarName1 = this.setting.lecturerAvatarName1;
    this.settingLecture.lecturerAvatar2 = this.setting.lecturerAvatar2;
    this.settingLecture.lecturerAvatarName2 = this.setting.lecturerAvatarName2;
    this.settingApi.save(this.settingLecture).subscribe(
      (res) => {
        const ask = this.modalService.open(AdminMainControlSaveComponent, {
          windowClass: "forgot-password",
        });
        ask.componentInstance.displayMessage = "تم حفظ  المحاضرين ";
      },
      (error) => {
        const ask = this.modalService.open(AdminMainControlSaveComponent, {
          windowClass: "forgot-password",
        });
        ask.componentInstance.displayMessage = " حدث خطأ اثناء حفظ  المحاضرين ";
      }
    );
  }
  preview(): void {
    this.fontStyle = {
      backgroundColor: this.font.backgroundColor,
      "font-size": this.font.fontSize + "px",
      "font-weight": this.font.fontWeight,
      "font-family": this.font.fontFamily,
      color: this.font.fontColor,
    };
  }
  changeVideoType(type: string) {
    if (type == "link") this.videoLink = true;
    else if (type == "upload") this.videoLink = false;
  }

  changeVideoType2(type: string) {
    if (type == "link") this.videoLink2 = true;
    else if (type == "upload") this.videoLink2 = false;
  }
  youtubeLinkToId(url) {
    if (url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    }
  }
}
