import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MessagesService } from '../../../../services/apis/messages.service';

@Component({
  selector: "admin-send-response",
  templateUrl: "admin-send-response.html",
  styleUrls: ["./admin-send-response.sass"],
})
export class SendResponseComponent implements OnInit {
  @Input() public lectureId: string;
  userId: string;
  selectedPerson:any={};
  response:string;
  constructor(
    private modalService: NgbModal,
    private msgApi: MessagesService
  ) {}
  ngOnInit() {}

  handleClose(saveBefore?: boolean) {
    if (saveBefore) {
      this.msgApi.save({
        answer:this.response,
        id:this.selectedPerson.id,
        isSeen:true,
        toAdmin:false
      }).subscribe((res)=>{
        this.modalService.dismissAll();
      });
    }
  }
  close() {
    this.modalService.dismissAll();
  }
}
