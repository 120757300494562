import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TransactionsService } from "@services/apis/transaction.service";
import * as moment from "moment";
import { PlansService } from "../../../../services/apis/plans.service";
import { AdminContinueDeleteComponent } from "../admin-continue-delete-component/admin-continue-deletecomponent";

@Component({
  selector: "app-admin-continue-subscribe",
  templateUrl: "./admin-continue-subscribe.html",
  styleUrls: ["./admin-continue-subscribe.sass"],
})
export class AdminContinueSubscribeComponent implements OnInit {
  dialogOpened: boolean;
  plans: any = [];
  @Input() student: any = {};
  @Input() selectedItem: any = {};
  isSubPeriod: boolean;
  transactions: any = [];
  planId: any;
  constructor(
    private planApi: PlansService,
    private transactionApi: TransactionsService,
    private modalService: NgbModal
  ) {}
  async handleClose(saveBefore?: boolean) {
    if (saveBefore) {
      let ispositive = true;
      if (
        this.student.subPeriod == null ||
        this.student.subPeriod == "" ||
        !Number(this.student.subPeriod) ||
        Number(this.student.subPeriod) < 0
      ) {
        this.isSubPeriod = true;
        return;
      } else {
        let endDate = null;
        let startDate=null;
        if (this.transactions != null && this.transactions.length > 0) {
          endDate = moment(
            new Date(this.transactions[this.transactions.length - 1].endDate),
            "DD-MM-YYYY"
          ).add(this.student.subPeriod, "days");
          startDate=this.transactions[this.transactions.length - 1].endDate;
        } else {
          startDate=new Date();
          endDate = moment(new Date(), "DD-MM-YYYY").add(
            this.student.subPeriod,
            "days"
          );
        }
       let currentPlan= await this.planApi.get(this.planId).toPromise();
        let transaction = {
          userId: this.selectedItem.items[0].id,
          planId: this.planId,
          phoneNumber: this.student.phone,
          amount: 0,
          isSuccess: true,
          allowExam:currentPlan?currentPlan.allowExam:false,
          allowLecture:currentPlan?currentPlan.allowLecture:false,
          startDate: startDate ? new Date(startDate) : new Date(),
          endDate: new Date(endDate),
        };
        this.transactionApi.saveTransaction(transaction).subscribe((res) => {
          this.modalService.dismissAll();
        });
        this.isSubPeriod = false;
      }
    }
  }
  close() {
    this.modalService.dismissAll();
  }
  onValueChange(event) {}
  async ngOnInit() {
    this.plans = await this.planApi.query({}).toPromise();
    this.plans = this.plans.result;
    this.planId = this.student.plan;
    if (this.selectedItem.items[0].id) {
      let transactions: any = await this.transactionApi
        .query({ where: { userId: this.selectedItem.items[0].id } })
        .toPromise();
      this.transactions = transactions.result.filter(
        (x) => x.isSuccess == true && moment(x.endDate).isAfter(moment())
      );
    }
  }
  removeTransaction(transaction){
    const ask = this.modalService.open(AdminContinueDeleteComponent, {
      windowClass: "forgot-password",
    });
    ask.result.then(
      async (result) => {
        if (result) {
          this.transactionApi.destroy(transaction.id).subscribe();
        }
      },
      async (result) => {
        if (result) {
          this.transactionApi.destroy(transaction.id).subscribe();
        }
      }
    );
    ask.componentInstance.messageConfirm =
    "  هل انت متاكد من حذف الخطه " +transaction._plan.name  + "";

  }
}
