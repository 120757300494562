import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-ask-lector",
  templateUrl: "./admin-ask-lector.html",
  styleUrls: ["./admin-ask-lector.sass"],
})
export class AdminAskLectorComponent implements OnInit {
  @Output() collectionAnswer = new EventEmitter<string>();
  answer:string;
  constructor(private modalService: NgbModal){
    
  }
  handleClose(saveBefore?: boolean) {
    this.modalService.dismissAll(this.answer);
  }
close(){
  this.modalService.dismissAll();
}
  ngOnInit() {}
}
