import { LecturesService } from "./../../../../services/apis/lectures.service";
import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "@services/apis/authentication.service";

@Component({
  selector: "app-admin-continue-delete",
  templateUrl: "./admin-continue-delete.html",
  styleUrls: ["./admin-continue-delete.sass"],
})
export class AdminContinueDeleteComponent implements OnInit {
  dialogOpened: boolean;
  plans: any = [];
  @Input() messageConfirm: any = "";
  isSubPeriod: boolean;
  constructor(private modalService: NgbModal) {}
  confirmdelete() {
    this.modalService.dismissAll(true);
  }
  close() {
    this.modalService.dismissAll();
  }
  async ngOnInit() {}
}
