<div class="range-controls">
  <button
    class="range-controls--minus btn-icon btn-icon-minus icon-circle"
    (click)="minus()"
  ></button>
  <button
    class="range-controls--plus btn-icon btn-icon-plus icon-circle"
    (click)="plus()"
  ></button>
</div>
