<!--  Question confirm dialog -->

  <div  class="admin-question-confirm-dialog-content div-center">
    <img src="assets/images/sub-complete.png" />
    <p>هل تريد بالتأكيد حذف هذا السؤال</p>
  </div>
  <div class="admin-question-confirm-dialog-content-footer div02">
    <button class="btn-green btn-large no-border" (click)="handleClose(true)">
      تاكيد
    </button>
    <button class="btn-dark btn-large no-border" (click)="close()">
      الغاء
    </button>
  </div>
