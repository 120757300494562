import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-form-control-file",
  templateUrl: "./form-control-file.html",
  styleUrls: ["./form-control-file.sass"],
})
export class FormControlFileComponent implements OnInit {
  @Input() name: string;
  @Input() ID: string | number;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() withAddIcon: boolean;
  @Input() isError = false;
  @Input() error: string;
  @Output() valueChange = new EventEmitter<string>();

  constructor() {}

  formField: FormControl;

  ngOnInit() {
    this.formField = new FormControl();
  }

  updateValue(event) {
    this.placeholder = event.target.files[0].name;
    this.valueChange.emit(event);
  }

  deleteImage() {
    this.valueChange.emit(" ");
  }
}
