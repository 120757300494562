<div class="admin-edit-user-dialog-content">
  <div class="admin-edit-user-dialog-content-header">
    <div
      class="close-icon-gray icon-100"
      (mousedown)="close()"
      style="margin-top: 50px;"
    ></div>
    <span class="displayMsg"> {{displayMessage}}</span>
  </div>
</div>
<div class="admin-edit-user-dialog-content-footer">
  <button
    class="btn-dark btn-large no-border confrmBtn"
    (click)="close()"
    
    >
    تم
  </button>
</div>
