import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@services/apis/authentication.service';
import { MessagesService } from '@services/apis/messages.service';

@Component({
  selector: "app-admin-main-control-save",
  templateUrl: "./admin-main-control-save.html",
  styleUrls: ["./admin-main-control-save.sass"],
})
export class AdminMainControlSaveComponent implements OnInit {
  @Input() displayMessage:string;
  constructor(private modalService: NgbModal){
    
  }
  close() {
    this.modalService.dismissAll();
  }
 async ngOnInit() {

  }
}
