<div class="col-lg-12 exam_questions margtn-div">
    <div class="question" *ngIf="questions[displayedQuest]">
        <div class="row justify-content-between " style="padding-top: 3%;">
            <div class="col-lg-2 difficulty_level mar-bottom">
                <p class="text-center">
                    ({{questions[displayedQuest]?.level}})
                </p>
            </div>
            <div class="col-lg-10 question_text mar-bottom">
                <!-- <p id="question_text" class="text-right" innerHTML="  {{displayedQuest + 1}}. {{changeNumbersToAr(questions[displayedQuest]?.question)}}">
                </p> -->
                <!-- <iframe (keydown)="copyDisable($event)" (load)="iframeStyle()" id="questionIframe"
                        [srcdoc]="changeNumbersToAr(questions[displayedQuest]?.question)" height="auto" width="100%" frameBorder="0" lang="ar"
                        dir="rtl"></iframe> -->

                <ckeditor *ngIf="questions[displayedQuest]?.question" (click)="hideToolbar()" style="text-align:right" id="cke_editor23"
                          ContentsLangDirection="rtl" readOnly="true" type="inline" [config]="config"
                          [data]="changeNumbersToAr(questions[displayedQuest]?.question)">
                </ckeditor>

                <img class="question_image" *ngIf="!questions[displayedQuest]?.question" [src]="questions[displayedQuest]?.questionImage" alt="">
                <!-- <div id="questionIframe" [innerHTML]="changeNumbersToAr(questions[displayedQuest]?.question)">
                </div> -->
            </div>
            <div *ngIf="questions[displayedQuest]?.type =='مقالى'" class="col-lg-2 ml-4 go_back ">
                <button [disabled]="isCompleted == true || answered == true" id="showArticleQstAnswer" class="font25"
                        *ngIf="questions[displayedQuest]?.type =='مقالى'" (click)="checkedAnswer(true)">
                    اظهار الاجابة
                </button>
            </div>
            <div class="col-lg-12 horizontal_line"></div>
            <div class="col-lg-12" *ngIf="questions[displayedQuest]?.type =='مقالى' && showAnswer">
                <div class="row" style="margin-right: 3%; direction: rtl;">
                    {{questions[displayedQuest]?.rightAnswer}}
                </div>
            </div>
            <div class="col-lg-12 exam_answers margchoice" *ngIf="questions[displayedQuest]?.type !='مقالى'">
                <div class="row" style="padding-top: 3%;">
                    <div class="col-lg-12 round" *ngFor="let choice of questions[displayedQuest]?.choices; let i = index">
                        <input *ngIf="answers[i]" type="checkbox" class="form-check-input checkbox-round" name="answer"
                               [(ngModel)]="answers[i].selected" (change)="checkedAnswer(answers[i].selected,i)" [value]="choice.text"
                               [id]="'check'+choice.text" [disabled]="answered || isCompleted == true"
                               [ngClass]="{'wrong_checkbox': answers[i].selected && answered && (!isRightAnswer && answers[i].value != 1 )}" />
                        <label *ngIf="answers[i]" [ngClass]="{'wrong_label': answers[i].selected && answered && !isRightAnswer,
                            'right_label': answers[i].selected && answered && (isRightAnswer || answers[i].value == 1) }">
                            {{characters[i]}}
                        </label>
                        <label dir="rtl" class="text-right mr-3" *ngIf="answers[i]" [ngClass]="{'wrong_label': answers[i].selected && answered && !isRightAnswer,
                        'right_label': answers[i].selected && answered && isRightAnswer}">
                            {{choice.text}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>