import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "@services/apis/authentication.service";
import { TransactionsService } from "@services/apis/transaction.service";
import { UsersService } from "@services/apis/users.service";

@Component({
  selector: "app-home-analytics-sub-and-payment",
  templateUrl: "./home-analytics-sub-and-payment.component.html",
  styleUrls: ["./home-analytics-sub-and-payment.component.sass"],
})
export class SubjectsComponent implements OnInit {
  constructor() {}
  ngOnInit() {
  }
}
