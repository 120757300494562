import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@services/apis/authentication.service';
import { MessagesService } from '@services/apis/messages.service';

@Component({
  selector: "app-admin-continue-send-message",
  templateUrl: "./admin-continue-send-message.html",
  styleUrls: ["./admin-continue-send-message.sass"],
})
export class AdminsendMessageComponent implements OnInit {
  dialogOpened: boolean;
  plans:any=[];
  @Input() selectedItem: any={};
  @Input() student: any={};
  isSubPeriod: boolean;
  constructor(private modalService: NgbModal,
              private messageApi:MessagesService){
    
  }
  close() {
    this.modalService.dismissAll();
  }
  handleMessageClose(saveBefore?: boolean) {
    const currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    let senderId = null;
    if (currentUser) {
      senderId = currentUser.userId;
    }
    if (saveBefore) {
      let message = {
        senderId: senderId,
        receiverId: this.selectedItem.items[0].id,
        message: this.student.message,
        isSeen: false,
        toAdmin:false
      };
      this.messageApi.save(message).subscribe((res)=>{
        this.modalService.dismissAll();
      });
    }
  }
 async ngOnInit() {

  }
}
