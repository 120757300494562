<div class="admin-ask-lector"></div>
  <div class="admin-ask-lector-dialog-content">
    <div style="margin-top: 10px;" class="admin-continue-subscribe-dialog-content-header">
      <div class="close-icon-gray icon-100" (mousedown)="close()"></div>
      <span >اضافة إجابات مجمعة</span>
    </div>
    <div class="admin-ask-lector-dialog-content-body">
      <div class="form-field">
        <label> اضافة إجابات مجمعة</label>
        <textarea class="comment-textarea form-control" style="direction: rtl;" [(ngModel)]="answer"> </textarea>
        <div class="text-info">
          <span>قم بإضافة إجابات وافضل بينها بسطر جديد</span>
          <div class="question-circle-icon icon-100"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="admin-ask-lector-dialog-content-footer">
    <button class="btn-green btn-large no-border" (click)="handleClose(true)">
      اضافه
    </button>
    <button class="btn-dark btn-large no-border" (click)="close()">
      الغاء
    </button>
  </div>

