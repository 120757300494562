<div class="landing_page_wrapper">

    <div class="header_and_carousal_section" dir="rtl">
        <div class="landing_header">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-3 px-0">
                        <div class="row brand">
                            <img src="../../../../../assets/images/new_landing_page/zmalti_logo.svg" alt="">
                            <p *ngIf="!IsLoggedIn() " routerLink="'/'" routerLinkActive="router-link-active"  >زمالتي</p>
                            <p *ngIf="IsLoggedIn() && !isAdmin()" [routerLink]="['/profile/payment']" routerLinkActive="router-link-active" >زمالتي</p>
                            <p *ngIf="IsLoggedIn() && isAdmin()" routerLink="'/'" routerLinkActive="router-link-active"  >زمالتي</p>
                        </div>
                    </div>
                    <div class=" col-lg-2 col-md-3 col-7 px-0">
                        <div class="row register_and_login">
                            <a *ngIf="!IsLoggedIn()" (click)="onNavigate()">انشاء حساب</a>
                            <div *ngIf="!IsLoggedIn()" class="vl"></div>
                            <a *ngIf="!IsLoggedIn()" (click)="onNavigateAuth()">تسجيل دخول</a>
                            <a *ngIf="IsLoggedIn() && isAdmin()" [routerLink]="['/dashboard/admin/articles-list']">لوحة التحكم</a>
                            <a *ngIf="IsLoggedIn() && !isAdmin()" [routerLink]="['/profile/me']">الملف الشخصي</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="carousal">

            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <!-- <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol> -->
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="row justify-content-center">
                            <div class="col-8 d-flex justify-content-center">
                                <h1>تعلم مع أفضل موقع للمحاسبة</h1>
                            </div>
                            <div class="col-8 d-flex justify-content-center">
                                <p>محاضرات مسجلة وأسئلة مقالية واختيارية في الموقع</p>
                            </div>
                            <div class="col-8 d-flex justify-content-center">
                                <a (click)="onNavigate()">تسجيل</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <a class="carousel-control-prev" data-target="#carouselExampleIndicators" role="button"
                    data-slide="prev">

                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" data-target="#carouselExampleIndicators" role="button"
                    data-slide="next">

                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a> -->
            </div>


        </div>
    </div>


    <section class="main-section text-right" dir="rtl">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-9 col-12">
                    <h4 class="font-weight-bold">
                        الدورة معتمدة من المؤسسة العامة للتدريب التقني والمهني
                        <br>
                        رقم اعتماد الدورة 117162818. يمكنك تقسيط مبلغ الدورة على دفعتين
                    </h4>
                    <p>
                        بعد الانتهاء من الدورة سوف تحصل على شهادة معتمدة من المؤسسة العامة للتدريب التقني والمهني
                    </p>
                    <div class="crop">
                        <img src="../../../../../assets/images/new_landing_page/training_corporaton_logo.svg">
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="second-section text-right" dir="rtl">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-12 text-center">
                    <h2 class="mb-4 font-weight-bold">
                        العملية المتبعة
                    </h2>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <img src="../../../../../assets/images/new_landing_page/man_and_board.svg">
                            <h5 class="card-title">
                                استعد بثقة
                            </h5>
                            <p class="card-text">
                                .زمالتى يعلم انك لا تستعد فقط للاختبار - إنك تستعد لمستقبلك قد لا يكون التحضير
                                لامتحان
                                زمالة المحاسبين القانونية لمادة المحاسبة سريعا او سهلا , ولكن بمساعدة الله سبحانه
                                وتعالى
                                أولا واخيرا ثم موقع زمالتى, ستمتلك الأدوات والموارد والدعم الذى تحتاجه للنجاح
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <img src="../../../../../assets/images/new_landing_page/man_and_calculator.svg">
                            <h5 class="card-title">
                                كن مستعد
                            </h5>
                            <p class="card-text">
                                الموقع الوحيد الذي تحتاجه للأستعداد لأهم اختبار في مهنتك.استخدم الموقع و استمع الي
                                المحاضرات المسجلة و تمرن على الاسئلة المقاليه و الاسئلة الاختياريه مع شرح مفصل للحل
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="plans-section text-right" dir="rtl">
        <div class="container">
            <app-fellowship-table [isHome]="true"></app-fellowship-table>
            <!-- <div class="row justify-content-center">
                <div class="col">
                    <div class="card main-plan">
                        <div class="card-header">

                        </div>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                محاضرات مسجلة
                            </li>
                            <li class="list-group-item">
                                اختبارات مقالي
                            </li>
                            <li class="list-group-item">
                                اختبارات متعددة
                            </li>
                        </ul>

                    </div>
                </div>

                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            بنك الأسئلة فقط
                        </div>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <img src="../../../../../assets/images/new_landing_page/Icon ionic-md-close.svg">
                            </li>
                            <li class="list-group-item">
                                <img src="../../../../../assets/images/new_landing_page/Icon ionic-md-checkmark.svg">
                            </li>
                            <li class="list-group-item">
                                <img src="../../../../../assets/images/new_landing_page/Icon ionic-md-checkmark.svg">
                            </li>
                        </ul>
                        <div class="card-footer">
                            <h1>
                                1250
                                <span>
                                    ريال
                                </span>
                            </h1>
                            <p>
                                عام كامل
                            </p>
                            <a href="#" class="btn btn-subscripe">
                                اشتراك
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            بنك الأسئلة فقط
                        </div>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <img src="../../../../../assets/images/new_landing_page/Icon ionic-md-checkmark.svg">
                            </li>
                            <li class="list-group-item">
                                <img src="../../../../../assets/images/new_landing_page/Icon ionic-md-close.svg">
                            </li>
                            <li class="list-group-item">
                                <img src="../../../../../assets/images/new_landing_page/Icon ionic-md-close.svg">
                            </li>
                        </ul>
                        <div class="card-footer">
                            <h1>
                                1250
                                <span>
                                    ريال
                                </span>
                            </h1>
                            <p>
                                عام كامل
                            </p>
                            <a href="#" class="btn btn-subscripe">
                                اشتراك
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card usual-plus">
                        <div class="card-header">
                            بنك الأسئلة فقط
                        </div>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <img
                                    src="../../../../../assets/images/new_landing_page/Icon ionic-md-checkmark-circle.svg">
                            </li>
                            <li class="list-group-item">
                                <img
                                    src="../../../../../assets/images/new_landing_page/Icon ionic-md-checkmark-circle.svg">
                            </li>
                            <li class="list-group-item">
                                <img
                                    src="../../../../../assets/images/new_landing_page/Icon ionic-md-checkmark-circle.svg">
                            </li>
                        </ul>
                        <div class="card-footer">
                            <h1>
                                2500
                                <span>
                                    ريال
                                </span>
                            </h1>
                            <p>
                                عام كامل
                            </p>
                            <a href="#" class="btn btn-subscripe">
                                اشتراك
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            بنك الأسئلة فقط
                        </div>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <img src="../../../../../assets/images/new_landing_page/Icon ionic-md-checkmark.svg">
                            </li>
                            <li class="list-group-item">
                                <img src="../../../../../assets/images/new_landing_page/Icon ionic-md-checkmark.svg">
                            </li>
                            <li class="list-group-item">
                                <img src="../../../../../assets/images/new_landing_page/Icon ionic-md-checkmark.svg">
                            </li>
                        </ul>
                        <div class="card-footer">
                            <h1>
                                2000
                                <span>
                                    ريال
                                </span>
                            </h1>
                            <p>
                                عام كامل
                            </p>
                            <a href="#" class="btn btn-subscripe">
                                اشتراك
                            </a>
                        </div>
                    </div>
                </div>

            </div> -->

            <div class="row">
                <div class="col-12 gradient_div">
                    <div class="row justify-content-center">
                        <div class="col-lg-5 col-md-5 col-12">
                            <p class="payment_info">
                                900132003330008 : يمكنك الاشتراك عن طريق بنك البلاد وارسال الايصال
                                الى واتس الخاص بالموقع رقم حساب بنك البلاد
                            </p>
                        </div>
                        <div class="col-lg-5 col-md-5 col-12">
                            <p class="dark_green payment_info text-center">
                                SA 0515 000 900 132 00 333 0008 : رقم الابيان
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="third_section" dir="rtl">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-8 text-center">
                    <h2 class="mb-4 font-weight-bold">
                        تعرف علينا
                    </h2>
                    <p class="mb-3">
                        فيديوهات مسجلة عن سجلنا وأعمالنا وأهم الشركات التي نقوم بأدراتها
                        وطريقة استخدام الموقع
                    </p>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-4 know_us_divs" *ngIf="setting.videoLink1">
                    <div class="row">
                        <div class="component" *ngIf="setting.videoLink1">
                            <!--component_right -->
                            <vime-player id="youtubLink" controls>
                                <vime-youtube [videoId]="youtubeLinkToId(setting?.videoLink1)"></vime-youtube>
                                <vime-video *ngIf="!youtubeLinkToId(setting?.videoLink1)">
                                    <source [src]="setting.videoLink1" />
                                </vime-video>
                            </vime-player>
                            <!-- <img src="../../../../../assets/images/new_landing_page/play.svg"> -->
                        </div>
                    </div>
                    <p class="text-center">{{setting.videoName1}}</p>
                </div>
                <div class="col-md-4 know_us_divs" *ngIf="setting.videoLink2">
                    <div class="row">
                        <div class="component " *ngIf="setting.videoLink2">
                            <!-- component_center -->
                            <vime-player controls>
                                <vime-youtube [videoId]="youtubeLinkToId(setting?.videoLink2)"></vime-youtube>
                                <vime-video *ngIf="!youtubeLinkToId(setting?.videoLink2)">
                                    <source [src]="setting.videoLink2" />
                                </vime-video>
                            </vime-player>
                            <!-- <img src="../../../../../assets/images/new_landing_page/play.svg"> -->
                        </div>
                    </div>
                    <p class="text-center">{{setting.videoName2}}</p>
                </div>
                <div class="col-md-4 know_us_divs" *ngIf="setting.videoLink3">
                    <div class="row">
                        <div class="component component_left" *ngIf="setting.videoLink3">
                            <vime-player controls>
                                <vime-youtube [videoId]="youtubeLinkToId(setting?.videoLink3)"></vime-youtube>
                                <vime-video *ngIf="!youtubeLinkToId(setting?.videoLink3)">
                                    <source [src]="setting.videoLink3" />
                                </vime-video>
                            </vime-player>
                            <!-- <img src="../../../../../assets/images/new_landing_page/play.svg"> -->
                        </div>
                    </div>
                    <p class="text-center">{{setting.videoName3}}</p>
                </div>
            </div>
        </div>
    </section>


    <section class="fourth_section" dir="rtl">
        <div class="pt-5 ">
            <div class="row justify-content-center main_row">
                <div class="col-12 d-flex justify-content-center">
                    <img class="mx-auto" src="../../../../../assets/images/new_landing_page/man_and_calculator.svg"
                        alt="">
                </div>
                <div class="col-12">
                    <h1 class="text-center">نبذة عن زمالتي</h1>
                </div>

                <div class="col-lg-4 col-md-9 col-11">
                    <p class="text-center">
                        يحتوي هذا الموقع على محاضرات مسجلة بالإضافة الي اسئلة اختبارات متعددة و مقالية، تساعدكم بعد
                        الاعتماد
                        على الله عز ثم جهدك الشخصي. فلسفتنا عن اختبار الزمالة لمادة المحاسبة مبنية على خبرة مقدمي
                        الدورة. تم
                        تنظيم الدورات بطريقة واضحة و فعالة مما يسمح لك بالتركيز على ما يجب ان تعرفة لاجتياز الاختبار.
                        تستطيع
                        المذاكرة في أي وقت تشاء بدون قيود او مواعيد محددة
                    </p>
                </div>
            </div>
        </div>
    </section>


    <footer class="footer_section" dir="rtl">
        <div class="contain">
            <div class="row justify-content-between">
                <div class="col-lg-5 col-md-5 col-12 left_side">
                    <div class="row justify-content-end pl-5">
                        <div class="col-lg-3 col-6">
                            <div class="row flow_col align_center_sm_Screen">
                                <p class="text-right sub_title">الدورات</p>
                                <a *ngFor="let course of courses" class="text-right footer_links" target="_blank"
                                    href=""> {{ course.name }} </a>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6">
                            <div class="row flow_col align_center_sm_Screen">
                                <p class="text-right sub_title">الاشتراك</p>
                                <a class="text-right footer_links" [routerLink]="['/sign-up']"> الخطط والاسعار</a>
                                <a class="text-right footer_links" [routerLink]="['/sign-up']"> الدفع</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-md-7 col-12">
                    <div class="row justify-content-center">
                        <div class="col-lg-5 col-md-8 col-12 contact_us">
                            <div class="row">
                                <div class="col-6 follow_on_twitter">
                                    <p>تابعنا على تويتر </p>
                                </div>
                                <div class="col-6 follow_on_twitter">
                                    <a [href]="setting?.twitterLink" target="_blank" class="d-flex justify-content-start">
                                        <img src="../../../../../assets/images/new_landing_page/twitter.svg">
                                    </a>
                                </div>
                                <div class="col-6 px-0">
                                    <p class="contact_us_p">
                                        تواصل معنا
                                    </p>
                                </div>
                                <div class="col-6">
                                    <div class="row">

                                        <div class="col-12 px-0">
                                            <div class="row">
                                                <img src="../../../../../assets/images/new_landing_page/metro-mobile.svg"
                                                    class="phone_email_icons">
                                                <p>
                                                    {{setting?.phone}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-12 px-0">
                                            <div class="row">
                                                <img src="../../../../../assets/images/new_landing_page/zocial-email.svg"
                                                    class="phone_email_icons">
                                                <p>
                                                    {{setting?.email}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>