import { Component, OnInit, Input } from "@angular/core";
import { LecturesService } from "@services/apis/lectures.service";
import { MessagesService } from "@services/apis/messages.service";

@Component({
  selector: "myquestion",
  templateUrl: "./myquestion.component.html",
  styleUrls: ["./myquestion.component.sass"],
})
export class MyquestionComponent implements OnInit {
  @Input() isRightNavOpened: boolean;
  isTimeThrowed: boolean;
  questions: Array<any>;
  constructor(
    public lecturesService: LecturesService,
    public messageApi: MessagesService
  ) {}

  async ngOnInit() {
    let sender = "";
    const currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      sender = currentUser.userId;
    }
    let resultMessages: any = await this.messageApi
      .query({
        where: {
          senderId: sender
        },
      })
      .toPromise();
      this.questions = resultMessages.result;
  }
}
