<div class="admin-subscribtion-control" *ngIf="plans && plans.length > 0">
  <div class="admin-subscribtion-control-content">
    <div (click)="addNewPlan()"  class="admin-articles-modify-middle">
      <span>أضافة خطه اشتراك</span>
      <button  class="btn icon-circle btn-icon-plus"></button>
    </div>
    <div class="admin-subscribtion-control-card" *ngFor="let plan of plans;let j = index">
      <div class="form-field">
        <span>الاسم</span>
        <div>
          <button class="btn-icon btn-edit-icon"></button>
          <input required class="form-control" type="text" [(ngModel)]="plan.name"
            [ngModelOptions]="{ standalone: true }" />
        </div>
      </div>
      <div class="form-field">
        <span>السعر</span>
        <div>
          <button class="btn-icon btn-edit-icon"></button>
          <input required class="form-control" type="text" [(ngModel)]="plan.price"
            [ngModelOptions]="{ standalone: true }" />
        </div>
      </div>
      <div class="form-field">
        <span>(الدولار)السعر</span>
        <div>
          <button class="btn-icon btn-edit-icon"></button>
          <input required class="form-control" type="text" [(ngModel)]="plan.DolarPrice"
            [ngModelOptions]="{ standalone: true }" />
        </div>
      </div>
      <div class="form-field">
        <span>المدة</span>
        <div>
          <button class="btn-icon btn-edit-icon"></button>
          <select required dir="rtl" style="width: 99%" [(ngModel)]="plan.durationMonths" class="form-control"
            (change)="changeDuration($event)">
            <option *ngFor="let Duration of DurationList" [value]="Duration.value">
              {{Duration.text}}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5"></div>
        <div class="col-md-3">
          <span class="col-md-2">الامتحانات</span>
          <span class="col-md-2">
            <input required  type="checkbox" [(ngModel)]="plan.allowExam"
              [ngModelOptions]="{ standalone: true }" />
          </span>
        </div>
        <div class="col-md-3">
          <span class="col-md-2">المحاضرات</span>
          <span class="col-md-2">
            <input required  type="checkbox" [(ngModel)]="plan.allowLecture"
              [ngModelOptions]="{ standalone: true }" />
          </span>
        </div>
      </div>
      <div *ngIf="this.planIndex==j">
        <p style="color: red;">يجب ان تدخل كل البيانات</p>
      </div>
      <div class="form-field form-field-submit">
        <button style="margin-right: 20px;" class="btn btn-green submit-btn no-border" (click)="saveUserData(plan,j)">
          حفظ
        </button>
        <button style="margin-right: 20px;" class="btn btn-green submit-btn no-border" (click)="deleteUserData(plan)">
          حذف
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="plans.length === 0" class="no-valid-data-available">
  <p>No subscribtions recently available.</p>
</div>