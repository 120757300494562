import { PlansService } from "./../../../../services/apis/plans.service";
import { AdminContinueDeleteComponent } from "./../admin-continue-delete-component/admin-continue-deletecomponent";
import { AdminMainControlSaveComponent } from "./../admin-main-control-component/admin-main-control-save-component/admin-main-control-save-component";
import { CouponsService } from "./../../../../services/apis/coupon.service";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "admin-coupons",
  templateUrl: "./admin-coupons.html",
  styleUrls: ["./admin-coupons.sass"],
})
export class AdminCoupons implements OnInit {
  usersData: Array<any>;
  coupons: any;
  plans: any;
  DurationList: any;
  couponIndex: -1;
  wrongPercentage: boolean = false;
  constructor(
    private couponsApi: CouponsService,
    private planApi: PlansService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      window.scroll(0, 0);
    });
    this.coupons = [];
    await this.planApi.query({}).subscribe((res) => {
      this.plans = res["result"];
    });

    this.coupons = await this.couponsApi.query({}).toPromise();
    if (this.coupons.result) {
      this.coupons = this.coupons.result;
    }
  }

  saveUserData(coupon: any, index: any) {
    if (
      coupon.code != null &&
      coupon.code != "" &&
      coupon.description != null &&
      coupon.description != "" &&
      coupon.percentage != null &&
      coupon.percentage != "" &&
      coupon.plan != null &&
      coupon.plan != ""
    ) {
      if (coupon.percentage > 100) {
        this.wrongPercentage = true;
        setTimeout(() => {
          this.wrongPercentage = false;
        }, 4000);
        return;
      }
      this.couponIndex = -1;
      this.couponsApi.save(coupon).subscribe(
        (res) => {
          const ask = this.modalService.open(AdminMainControlSaveComponent, {
            windowClass: "forgot-password",
          });
          ask.componentInstance.displayMessage = "تم حفظ الكوبون ";
          console.log(res);
        },
        (error) => {
          const ask = this.modalService.open(AdminMainControlSaveComponent, {
            windowClass: "forgot-password",
          });
          ask.componentInstance.displayMessage = " حدث خطأ اثناء حفظ الكوبون";
        }
      );
    } else {
      this.couponIndex = index;
    }
  }
  deleteUserData(coupon) {
    const ask = this.modalService.open(AdminContinueDeleteComponent, {
      windowClass: "forgot-password",
    });
    ask.result.then(
      async (result) => {
        if (result) {
          this.couponsApi.destroy(coupon.id).subscribe(async (res) => {
            this.coupons = await this.couponsApi.query({}).toPromise();
            if (this.coupons.result) {
              this.coupons = this.coupons.result;
            }
          });
        }
      },
      async (result) => {
        if (result) {
          this.couponsApi.destroy(coupon.id).subscribe(async (res) => {
            this.coupons = await this.couponsApi.query({}).toPromise();
            if (this.coupons.result) {
              this.coupons = this.coupons.result;
            }
          });
        }
      }
    );
    ask.componentInstance.messageConfirm = `هل انت متاكد من حذف الكوبون  ${coupon.code} `;
  }
  addNewCoupon() {
    this.coupons.push({});
  }
}
