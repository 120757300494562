export const UsersMock = [
  {
    editDate: "15-2020",
    name: "AhmedAh",
    title: "المحاضرة الاولى",
    commitMessage: "اريد معرفة الفرق بين أنواع المحاسبة",
    phone: "(344)341-2577",
    subPrice: "4000",
    subPeriod: "سنة كاملة",
  },
  {
    editDate: "15-2020",
    name: "AhmedDoja",
    title: "المحاضرة الاولى",
    commitMessage: "كيف يمكنني دراسة المحاسبة",
    phone: "(344)341-2577",
    subPrice: "6000",
    subPeriod: "سنة كاملة",
  },
];

export const ArticlesMock = [
  {
    subjectName:
      "التحضير لمادة المحاسبة لأختبار الهيئة  السعودية للمحاسبين القانونين",
    materialType: "نوع المادة",
    description:
      "التحضير لمادة المحاسبة لأختبار الهيئة  السعودية للمحاسبين القانونين التحضير لمادة المحاسبة لأختبار الهيئة  السعودية للمحاسبين القانونين التحضير لمادة المحاسبة لأختبار الهيئة  السعودية للمحاسبين القانونين التحضير لمادة المحاسبة لأختبار الهيئة  السعودية للمحاسبين",
  },
  {
    subjectName:
      "التحضير لمادة المحاسبة لأختبار الهيئة  السعودية للمحاسبين القانونين",
    materialType: "نوع المادة",
    description:
      "التحضير لمادة المحاسبة لأختبار الهيئة  السعودية للمحاسبين القانونين التحضير لمادة المحاسبة لأختبار الهيئة  السعودية للمحاسبين القانونين",
  },
  {
    subjectName:
      "التحضير لمادة المحاسبة لأختبار الهيئة  السعودية للمحاسبين القانونين",
    materialType: "نوع المادة",
    description:
      "التحضير لمادة المحاسبة لأختبار الهيئة  السعودية للمحاسبين القانونين التحضير لمادة المحاسبة لأختبار الهيئة  السعودية للمحاسبين القانونين التحضير لمادة المحاسبة لأختبار الهيئة  السعودية للمحاسبين القانونين التحضير لمادة المحاسبة لأختبار الهيئة  السعودية للمحاسبين",
  },
];

export const StudentsMock = [
  {
    description: "ارسال رسالة تجديد الاشتراك",
    email: "aaron.williamson@mail.com",
    phone: "(344)341-2577",
    leftTime: "ستة شهور",
    subType: "الخطة المكثفة",
    editDate: "October 2, 2020 02:25 PM",
    name: "Ahmed Kamel",
    blueText: "تجديد الاشتراك",
    loginText: "تسجيل الدخول",
    redText: "حظر",
    sendText: "ارسال رسالة",
    deleteText: "حذف",
    code: "123456"
  },
  {
    description: "ارسال رسالة تجديد الاشتراك",
    email: "andreea.palmer@mail.com",
    phone: "(344)341-2577",
    leftTime: "ستة شهور",
    subType: "الخطة المكثفة",
    editDate: "December 2, 2020 02:25 PM",
    name: "Bassam Tp",
    blueText: "تجديد الاشتراك",
    loginText: "تسجيل الدخول",
    redText: "حظر",
    sendText: "ارسال رسالة",
    deleteText: "حذف",
    code: "123456"
  },
];

export const AdminsMock = [
  {
    email: "mike.hawkins@mail.com",
    instagramLink: "ronnie.patel@instagram.com",
    facebookLink: "ronnie.patel@facebook.com",
    twitterLink: "ronnie.patel@twitter.com",
  },
];

export const VideoCardsMock = [
  {
    videoName: "some_video1",
    videoSrc: "assets/images/video-content-11.png",
  },
  {
    videoName: "some_video2",
    videoSrc: "assets/images/video-content-11.png",
  },
];

export const QuestionBankItems = [
  {
    question: "سؤالا",
    rightAnswer: "المحاضرة الأولى مدخل الى اساسيات المحاسبة",
  },
  {
    question: "سؤالا",
    ightAnswer: "المحاضرة الثانية",
  },
];
