<form 
(ngSubmit)="f.form.valid &&updateFormStep()"
#f="ngForm"
class="register-form"
name="registerData"
>
<div class="home-analytics-profile"  style="direction: rtl;">

  <div class="home-analytics-profile-form-container" style="direction: rtl;">
      <p class="home-analytics-profile-form-container-title">
        تغيير كلمة المرور
      </p>
      <div class="form-field form-field-with-left-icon">
        <label for="password">كلمة المرور الحالية</label>
        <div
          toggle="#passowrd"
          class="toggle-password-visibility-icon icon-100"
          (mousedown)="showPassword()"
          (mouseup)="hidePassword()"
        ></div>
        <input
          id="password"
          class="form-control"
          type="password"
          name="password"
          #pass="ngModel"
          required
          [(ngModel)]="currentPassword"
          [ngModelOptions]="{ standalone: true }"
          [ngClass]="{
            'has-danger':
              (f.submitted || pass.touched) && pass.hasError('required')
          }"
        />
        <div
          *ngIf="(f.submitted || pass.touched) && pass.hasError('required')"
          class="feed_back"
          style="color: red"
        >
          كلمه المرور مطلوبه
        </div>
      </div>
      <div class="form-field form-field-with-left-icon">
        <label for="password">كلمة المرور الجديدة</label>
        <div
          toggle="#passowrd"
          class="toggle-password-visibility-icon icon-100"
          (mousedown)="showPassword()"
          (mouseup)="hidePassword()"
        ></div>
        <input
          id="password"
          class="form-control"
          type="password"
          name="password"
          #pass="ngModel"
          [(ngModel)]="newPassword"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>
      <div class="form-field form-field-with-left-icon">
        <label>
          <label for="confirm_password">تأكيد كلمة المرور</label>
        </label>
        <div class="toggle-password-visibility-icon icon-100"></div>
        <input
          id="confirmPassword"
          class="form-control"
          type="password"
          name="confirmPassword"
          #confirmPass="ngModel"
          [(ngModel)]="confirmPassword"
          [ngModelOptions]="{ standalone: true }"
        />
        <div
          *ngIf="notMatched"
          class="feed_back"
          style="color: red"
        >
          كلمه المرور لاتطابق
        </div>
      </div>
      <div
      *ngIf="invalidPassword"
      class="feed_back"
      style="color: red"
    >
     كلمه المرور غير صحيحه
    </div>
      <div class="form-field-submit">
        <button class="btn btn-large  btn-green no-border">حفظ</button>
      </div>
  </div>

  
</div>
</form>

<div class="saved" *ngIf="savedForm">
  <div class="row bg_white w-100 justify-content-center">
    <div class="col-lg-3">
      <p class="green text-center">تم الحفظ</p>
    </div>
  </div>
</div>
