<div class="admin-edit-user-dialog-content" >
    <div class="admin-edit-user-dialog-content-header">
      <div style="margin-top: 20px;" class="close-icon-gray icon-100" (mousedown)="close()"></div>
      <span style="margin-top: 50px;" >ارسال الرد</span>
    </div>
    <div class="admin-edit-user-dialog-content-body">
      <p class="person-info">
        <span>صاحب رقم </span>
        <span class="text-blue">{{selectedPerson._sender.mobile}}</span>
        <span>على سؤال</span>
        <span class="text-blue">{{selectedPerson._sender.name}}</span>
        <span>ارسال الرد لطالب</span>
        
      </p>
      <div class="person-info-field person-info-field-top form-field">
        <input
          type="text"
          placeholder="اريد معرفة الفرق بين أنواع المحاسبة"
          class="form-control"
          disabled
          [(ngModel)]="selectedPerson.message"
          style="direction: rtl;"
        />
      </div>
      <div class="person-info-field form-field">
        <label>اكتب الرد</label>
        <textarea 
         style="direction: rtl;"
         class="comment-textarea form-control" 
         [(ngModel)]="response"> 
        </textarea>
      </div>
    </div>
  </div>
  <div class="admin-edit-user-dialog-content-footer">
    <button class="btn-green btn-large no-border" (click)="handleClose(true)">
      ارسال الاجابة
    </button>
    <button class="btn-dark btn-large no-border" (click)="close()">
      الغاء
    </button>
</div>
