<div style="padding: 20px;">
	<p *ngFor="let alert of alerts.values |async">
		<!-- <ngb-alert [type]="'danger'" (close)="closeAlert(alert)">{{ alert.message }}</ngb-alert> -->
	</p>

	<div class="card card-custom">
		<!--begin::Form-->
		<form class="form">
			<div class="card-body">
				<div class="form-group">
					<label>{{title}} <span class="acceptedTypes">({{acceptedTypes[filetype]}})</span></label>
					<div></div>
					<div class="row">
						<div class="col-12 custom-file">
							<div class="video-uploader-pane">
								<span>{{title}}</span>
							  </div>
							<input [accept]="acceptedTypes[filetype]" multiple="false" type="file" class="custom-file-input" id="file"
								(change)='onFileUploadchange($event)' />
							<label class="custom-file-label" for="customFile">Choose file</label>
						</div>
						<div class="col-3">
							<mat-spinner *ngIf='(loading$.value|async)' class="spinner" [diameter]="25"></mat-spinner>
							<span>{{progress.value|async}}</span>
						</div>
						<div class="col-6"></div>
						<div class="col-3">
							<div class="btn-icon btn-icon-remove c-pointer" (click)="removeSelected()"></div>
							<!-- <button color="warn" mat-button mat-raised-button [disabled]="selectedCount==0" (click)="removeSelected()">Delete</button> -->
						</div>
					</div>


				</div>
			</div>

		</form>
		<!--end::Form-->
	</div>



	<ul class="gallery" *ngIf="filetype =='image'">
		<li *ngFor="let image of images.values|async;let i=index" [style.backgroundImage]="'url('+image.path+')'">
			<input type="checkbox" value="true" [(ngModel)]="selected[i]" (change)="calcSelected()" />
			<h2 class="title">
				{{image.fileName}}
				<mat-icon color="warn" matTooltip="{{'SETTINGS.LIST.DELETE'}}" type="button" (click)="removeRow(image)">delete</mat-icon>
			</h2>
		</li>
	</ul>
	
	<ul class="gallery" *ngIf="filetype =='misc'">
		<li *ngFor="let image of images.values|async;let i=index" [style.backgroundImage]="'url('+image.path+')'">
			<input type="checkbox" value="true" [(ngModel)]="selected[i]" (change)="calcSelected()" />
			<h2 class="title">
				{{image.fileName}}
				<mat-icon color="warn" matTooltip="{{'SETTINGS.LIST.DELETE'}}" type="button" (click)="removeRow(image)">delete</mat-icon>
			</h2>
			<video controls #videoPlayer>
				<source [src]="image.path" type="video/mp4" />
				Browser not supported
			  </video>
		</li>
	</ul>

	<ul class="gallery" *ngIf="filetype !== 'image'">
		<li *ngFor="let image of images.values|async;let i=index" [style.backgroundImage]="'url('+fileImage+')'">
			<!-- <input type="checkbox" value="true" [(ngModel)]="selected[i]" /> -->
			<h2 class="title">
				{{image.fileName}}
				<mat-icon color="warn" matTooltip="{{'SETTINGS.LIST.DELETE'}}" type="button" (click)="removeRow(image)">delete</mat-icon>
			</h2>
		</li>
	</ul>


</div>