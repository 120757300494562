<div class="lections-previous-tests">
  <div class="lections-previous-tests--content" [ngClass]="{'collapsed': lecturesService.rightNavState }">
    <div class="lections-previous-tests--content--section">



      <div class="lections-previous-tests--looking-for-tests">
        <div class="search-for-lections">
          <input class="form-control-search" placeholder="{{searchPlaceholder}}"
            (change)="onChangeSearchValue($event)" />
        </div>
      </div>
      <div class="lections-previous-tests--display-previous-tests">
        <div class="ui-grid row">
          <div class="ui-grid--heading col-lg-12">
            <div class="row">
              <div class="col-lg-4">
                <div class="row">
                  <div class="col-lg-3">
                    <p class="direction header_font">
                      النتيجة
                    </p>
                  </div>
                  <div class="col-lg-5">
                    <p class="direction header_font">
                      الاسم
                    </p>
                  </div>
                  <div class="col-lg-3">
                    <p class="direction header_font transslatex-55">
                      التاريخ
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="row">
                  <div class="col-lg-2">
                    <p class="direction header_font word_nowrap">
                      وضع الامتحان
                    </p>
                  </div>
                  <div class="col-lg-4">
                    <p class="direction header_font">
                      نوع الاسئلة
                    </p>
                  </div>
                  <div class="col-lg-1">
                    <p class="direction header_font">
                      الأجزاء
                    </p>
                  </div>

                </div>
              </div>
              <div class="col-lg-4">
                <div class="row">
                  <div class="col-lg-3">
                    <p class="direction header_font">
                      عدد الاسئلة
                    </p>
                  </div>
                  <div class="col-lg-3">
                    <p class="direction header_font">
                      المزيد
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ui-grid--body col-lg-12">
            <div class="row flex_wrap">
              <div *ngFor="let test of testsData" class="ui-grid--body-item px-0 col-lg-12">
                <div class="row flex_wrap">
                  <div class="col-lg-4">
                    <div class="row">
                      <div class="col-lg-3">
                        <p class="direction header_font">
                          {{test.questionNumbers}} / {{test.rightAnswers}}  
                        </p>
                      </div>
                      <div class="col-lg-5">
                        <p class="direction header_font">
                          {{test.name}}
                        </p>
                      </div>
                      <div class="col-lg-3">
                        <p class="direction header_font body_cell_width">
                          {{test.modified | date: 'MMM d, y, h:mm a'}}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="row">
                      <div class="col-lg-2">
                        <p class="direction header_font">
                          {{getTimeType(test)}}
                        </p>
                      </div>
                      <div class="col-lg-4">
                        <p class="direction header_font">
                          اختيار من متعدد
                        </p>
                      </div>
                      <div class="col-lg-1">
                        <p class="">
                          {{returnNumberOfLectures(test.lectureId)}}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="row">
                      <div class="col-lg-3">
                        <p class="direction header_font text-left">
                          {{test.questionNumbers}}
                        </p>
                      </div>
                      <div class="col-lg-4">
                        <div class="row justify_even">
                          <!-- <p class="direction header_font">
                        أعادة
                      </p> -->
                          <a [routerLink]="['/exam/'+test.id]" class="direction header_font">
                            اجاباتي
                          </a>
                        </div>
                      </div>

                    </div>
                  </div>
                  <!-- <div class="col-lg-1 direction header_font" *ngFor="let gridHeadingItem of gridHeadingItems">
                <span>{{test[gridHeadingItem.key]}}</span>
              </div> -->
                </div>
              </div>

            </div>
          </div>
          <ul class="pagination-content pagination-div">
            <li class="pagination-control-left pagination-control pagination-content-left">
              <div class="left-control-icon icon-100">
                <div class="arrow-icon icon-100"></div>
              </div>
            </li>
            <li class="pagination-count-item" pagination-content-right>
              <!-- *ngFor="let paginationPageCount of Questions; let ID = index;"
        [ngClass]="{'active': ID === activePaginationItem }" -->
              <!-- <span>{{ID + 1}}</span> -->
              <span>{{1}}</span>
            </li>
            <li class="pagination-control-right pagination-control pagination-content-right">
              <div class="right-control-icon icon-100">
                <div class="arrow-icon icon-100"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>