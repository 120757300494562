import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { flush } from '@angular/core/testing';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-admin-question-confirm",
  templateUrl: "./admin-question-confirm.html",
  styleUrls: ["./admin-question-confirm.sass"],
})
export class AdminQuestionConfirmComponent implements OnInit,OnChanges {
  @Input() popupOpened: boolean;
  @Output() confirm = new EventEmitter<boolean>();
  constructor(private modalService: NgbModal, ){

  }
  ngOnInit() {}
  ngOnChanges(simpleChange:SimpleChanges){
      this.popupOpened=simpleChange.popupOpened.currentValue;
  }
  handleClose(saveQuestionBefore?: boolean) {
    if (saveQuestionBefore) {
         this.modalService.dismissAll(true);
    }else{
      this.modalService.dismissAll();
    }
  }
  close(){
    this.modalService.dismissAll();
  }
}
