import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LecturesService } from "@services/apis/lectures.service";
import { ExamsService } from "./../../../../services/apis/exam.service";
import { QuestionssService } from "./../../../../services/apis/questions.service";



@Component({
  selector: "app-lections-create-test",
  templateUrl: "lections-create-test.html",
  styleUrls: ["./lections-create-test.sass"],
})
export class LectionsCreateTestComponent implements OnInit {
  @Input() isRightNavOpened: boolean;
  isTimeThrowed: boolean;
  lectures: any[] = [];
  sections: number;
  chunkSize: number;
  firstSec: number;
  secondSec: number;
  questions: any = {};
  choices = 0;
  article = 0;
  checkbox1Placeholder: string;
  checkbox2Placeholder: string;
  checkedLectures: any = {};
  checkedOptions: any = {};
  chooseLecMsg: boolean = false;
  chooseQuestionsType: boolean = false;
  questionsNumber: number = 0;
  choicesQuestionsNumber: any = [];
  articleQuestionsNumber: any = [];
  questNumbers: number = 0;
  constructor(
    public lecturesService: LecturesService,
    private lectureApi: LecturesService,
    private questionsApi: QuestionssService,
    private examApi: ExamsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.checkbox1Placeholder = "المقالي";
    this.checkbox2Placeholder = "الاختيار من متعدد";

    this.lectureApi.query({}).subscribe((res) => {
      this.lectures = res["result"];
      this.lectures.forEach((lec) => {
        this.questionsCount(lec.id);
      });
      this.chunkSize = this.sectionsNumber(this.lectures.length);
    });
    this.questionTypes();
  }

  handleSwitcherUpdate(newState: boolean) {
    this.isTimeThrowed = newState;
  }

  handleUpdateOnCheckbox(event: Event, id: string) {
    const check: boolean = event.target["checked"];
    if (id != "choices" && id != "article") {
      this.checkedLectures[id] = check;
    } else {
      this.checkedOptions[id] = check;
    }
    // console.log('this.checkedOptions', this.checkedOptions);
  }

  maxLength() {
    this.questNumbers = 0;

    if (Object.entries(this.checkedLectures).length > 0) {
      Object.entries(this.checkedLectures).forEach((lecture) => {
        // check if the lecture is selected
        if (lecture[1] === true) {
          // check if the article and choices questions are both selected
          if (
            this.checkedOptions.article &&
            this.checkedOptions.article == true &&
            this.checkedOptions.choices &&
            this.checkedOptions.choices == true
          ) {
            this.questNumbers =
              this.questNumbers +
              (Number(this.choicesQuestionsNumber[lecture[0]]) || 0) +
              (Number(this.articleQuestionsNumber[lecture[0]]) || 0);
            // console.log("article and choices questions", this.questNumbers);
          } else if (
            // check if the  choices questions only
            this.checkedOptions.choices &&
            this.checkedOptions.choices == true
          ) {
            // console.log("choices", this.questNumbers);
            this.questNumbers =
              this.questNumbers +
              (Number(this.choicesQuestionsNumber[lecture[0]]) || 0);
            // console.log("choices after", this.questNumbers);
          } else if (
            // check if the  article questions only
            this.checkedOptions.article &&
            this.checkedOptions.article == true
          ) {
            // console.log("article before", this.questNumbers);
            this.questNumbers =
              this.questNumbers +
              (Number(this.articleQuestionsNumber[lecture[0]]) || 0);
            // console.log("article after", this.questNumbers);
          }
        }
      });
    } else {
      if (Object.entries(this.checkedOptions).length == 0) {
        this.questNumbers = this.articleNum() + this.choicesNum();
      } else {
        if (
          this.checkedOptions.article &&
          this.checkedOptions.article == true &&
          this.checkedOptions.choices &&
          this.checkedOptions.choices == true
        ) {
          this.questNumbers = this.articleNum() + this.choicesNum();
          // console.log("both true");
        } else if (
          this.checkedOptions.choices &&
          this.checkedOptions.choices == true
        ) {
          this.questNumbers = this.choicesNum();
          // console.log("choices true");
        } else if (
          this.checkedOptions.article &&
          this.checkedOptions.article == true
        ) {
          this.questNumbers = this.articleNum();
          // console.log("article true");
        }
      }
      // console.log("fourth", this.questNumbers);
    }

    return this.questNumbers;
  }

  async create() {
    this.chooseLecMsg = false;
    this.chooseQuestionsType = false;
    const userId = JSON.parse(localStorage.currentUser).userId;
    let NumberOfQuestions = document.getElementById("numOfQuestions")["value"];
    if (NumberOfQuestions > this.maxLength())
      NumberOfQuestions = this.maxLength();
    const lecturesIds: any[] = [];
    for (const key in this.checkedLectures) {
      if (this.checkedLectures[key] == true) {
        lecturesIds.push(key);
      }
    }

    if (lecturesIds.length < 1) {
      this.chooseLecMsg = true;
      return;
    }

    if (
      (!this.checkedOptions.choices && !this.checkedOptions.article) ||
      (this.checkedOptions.choices == false &&
        this.checkedOptions.article == false)
    ) {
      this.chooseQuestionsType = true;
      return;
    }

    const questions = await this.questionsApi
      .query({
        where: {
          lectureId: {
            inq: lecturesIds,
          },
        },
      })
      .toPromise();
    // let questionIds = questions["result"].map((x) => x.id);
    const exam = {
      userId,
      lectures: lecturesIds,
      // questions: questionIds,
      multipleChoices: this.checkedOptions.choices || false,
      articleQuestions: this.checkedOptions.article || false,
      time: this.isTimeThrowed,
      // examType: "اختبار عشوائي",
      isCompleted: false,
      questionNumbers: Number(NumberOfQuestions),
    };
    // console.log(exam);
    // this.examApi.add(exam).subscribe((res) => {
    this.examApi.createRandomExam(exam).subscribe((res) => {
      // console.log(res);
      // this.router.navigateByUrl("/exam/" + res.id);
      this.router.navigateByUrl("/exam/" + res);
    });
  }

  sectionsNumber(lectures) {
    let chunkSize = lectures / 3;
    this.firstSec = this.secondSec = chunkSize;
    if (lectures % 3 !== 0) {
      chunkSize = Math.floor(chunkSize);
      this.firstSec = this.secondSec = chunkSize;
      if (chunkSize > lectures / 3) {
        this.secondSec = chunkSize + 1;
        this.secondSec = Math.floor(this.secondSec);
      } else {
        this.firstSec = chunkSize + 1;
        this.firstSec = Math.floor(this.firstSec);
      }
    }
    return chunkSize;
  }

  questionsCount(id: string) {
    let count;
    this.questionsApi
      .query({
        where: { lectureId: id, choiceCount: { inq: ["عشوائى", "كلاهما"] } },
      })
      .subscribe((res) => {
        if (res) {
          this.getQuestionCountByType(res['result'], id);
          count = res["count"].toString();
          // this.questions[id] = count;
          this.questionsTypesArray(res["result"]);
        } else {
          count = 0;
          // this.questions[id] = count.toString();
          this.questions[id] = {
            choices: 0,
            article: 0
          }
        }
      });
  }

  getQuestionCountByType(result: any[], id: string) {
    let choices = 0;
    let article = 0;
    result.forEach(x => {
      console.log(x);
      if (x.type === "اختيار من متعدد") choices = choices + 1;
      if (x.type === "مقالى") article = article + 1;
    });
    this.questions[id] = {
      choices: choices.toString(),
      article: article.toString()
    }
  }

  questionsTypesArray(arr: []) {
    arr.forEach((quest) => {
      if (quest["type"] == "مقالى") {
        this.articleQuestionsNumber[quest["lectureId"]] = this
          .articleQuestionsNumber[quest["lectureId"]]
          ? this.articleQuestionsNumber[quest["lectureId"]] + 1
          : 1;
      } else {
        this.choicesQuestionsNumber[quest["lectureId"]] = this
          .choicesQuestionsNumber[quest["lectureId"]]
          ? this.choicesQuestionsNumber[quest["lectureId"]] + 1
          : 1;
      }
    });
  }

  questionTypes() {
    this.questionsApi.query({}).subscribe(async (res) => {
      const questions: any[] = await res["result"];
      if (res["count"] > 0) {
        questions.forEach((element) => {
          if (
            this.lectures
              .map((e) => {
                return e.id;
              })
              .indexOf(element.lectureId) != -1
          ) {
            if (
              element.choiceCount == "عشوائى" ||
              element.choiceCount == "كلاهما"
            ) {
              if (element.type == "مقالى") {
                this.article = this.article + 1;
              } else if (element.type == "اختيار من متعدد") {
                this.choices = this.choices + 1;
              }
            }
          }
        });
      }
    });
  }

  articleNum() {
    let articleQuestions = 0;
    for (const key in this.articleQuestionsNumber) {
      articleQuestions =
        articleQuestions + (Number(this.articleQuestionsNumber[key]) || 0);
    }
    return articleQuestions;
  }

  choicesNum() {
    let choices = 0;
    for (const key in this.choicesQuestionsNumber) {
      choices = choices + (Number(this.choicesQuestionsNumber[key]) || 0);
    }
    return choices;
  }
}
