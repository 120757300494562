<div class="admin-subscribtion-control" *ngIf="coupons"> <!-- && coupons.length > 0-->
    <div class="admin-subscribtion-control-content">
      <div (click)="addNewCoupon()"  class="admin-articles-modify-middle">
        <span>أضافة كوبون خصم </span>
        <button  class="btn icon-circle btn-icon-plus"></button>
      </div>
      <div class="admin-subscribtion-control-card" *ngFor="let coupon of coupons;let j = index">
        <div class="form-field">
          <span>الكود</span>
          <div>
            <button class="btn-icon btn-edit-icon"></button>
            <input required class="form-control" type="text" [(ngModel)]="coupon.code"
              [ngModelOptions]="{ standalone: true }" />
          </div>
        </div>
        <div class="form-field">
          <span>الوصف</span>
          <div>
            <button class="btn-icon btn-edit-icon"></button>
            <input required class="form-control" type="text" [(ngModel)]="coupon.description"
              [ngModelOptions]="{ standalone: true }" />
          </div>
        </div>
        <div class="form-field">
          <span>نسبة الخصم</span>
          <div>
            <button class="btn-icon btn-edit-icon"></button>
            <input required class="form-control" type="number" max="100" [(ngModel)]="coupon.percentage"
              [ngModelOptions]="{ standalone: true }" />
          </div>
        </div>
        <div class="form-field">
          <span>الخطة</span>
          <div>
            <button class="btn-icon btn-edit-icon"></button>
            <select required dir="rtl" style="width: 99%" [(ngModel)]="coupon.plan" class="form-control">
              <option *ngFor="let plan of plans" [value]="plan.id">
                {{plan.name}}
              </option>
            </select>
          </div>
        </div>
       
        <div *ngIf="this.wrongPercentage == true">
          <p class="admin-subscribtion-control-card-errorMsg" >النسبة يجب أن تكون أقل من أو تساوي 100 </p>
        </div>
       
        <div *ngIf="this.couponIndex==j">
          <p class="admin-subscribtion-control-card-errorMsg" >يجب ان تدخل كل البيانات</p>
        </div>
        <div class="form-field form-field-submit">
          <button style="margin-right: 20px;" class="btn btn-green submit-btn no-border" (click)="saveUserData(coupon,j)">
            حفظ
          </button>
          <button style="margin-right: 20px;" class="btn btn-green submit-btn no-border" (click)="deleteUserData(coupon)">
            حذف
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div *ngIf="coupons.length === 0" class="no-valid-data-available">
    <p>No subscribtions recently available.</p>
  </div> -->