<div class="admin-root">
  <div class="admin-user-updates">
    <div
      class="admin-user-updates-card"
      *ngFor="let updatesCard of updatesCards; let ID = index"
    >
      <div
        class="admin-user-updates-card-info c-pointer"
        (mousedown)="editStudent(updatesCard)"
      >
        <div class="admin-user-updates-card-head-info">
          <div class="info-icon"></div>
          <span class="edit-date">{{updatesCard.created|date:"MM/dd/yyyy"}}</span>
          <span>رد</span>
          <span>{{updatesCard._sender?updatesCard._sender.name:""}}</span>
          <span>{{updatesCard._lecture? updatesCard._lecture.LectureName:""}}</span>
        </div>
        <div class="admin-user-updates-card-body-info">
          <span>{{updatesCard.message}}</span>
          <span>{{ID+1}}</span>
        </div>
      </div>
    </div>
  </div>

</div>
